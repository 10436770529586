<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.909 23.6931C16.25 24.1952 15.3088 24.068 14.8068 23.4091L6.80677 12.9091C6.39764 12.3721 6.39764 11.6279 6.80677 11.0909L14.8068 0.590935C15.3088 -0.0680239 16.25 -0.195212 16.909 0.30685C17.5679 0.808914 17.6951 1.75011 17.1931 2.40906L9.88569 12L17.1931 21.5909C17.6951 22.2499 17.5679 23.1911 16.909 23.6931Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24ChevronBoldLeft",
};
</script>

<style></style>
