<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.09092 0.306852C7.74988 -0.195211 8.69107 -0.0680224 9.19314 0.590935L17.1931 11.0909C17.6023 11.6279 17.6023 12.3721 17.1931 12.9091L9.19314 23.4091C8.69107 24.068 7.74988 24.1952 7.09092 23.6931C6.43197 23.1911 6.30478 22.2499 6.80684 21.5909L14.1142 12L6.80684 2.40906C6.30478 1.75011 6.43197 0.808915 7.09092 0.306852Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24ChevronBoldRight",
};
</script>

<style></style>
