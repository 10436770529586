<template>
  <v-footer dark class="main-footer pb-16 mt-10" padless color="#82837D">
    <v-col cols="12" class="grey lighten-4" justify="center">
      <div
        class="
          d-flex
          align-center
          justify-center
          pa-5
          main-footer_payment-options
        "
      >
        <v-img
          src="@/assets/images/Klarna-small.svg"
          max-height="32"
          contain
          class="mx-2 flex-grow-0"
        >
        </v-img>

        <v-img
          src="@/assets/images/Visa-small.svg"
          max-height="32"
          contain
          class="mx-2 flex-grow-0"
        >
        </v-img>

        <v-img
          src="@/assets/images/Mastercard-small.svg"
          max-height="32"
          contain
          class="mx-2 flex-grow-0"
        >
        </v-img>

        <v-img
          src="@/assets/images/Amex-small.svg"
          max-height="32"
          contain
          class="mx-2 flex-grow-0"
        >
        </v-img>
      </div>
    </v-col>
    <v-col cols="12" justify="center" class="py-14 px-6">
      <v-img
        src="@/assets/images/LA_logo_vaaka_nega.png"
        max-height="60"
        contain
      >
      </v-img>
    </v-col>

    <v-container>
      <v-row class="px-6">
        <v-col cols="12" sm="6" md="4">
          <h3 class="mb-2">Loviisan Aitta</h3>
          <p>
            Vahdontie 600 <br />
            21290 Rusko
          </p>
          <a style="color: #ffffff" href="tel:+358 02 4322227"
            ><p>Puhelin (02) 432 2227</p></a
          >

          <p>Y-tunnus: 1947468-7</p>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <h4 class="mb-2 mt-1 ml-sm-3">Seuraa meitä</h4>
          <v-row class="mb-4">
            <v-col>
              <v-btn
                class="mx-1"
                icon
                large
                href="https://www.facebook.com/LoviisanAitta/"
              >
                <v-icon>${{ "Icon24Facebook" }}</v-icon>
              </v-btn>

              <v-btn
                class="mx-1"
                icon
                large
                href="https://www.instagram.com/loviisanaitta/"
              >
                <v-icon>${{ "Icon24Instagram" }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="d-flex flex-column">
            <v-btn
              text
              class="mr-auto"
              href="http://eepurl.com/i6cp"
              target="_blank"
            >
              <v-icon left> mdi-arrow-right </v-icon>
              Tilaa asiakaskirje
            </v-btn>
            <v-btn text class="mr-auto" to="/tietosuojakaytanto">
              <v-icon left> mdi-arrow-right </v-icon>
              Tietosuojakäytäntö
            </v-btn>
            <v-btn text class="mr-auto" to="/tilaus-ja-toimitusehdot">
              <v-icon left> mdi-arrow-right </v-icon>
              Verkkokaupan toimitusehdot
            </v-btn>
          </div>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <h4 class="mb-2 mt-1">Myymälä avoinna</h4>
            </v-col>
            <v-col cols="6" sm="4" md="6">
              <h4 class="mb-1 mt-1">Poikkeuksia aukioloihin</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-row no-gutters class="ma-0">
                <v-col>ma-pe</v-col>
                <v-col>11-18</v-col>
              </v-row>
              <v-row no-gutters class="ma-0">
                <v-col>la</v-col>
                <v-col>11-15</v-col>
              </v-row>
              <v-row no-gutters class="ma-0">
                <v-col>su</v-col>
                <v-col>Suljettu</v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <!-- <v-row no-gutters class="ma-0">
                <v-col>4.11.</v-col>
                <v-col>11-15</v-col>
              </v-row>
              <v-row no-gutters class="ma-0">
                <v-col>5.11.</v-col>
                <v-col>12-16</v-col>
              </v-row> -->
              <!-- <v-row no-gutters class="mt-8 mb-8">
                <v-col><h4>Poikkeuksia aukioloihin</h4></v-col>
              </v-row> -->

              <v-row no-gutters class="ma-0">
                <v-col>29.3.-1.4.</v-col>
                <v-col>Suljettu</v-col>
              </v-row>
              <v-row no-gutters class="ma-0">
                <v-col>30.4.</v-col>
                <v-col>11-15</v-col>
              </v-row>
              <v-row no-gutters class="ma-0">
                <v-col>1.5.</v-col>
                <v-col>Suljettu</v-col>
              </v-row>
              <v-row no-gutters class="ma-0">
                <v-col>9.5.</v-col>
                <v-col>Suljettu</v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped>
.footer_payment-options .v-image {
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 10%);
  border-radius: 3px;
}

.main-footer {
  background-color: #82837d !important;
}
</style>

<script>
export default {};
</script>
