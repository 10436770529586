<template>
  <form class="main-search-bar" v-if="this.showSearch" autocomplete="off">
    <input autocomplete="false" class="d-none" />
    <!-- hidden input to disable the password autocomplete -->
    <v-autocomplete
      v-model="model"
      ref="refSearch"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      clearable
      :dialog="dialog"
      clear-icon="mdi-close-circle"
      rounded
      no-filter
      hide-details
      hide-no-data
      item-text="name"
      item-value="symbol"
      label="Etsi tuotteita.."
      solo
      flat
      multiple
      outlined
      prepend-inner-icon="mdi-magnify"
      append-icon=""
      :menu-props="{
        closeOnClick: true,
        closeOnContentClick: true,
      }"
      @keydown.enter="goToSearch"
      @change="itemChange"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Aikasemmat haut
            <div v-for="(item, i) in $store.state.searchHistory" :key="i">
              <router-link
                :to="{
                  name: 'ProductSearch',
                  params: { search: item, value: item },
                }"
                >{{ item }}</router-link
              >
            </div>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:append-item>
        <v-row class="ma-0">
          <v-col class="d-flex justify-center show-all">
            <v-btn text class="primary--text" @click="goToSearch">
              Näytä kaikki
              <v-icon right> mdi-arrow-right </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="{ item }">
        <v-img
          v-if="item.thumbnail"
          width="60"
          aspect-ratio="1"
          class="product-card__img flex-grow-0 mr-4"
          :src="baseurl + item.thumbnail + '/small.png'"
          @click="addToSearchHistory(item.details.sku.value, item.name)"
          v-bind:style="{ cursor: 'pointer' }"
        ></v-img>
        <v-img
          v-else-if="item.assets"
          width="60"
          aspect-ratio="1"
          class="product-card__img flex-grow-0 mr-4"
          :src="baseurl + item.assets[0] + '/small.png'"
          @click="addToSearchHistory(item.details.sku.value, item.name)"
          v-bind:style="{ cursor: 'pointer' }"
        ></v-img>
        <v-img
          v-else
          contain
          width="60"
          aspect-ratio="1"
          class="product-card__img flex-grow-0 mr-4"
          src="@/assets/images/no-img.jpg"
          @click="addToSearchHistory(item.details.sku.value, item.name)"
          v-bind:style="{ cursor: 'pointer' }"
        >
        </v-img>
        <v-list-item-content
          @click="addToSearchHistory(item.details.sku.value, item.name)"
          v-bind:style="{ cursor: 'pointer' }"
        >
          <v-list-item-title v-text="item.name"></v-list-item-title>
          <v-list-item-subtitle v-text="item.symbol"></v-list-item-subtitle>
        </v-list-item-content>
        <v-btn
          outlined
          rounded
          small
          class="flex-shrink-0"
          @click="addToCart(item)"
          :disabled="checkStock(item)"
        >
          <v-icon left small> ${{ "Icon16Shopping" }} </v-icon>
          Lisää
        </v-btn>
        <br />
      </template>
    </v-autocomplete>
  </form>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      expand: false,
      focusExpand: true,
      blurExpand: false,
      searchResult: [],
      items: [],
      timeout: null,
      url: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/product",
      expand2: "",
      isLoading: false,
      search: null,
      tab: null,
      baseurl: process.env.VUE_APP_BBSHOP_IMAGES_ENDPOINT,
      model: null,
      showSearch: true,
      dialog: false,
      stockUrl: process.env.VUE_APP_BBSHOP_ENDPOINT + "/stock/",
    };
  },
  methods: {
    generateSlug: function(name) {
      let lowerCase = name.toLowerCase();
      let replaceSpaces = lowerCase.replaceAll(" ", "-");
      let replaceUmlaut = replaceSpaces
        .replaceAll("ä", "a")
        .replaceAll("ö", "ö")
        .replaceAll("å", "a")
        .replaceAll("ü", "u");
      let replaceSymbols = replaceUmlaut.replaceAll(/[^a-z0-9\-]/g, "-"); //eslint-disable-line
      let replaceExtraDashes = replaceSymbols.replaceAll(/-+/g, "-");
      return replaceExtraDashes;
    },
    checkStock(item) {
      if (item.variants) {
        return false;
      }
      // if (item.details.in_stock) {
      //   if (item.details.in_stock.value > 0) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // } else return true;
      if (item.stock) {
        if (item.stock > 0) {
          return false;
        } else {
          return true;
        }
      } else return true;
    },
    itemChange(e) {
      this.selected = e;
      this.$nextTick(() => {
        this.search = null;
        this.items = [];
      });
    },
    addToCart: function(item) {
      if (item.variants) {
        // console.log("variants?");
        this.$router.push({
          name: "ProductPage",
          params: { sku: item.details.sku.value, name: item.name },
        });
        document.activeElement.blur();
      } else {
        let check = this.checkDiscountCategory(item.categories);
        let discount = false;
        let discountPercent = 0;

        if (check) {
          discount = true;
          discountPercent = check.percentage * 100;
        }

        let data = {};

        if (discount) {
          data = {
            name: item.name,
            sku: item.details.sku.value,
            price: item.details.price.value,
            count: 1,
            uuid: item.uuid,
            assets: item.assets,
            in_stock: item.stock,
            details: item.details,
            discount: {
              active: discount,
              percent: discountPercent,
            },
          };
        } else {
          data = {
            name: item.name,
            sku: item.details.sku.value,
            price: item.details.price.value,
            count: 1,
            uuid: item.uuid,
            assets: item.assets,
            in_stock: item.stock,
            details: item.details,
          };
        }

        this.$store.commit("addToCart", data);

        // this.$store.commit("addToCart", {
        //   name: item.name,
        //   sku: item.details.sku.value,
        //   price: item.details.price.value,
        //   count: 1,
        //   uuid: item.uuid,
        //   assets: item.assets,
        //   in_stock: item.stock,
        //   details: item.details,
        // });
        // console.log(this.$store.state.shoppingCart);
        // this.$store.commit("setSnack", {
        //   text: item.name,
        //   message: " lisätty ostoskoriin",
        //   color: "success",
        // });
      }
    },
    addToSearchHistory: function(sku, name) {
      this.$router
        .push({
          name: "ProductPage",
          params: { sku: sku, name: name },
        })
        .catch(() => {});
      document.activeElement.blur();
      this.$store.commit("addToSearchHistory", this.search);
    },
    goToSearch: function() {
      if (this.search !== undefined) {
        if (this.search.length > 2) {
          this.expand = false;
          this.result = null;
          this.searchResult = [];
          this.$router
            .push({
              name: "ProductSearch",
              params: { search: this.search, value: this.search },
            })
            .catch(() => {});
          document.activeElement.blur();
          this.$store.commit("addToSearchHistory", this.search);
          this.search = null;
          this.items = [];
          this.isLoading = false;
          this.model = null;
        }
      }
    },
    closeExpand: function(value) {
      this.expand2 = value;
      this.expand = false;
      // console.log("emit?" + value);
      document.activeElement.blur();
    },
  },
  watch: {
    $route() {
      if (
        this.$route.name === "login" ||
        this.$route.name === "account" ||
        this.$route.name === "register" ||
        this.$route.name === "user-page" ||
        this.$route.name === "reset-password"
      ) {
        this.showSearch = false;
      } else {
        this.showSearch = true;
      }
    },
    search(val) {
      if (val !== null) {
        if (val.length > 2) {
          this.isLoading = true;
          // console.log("searched value: " + val);
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            let tempStr = val.split(/(\s+)/);
            let tempStr2 = tempStr.filter(function(entry) {
              return entry.trim() != "";
            });

            let value = "";

            if (tempStr2.length > 1) {
              // console.log("over 1");
              tempStr2.map((e, i) => {
                value += "*" + e + "*";
                if (tempStr2.length > i + 1) {
                  // console.log(i);
                  value += " AND ";
                }
              });
            } else {
              // console.log("less than 1");
              value = "*" + val + "*";
            }
            // console.log(value);

            let data = {
              from: 0,
              size: 10,
              query: {
                query_string: {
                  fields: ["name^4", "description^1"],
                  query: value,
                },
              },
            };

            // let data = {
            //   from: 0,
            //   size: 10,
            //   query: {
            //     query_string: {
            //       query: val + "*",
            //       fields: ["name"],
            //     },
            //   },
            // };

            if ((val !== null && val !== "") || value !== "") {
              axios
                .post(this.url, data)
                .then((response) => {
                  // console.log(response.data);
                  this.items = response.data.items;
                  this.isLoading = false;
                })
                .catch((error) => {
                  console.log(error);
                  this.isLoading = false;
                })
                .finally(() => {
                  // console.log("finally?");
                  this.items.forEach((element, i) => {
                    // console.log(element)
                    this.axios.get(this.stockUrl + element.uuid).then((res) => {
                      this.$set(this.items[i], "stock", res.data);
                    });
                  });
                  // console.log(this.items);
                });
            }
            // console.log(val);
            // this.$store.commit("addToSearchHistory", val)
          }, 1000);
        }
      }
    },
  },
};
</script>

<style scoped>
.v-list.v-select-list.v-sheet {
  padding: 0;
}

.v-list-item.v-list-item--link {
  padding: 0 !important;
}

.main-earch-bar {
  position: relative;
}

.main-search-bar__menu {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 640px;
  z-index: 9999;
}

.show-all {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 959px) {
  .hide-on-small {
    display: none;
  }
}
</style>
