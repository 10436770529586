<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5 2.8C20.4389 2.8 21.2 3.56112 21.2 4.5V12.0858C21.2 12.5367 21.0209 12.9691 20.7021 13.2879L10.7021 23.2879C10.0382 23.9518 8.96182 23.9518 8.29792 23.2879L0.712136 15.7021C0.0482445 15.0382 0.0482463 13.9618 0.712137 13.2979L10.7121 3.29792C11.0309 2.97911 11.4633 2.8 11.9142 2.8L19.5 2.8ZM19.8 4.5C19.8 4.33432 19.6657 4.2 19.5 4.2H11.9142C11.8347 4.2 11.7583 4.23161 11.7021 4.28787L1.70209 14.2879C1.58493 14.405 1.58493 14.595 1.70209 14.7121L9.28787 22.2979C9.40503 22.4151 9.59498 22.4151 9.71214 22.2979L19.7121 12.2979C19.7684 12.2417 19.8 12.1654 19.8 12.0858V4.5Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.495 0.505026C23.7683 0.778393 23.7683 1.22161 23.495 1.49498L15.995 8.99498L15.005 8.00503L22.505 0.505026C22.7784 0.231659 23.2216 0.231659 23.495 0.505026Z"

    />
    <path
      d="M15.5 10C14.6716 10 14 9.32843 14 8.5C14 7.67157 14.6716 7 15.5 7C16.3284 7 17 7.67157 17 8.5C17 9.32843 16.3284 10 15.5 10Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Label",
};
</script>

<style></style>
