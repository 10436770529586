<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM18.2834 20.5378C16.5251 21.834 14.352 22.6 12 22.6C9.64805 22.6 7.47501 21.834 5.71674 20.5379C5.95334 17.2742 8.67607 14.7 12 14.7C15.324 14.7 18.0467 17.2742 18.2834 20.5378ZM19.5434 19.447C18.9252 16.4287 16.5411 14.0539 13.5178 13.4495C15.3688 12.8183 16.7 11.0646 16.7 8.99999C16.7 6.40425 14.5958 4.29999 12 4.29999C9.40431 4.29999 7.30005 6.40425 7.30005 8.99999C7.30005 11.0646 8.63132 12.8183 10.4823 13.4495C7.45899 14.0539 5.07481 16.4287 4.45671 19.4471C2.56669 17.5328 1.4 14.9026 1.4 12C1.4 6.14578 6.14578 1.4 12 1.4C17.8542 1.4 22.6 6.14578 22.6 12C22.6 14.9026 21.4333 17.5327 19.5434 19.447ZM12 5.69999C10.1775 5.69999 8.70005 7.17745 8.70005 8.99999C8.70005 10.8225 10.1775 12.3 12 12.3C13.8226 12.3 15.3 10.8225 15.3 8.99999C15.3 7.17745 13.8226 5.69999 12 5.69999Z"
      />
  </svg>
</template>

<script>
export default {
  name: "Icon24Account",
};
</script>

<style></style>
