var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:{ scrolled: !_vm.scrolled }},[_c('div',{staticClass:"navbar"},[_c('div',{staticClass:"d-flex pa-2 navbar__wrapper",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex pa-2 align-center navbar__top-left"},[_c('v-btn',{staticClass:"mobile-drawer__toggle navbar__menu",attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();(_vm.drawer = !_vm.drawer), (_vm.showSearch = false)}}},[_c('v-icon',[_vm._v("$"+_vm._s("Icon24Menu"))])],1),(!_vm.showSearch)?_c('v-btn',{staticClass:"navbar__search-btn d-sm-none",attrs:{"icon":"","large":""},on:{"click":function($event){_vm.toggleSearch(),
              (_vm.drawer = false),
              (_vm.expandCategoryDrawer = false),
              _vm.$root.$emit('update', _vm.expandCategoryDrawer)}}},[_c('v-icon',[_vm._v("$"+_vm._s("Icon24Search"))])],1):_vm._e(),_c('v-fade-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearch),expression:"showSearch"}],staticClass:"hide-search-on-large px-4 mx-0 align-center",staticStyle:{"width":"100%","z-index":"999"}},[_c('MainSearchBar',{staticStyle:{"width":"100%","max-width":"600px"}}),_c('v-btn',{staticClass:"navbar__search-btn",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.toggleSearch()}}},[_c('v-icon',[_vm._v("$"+_vm._s("Icon16Close"))])],1)],1)])],1),_c('div',{staticClass:"pa-2 navbar__top-middle hide-on-scroll justify-center"},[_c('router-link',{staticClass:"navbar__brand-logo hide-on-scroll hidden-sm-and-down",attrs:{"to":{ name: 'Home' }}},[_c('v-img',{attrs:{"src":require('@/assets/images/LA_logo_vaaka.png'),"contain":"","height":"50"}})],1)],1),_c('div',{staticClass:"d-flex pa-2 justify-end align-center navbar__top-right"},[_c('LoginMenu'),_c('FavMenu'),_c('CartMenu')],1),_c('div',{staticClass:"\n          hidden-sm-and-down\n          justify-center\n          align-center\n          hide-on-scroll\n          navbar__links\n        "},[_c('v-sheet',{staticClass:"mx-auto"},[_c('v-slide-group',{staticClass:"hidden-sm-and-down"},[_vm._l((_vm.mainMenu),function(n,i){return [(n.showInDesktop)?_c('v-slide-item',{key:i,staticClass:"mx-6",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
              var toggle = ref.toggle;
return [(n.hoverMenu)?_c('router-link',{attrs:{"to":n.url,"exact":"","active-class":"router-link-exact-path-active"}},[_c('v-btn',{staticClass:"nav-btn",attrs:{"text":"","tile":"","ripple":false,"color":active ? undefined : 'black',"tabindex":-1},on:{"click":toggle}},[_c('v-menu',{staticClass:"cart-menu",staticStyle:{"width":"100%"},attrs:{"transition":"fade-transition","offset-y":"","open-on-hover":"","tile":"","min-width":"90%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',[_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.lang(n.name))+" ")])])]}}],null,true)},[_c('v-card',{staticClass:"d-flex flex-column overflow-y-auto mega-menu",attrs:{"tile":"","outlined":"","max-height":"calc(100vh - 140px)","width":"100%","div":""}},[_c('div',{staticClass:"d-flex flex-column pa-5 flex-shrink-0",staticStyle:{"width":"100%"}},[_c('navbar-hover')],1)])],1)],1),_c('v-icon',[_vm._v("$"+_vm._s("Icon16ChevronDown"))])],1):_c('router-link',{attrs:{"to":n.url,"exact":"","active-class":"router-link-exact-path-active"}},[_c('v-btn',{staticClass:"nav-btn",attrs:{"text":"","tile":"","ripple":false,"color":active ? undefined : 'black',"tabindex":-1},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm.lang(n.name))+" ")])],1)]}}],null,true)}):_vm._e()]})],2)],1)],1),_c('div',{staticClass:"\n          d-flex\n          pa-2\n          justify-center\n          align-center\n          fixed-search-on-scroll\n          navbar__search-container\n        "},[_c('div',{staticClass:"my-1",staticStyle:{"width":"100% !important","max-width":"900px"}},[_c('MainSearchBar')],1)])])]),_c('v-navigation-drawer',{staticClass:"navdrawer",attrs:{"temporary":"","app":"","width":"90vw"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"d-flex align-center pl-12 justify-center"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('v-img',{staticClass:"mx-auto my-6 flex-shrink-1",attrs:{"src":require('@/assets/images/LA_logo_vaaka.png'),"contain":"","max-height":"40px","max-width":"100%"}})],1),_c('v-btn',{staticClass:"ma-4",attrs:{"icon":"","right":"","width":"40","height":"40"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-icon',[_vm._v("$"+_vm._s("Icon16Close"))])],1)],1),_c('v-treeview',{attrs:{"items":_vm.items2,"activatable":"","item-key":"name","open-on-click":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
              var item = ref.item;
return [(item.url)?_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"text":"","ripple":false,"height":"48","tile":"","to":{ path: item.url }},on:{"click":function($event){_vm.drawer = false}}},[_vm._v(" "+_vm._s(item.name)+" ")]):(item.uuid)?_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"text":"","ripple":false,"height":"48","tile":"","to":{
            name: 'product-list',
            params: { category: _vm.getSeoUrl(item), uuid: item.uuid },
          }},on:{"click":function($event){_vm.drawer = false}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.name)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }