<template>
  <div class="cart-menu-item d-flex flex-column bb-cart-menu-item">
    <div
      v-for="(item, i) in this.$store.state.cart.shoppingCart"
      :key="i"
      class="d-flex"
    >
      <router-link
        :to="{
          name: 'ProductPage',
          params: { sku: realSku(item), name: generateSlug(item.name) },
        }"
      >
        <v-img
          v-if="item.thumbnail"
          width="120"
          height="120"
          aspect-ratio="1"
          class="card-menu-item__img flex-grow-0"
          :src="baseurl + item.thumbnail + '/small.png'"
        ></v-img>
        <v-img
          v-else-if="item.assets"
          width="120"
          height="120"
          aspect-ratio="1"
          class="card-menu-item__img flex-grow-0"
          :src="baseurl + item.assets[0] + '/small.png'"
        ></v-img>
        <v-img
          v-else
          width="120"
          height="120"
          aspect-ratio="1"
          class="card-menu-item__img flex-grow-0"
          src="@/assets/images/no-img.jpg"
        >
        </v-img>
      </router-link>
      <div class="d-fex flex-column flex-grow-0 pa-4" style="width: 100%">
        <div class="d-flex align-center" style="height: 40px">
          <router-link
            :to="{
              name: 'ProductPage',
              params: { sku: realSku(item), name: generateSlug(item.name) },
            }"
          >
            <h4 class="card-menu-item__product-name text-truncate">
              {{ item.name }}
            </h4>
          </router-link>
        </div>
        <div class="d-flex align-center" style="height: 40px">
          <div style="display: inline-block">
            <span v-if="item.discount">
              Alennus {{ item.discount.percent }} %
            </span>
            <span
              v-for="(variant, i) in item.variantProduct"
              :key="i + variant.name"
            >
              <span>{{ variant.name }}</span>
              <span>:</span>
              <span>
                {{ variant.value }}
              </span>
              <span v-if="variant.name === 'Paino'">kg</span>
              <span v-if="item.variantProduct.length > i + 1">, </span>
            </span>
          </div>
          <!-- <div class="body-2 cart-menu-item__text flex-grow-1 pr-2">
            {{ item.variantProduct }}
            <p v-for="(item, i) in item.variantProduct" :key="i + item.name">
              {{ item.name }}: {{ item.value }}
            </p>
          </div> -->
          <v-btn
            @click="removeFromCart(item.uuid)"
            outlined
            rounded
            small
            class="cart-menu-item__remove-btn ml-auto"
          >
            <v-icon left> mdi-close </v-icon>
            Poista
          </v-btn>
        </div>
        <div class="d-flex align-center" style="height: 40px">
          <StepperInput :item="item" />
          <div class="d-flex flex-wrap align-baseline ml-auto">
            <div class="cart-menu-item__currency mr-2" style="line-height: 1">
              yht.
            </div>
            <div
              class="cart-menu-item__cost-value font-weight-bold"
              style="line-height: 1"
            >
              <!-- {{ item.price.toFixed(2) }}€ -->
              {{ calculatePrice(item) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-divider></v-divider>
  </div>
</template>

<style scoped>
.card-menu-item__product-name {
  max-width: 260px;
}
.cart-menu-item__text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 20px;
  height: 40px;
  max-width: 180px;
}
</style>

<script>
import StepperInput from "@/components/StepperInput";

export default {
  components: { StepperInput },
  data() {
    return {
      baseurl: process.env.VUE_APP_BBSHOP_IMAGES_ENDPOINT,
    };
  },
  methods: {
    realSku: function(item) {
      if (item.baseProduct) {
        return item.baseProduct.sku;
      } else {
        return item.sku;
      }
    },
    calculatePrice(item) {
      // console.log(item.discount);
      let price = 0;
      if (item.discount) {
        price = item.price * (1.0 - item.discount.percent / 100);
      } else {
        price = item.price;
      }
      let totalPrice = price * item.count;
      return totalPrice.toFixed(2);
      // let price = item.price * item.count;
      // return price.toFixed(2);
    },
    generateSlug: function(name) {
      let lowerCase = name.toLowerCase();
      let replaceSpaces = lowerCase.replaceAll(" ", "-");
      let replaceUmlaut = replaceSpaces
        .replaceAll("ä", "a")
        .replaceAll("ö", "ö")
        .replaceAll("å", "a")
        .replaceAll("ü", "u");
      let replaceSymbols = replaceUmlaut.replaceAll(/[^a-z0-9\-]/g, "-"); //eslint-disable-line
      let replaceExtraDashes = replaceSymbols.replaceAll(/-+/g, "-");
      return replaceExtraDashes;
    },
    removeFromCart: function(uuid) {
      this.$store.commit("removeFromCart", { uuid: uuid });
    },
  },
};
</script>
