<template>
  <div class="d-flex pa-8">
    <div class="d-flex flex-column" :style="'max-width:' + data.maxWidth">
      <h1 v-if="data.title" class="pb-6">{{ lang(data.title) }}</h1>
      <p v-if="data.text">
        {{ lang(data.text) }}
      </p>
      <div v-for="(item, i) in data.list" class="d-flex my-3" :key="i">
        <v-icon v-if="item.icon" left> {{ item.icon }} </v-icon>
        <h4 v-if="item.text">
          {{ lang(item.text) }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>