<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 13.4719C16 13.8033 15.7314 14.0719 15.4 14.0719H0.6C0.268629 14.0719 -1.44847e-08 13.8033 0 13.4719C1.44847e-08 13.1406 0.268629 12.8719 0.6 12.8719H15.4C15.7314 12.8719 16 13.1406 16 13.4719Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 13.501C11 12.6713 10.3268 11.9989 9.49708 12L8.49843 12.0013C7.67056 12.0023 7 12.6738 7 13.5016C7 14.3303 7.67173 15.002 8.50035 15.002H9.49901C10.328 15.002 11 14.33 11 13.501Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 8C16 8.33137 15.7314 8.6 15.4 8.6L0.601584 8.6C0.270214 8.6 0.00158695 8.33137 0.00158697 8C0.00158698 7.66863 0.270213 7.4 0.601584 7.4L15.4 7.4C15.7314 7.4 16 7.66863 16 8Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 8C6 7.1703 5.32689 6.49796 4.49719 6.4989L3.5 6.5C2.67211 6.50094 2.00147 7.17235 2.00147 8.00024C2.00147 8.8288 2.67315 9.50049 3.50171 9.50049L4.4989 9.50109C5.32794 9.50109 6 8.82903 6 8Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 2.5C16 2.83137 15.7314 3.1 15.4 3.1H0.604151C0.272779 3.1 0.00415017 2.83137 0.00415019 2.5C0.0041502 2.16863 0.272779 1.9 0.604151 1.9H15.4C15.7314 1.9 16 2.16863 16 2.5Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 2.50291C13 1.67319 12.3268 1.00086 11.4971 1.00192L10.4984 1.0032C9.67056 1.00426 9 1.67568 9 2.50355C9 3.33217 9.67173 4.0039 10.5003 4.0039H11.499C12.328 4.0039 13 3.33189 13 2.50291Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon16Sliders",
};
</script>

<style></style>
