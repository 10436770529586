<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0014 3.05156C11.2491 2.83141 11.6283 2.85372 11.8484 3.10139L15.8484 7.60139C16.0505 7.82872 16.0505 8.17129 15.8484 8.39862L11.8484 12.8986C11.6283 13.1463 11.2491 13.1686 11.0014 12.9485C10.7537 12.7283 10.7314 12.3491 10.9516 12.1014L14.0639 8.60001H0.6C0.268629 8.60001 0 8.33138 0 8.00001C0 7.66864 0.268629 7.40001 0.6 7.40001H14.0639L10.9516 3.89862C10.7314 3.65095 10.7537 3.27171 11.0014 3.05156Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Icon16ArrowRight",
};
</script>

<style></style>
