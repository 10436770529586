<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 6C2 5.44772 2.44772 5 3 5H19C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H3C2.44772 7 2 6.55228 2 6Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 12C4 11.4477 4.44772 11 5 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H5C4.44772 13 4 12.5523 4 12Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 18C2 17.4477 2.44772 17 3 17H19C19.5523 17 20 17.4477 20 18C20 18.5523 19.5523 19 19 19H3C2.44772 19 2 18.5523 2 18Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Category",
};
</script>

<style></style>
