<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5765 22.6465L12 23L12.4235 22.6465C21.5294 15.2222 24 12.6061 24 8.36364C24 4.82828 21.1765 2 17.6471 2C15.4917 2 14.0411 2.90194 12.9745 3.88811C12.6088 4.22615 12.2883 4.57409 12 4.89899C11.7117 4.57409 11.3912 4.22615 11.0255 3.88811C9.95889 2.90194 8.50826 2 6.35294 2C2.82353 2 0 4.82828 0 8.36364C0 12.6061 2.47059 15.2222 11.5765 22.6465ZM12 21.1849C16.301 17.6675 18.9523 15.3388 20.5499 13.4169C22.1301 11.5158 22.6 10.1093 22.6 8.36364C22.6 5.59926 20.4011 3.4 17.6471 3.4C15.3862 3.4 14.1199 4.6194 13.0471 5.82823L12 7.00825L10.9529 5.82823C9.88015 4.6194 8.6138 3.4 6.35294 3.4C3.59895 3.4 1.4 5.59926 1.4 8.36364C1.4 10.1093 1.86988 11.5158 3.45015 13.4169C5.04766 15.3388 7.69896 17.6675 12 21.1849Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Heart",
};
</script>

<style></style>
