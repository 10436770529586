<template>
  <div class="d-flex flex-row mb-6 micro-card">
    <v-hover
      v-slot="{ hover }"
      open-delay="100"
      v-for="(card, i) in cards"
      :key="i"
    >
      <router-link v-if="card.url !== null" :to="card.url">
        <v-card
          flat
          class="micro-card ma-2 pa-4"
          max-width="240"
          :elevation="hover ? 4 : 0"
          :class="{ 'on-hover': hover }"
        >
          <div class="d-flex flex-column flex-md-row">
            <v-img
              :src="require('@/assets/images/' + card.image)"
              class="mr-4 flex-grow-0"
              height="64"
              width="64"
            >
            </v-img>
            <div class="d-flex flex-column justify-center flex-grow-1">
              <h4>
                {{ card.name }}
                <v-icon small class="ml-1">
                  {{ card.icon }}
                </v-icon>
              </h4>
              <p class="ma-0 body-2">
                {{ lang(card.text) }}
              </p>
            </div>
          </div>
        </v-card>
      </router-link>
      <v-card
        v-else
        flat
        class="micro-card ma-2 pa-4"
        max-width="240"
        :elevation="hover ? 4 : 0"
        :class="{ 'on-hover': hover }"
      >
        <div class="d-flex flex-column flex-md-row">
          <v-img
            :src="require('@/assets/images/' + card.image)"
            class="mr-4 flex-grow-0"
            height="64"
            width="64"
          >
          </v-img>
          <div class="d-flex flex-column justify-center flex-grow-1">
            <h4>
              {{ card.name }}
              <v-icon small class="ml-1">
                {{ card.icon }}
              </v-icon>
            </h4>
            <p class="ma-0 body-2">
              {{ lang(card.text) }}
            </p>
          </div>
        </div>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      cards: this.$store.state.cms.data.microCard[this.id],
      dialog: false,
    };
  },
};
</script>
