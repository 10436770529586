<template>
  <div>
    <h2 v-if="data.title" class="mb-3">{{ lang(data.title) }}</h2>
    <v-row class="mb-8">
      <template v-for="(item, i) in data.items">
        <v-col cols="8" :key="item.image" v-if="i === 0">
          <router-link
            v-if="item.link && item.link !== ''"
            :to="{ name: 'product-list', params: { category: item.link } }"
          >
            <v-img
              :src="require('@/assets/images/' + item.image)"
              height="100%"
            ></v-img>
          </router-link>
          <v-img
            v-else
            :src="require('@/assets/images/' + item.image)"
            height="100%"
          ></v-img>
        </v-col>
        <v-col cols="4" v-if="i < data.items.length" :key="i">
          <v-row v-for="(item, i) in data.items2" :key="i">
            <v-col>
              <router-link
                v-if="item.link && item.link !== ''"
                :to="{ name: 'product-list', params: { category: item.link } }"
              >
                <v-img :src="require('@/assets/images/' + item.image)"></v-img>
              </router-link>
              <v-img
                v-else
                :src="require('@/assets/images/' + item.image)"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>