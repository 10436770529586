<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.57574 1.57574C1.81005 1.34142 2.18995 1.34142 2.42427 1.57574L14.4243 13.5757C14.6586 13.8101 14.6586 14.1899 14.4243 14.4243C14.19 14.6586 13.8101 14.6586 13.5757 14.4243L1.57574 2.42426C1.34142 2.18995 1.34142 1.81005 1.57574 1.57574Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4243 1.57574C14.19 1.34142 13.8101 1.34142 13.5757 1.57574L1.57574 13.5757C1.34142 13.8101 1.34142 14.1899 1.57574 14.4243C1.81005 14.6586 2.18995 14.6586 2.42427 14.4243L14.4243 2.42426C14.6586 2.18995 14.6586 1.81005 14.4243 1.57574Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon16Close",
};
</script>

<style></style>
