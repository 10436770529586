<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-40"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.300003 9C0.300003 7.50883 1.50883 6.3 3 6.3H37C38.4912 6.3 39.7 7.50883 39.7 9V32C39.7 33.4912 38.4912 34.7 37 34.7H3C1.50883 34.7 0.300003 33.4912 0.300003 32V9ZM3 7.7C2.28203 7.7 1.7 8.28203 1.7 9V32C1.7 32.718 2.28203 33.3 3 33.3H37C37.718 33.3 38.3 32.718 38.3 32V9C38.3 8.28203 37.718 7.7 37 7.7H3Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39 17.5H1V12.5H39V17.5Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 30H5V28H21V30Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon40CreditCardPay",
};
</script>

<style></style>
