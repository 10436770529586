<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.505 16.505C16.7784 16.2317 17.2216 16.2317 17.495 16.505L23.7448 22.7549C24.0182 23.0282 24.0183 23.4716 23.745 23.745C23.4716 24.0183 23.0284 24.0183 22.755 23.745L16.505 17.495C16.2317 17.2216 16.2317 16.7784 16.505 16.505Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 18.6C14.7496 18.6 18.6 14.7496 18.6 10C18.6 5.25035 14.7496 1.4 10 1.4C5.25035 1.4 1.4 5.25035 1.4 10C1.4 14.7496 5.25035 18.6 10 18.6ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Search",
};
</script>

<style></style>
