<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0024 1.98L23.8848 9.72938L23.12 10.902L21.7024 9.97751V22.0157H13.8024V16.5157H10.2024V22.0157H2.30238V9.97751L0.88477 10.902L0.119995 9.72938L12.0024 1.98ZM3.70238 9.06446V20.6157H8.80238V15.1157H15.2024V20.6157H20.3024V9.06446L12.0024 3.65142L3.70238 9.06446Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Home",
};
</script>

<style></style>
