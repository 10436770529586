import { render, staticRenderFns } from "./CardStyle1.vue?vue&type=template&id=6ac748bc&scoped=true"
import script from "./CardStyle1.vue?vue&type=script&lang=js"
export * from "./CardStyle1.vue?vue&type=script&lang=js"
import style0 from "./CardStyle1.vue?vue&type=style&index=0&id=6ac748bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ac748bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
