<template>
  <div v-if="data.style === '1'">
    <card-style-1 :data="data"> </card-style-1>
  </div>
  <div v-else-if="data.style === '2'">
    <card-style-2 :data="data"> </card-style-2>
  </div>
  <div v-else-if="data.style === '3'">
    <card-style-3 :data="data"> </card-style-3>
  </div>
  <div v-else-if="data.style === '4'">
    <card-style-4 :data="data"> </card-style-4>
  </div>
  <div v-else-if="data.style === '5'">
    <card-style-5 :data="data"> </card-style-5>
  </div>
  <div v-else-if="data.style === '6'">
    <card-style-6 :data="data"> </card-style-6>
  </div>
</template>

<script>
import CardStyle1 from "./styles/CardStyle1.vue";
import CardStyle2 from "./styles/CardStyle2.vue";
import CardStyle3 from "./styles/CardStyle3.vue";
import CardStyle4 from "./styles/CardStyle4.vue";
import CardStyle5 from "./styles/CardStyle5.vue";
import CardStyle6 from "./styles/CardStyle6.vue";

export default {
  components: {
    CardStyle1,
    CardStyle2,
    CardStyle3,
    CardStyle4,
    CardStyle5,
    CardStyle6,
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style>
</style>