<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-40"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.8 11.8H23.2V18.2H16.8V11.8ZM18.2 13.2V16.8H21.8V13.2H18.2Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 21.1992L29.34 26.3881L28.6601 27.6119L20 22.8008L11.34 27.6119L10.6601 26.3881L20 21.1992Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 6.20796L37.3275 15.3814L36.6725 16.6187L20 7.79205L3.32753 16.6187L2.67249 15.3814L20 6.20796Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.8 10V6.00001H26.2V10H24.8ZM9.80001 12.5V8.50001H11.2V12.5H9.80001Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 2.19923L27.84 6.55476L27.1601 7.77858L20 3.80078L8.83996 10.0008L8.16006 8.77698L20 2.19923Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7 35.3V26H12.3V36.7H27.7V26H26.3V35.3H13.7Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3 28.3H23.7V36H22.3V29.7H17.7V36H16.3V28.3Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.80001 15H6.20001V34.3H12.7419V35.7H4.80001V15ZM33.8 34.3V15H35.2V35.7H26.7742V34.3H33.8Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon40Kuivuri",
};
</script>

<style></style>
