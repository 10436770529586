export default {
  state: {
    orders: {},
  },
  getters: {},
  mutations: {
    emptyOrders(state) {
      state.orders = {};
    },
    addOrders(state, orders) {
      // console.log(orders);
      state.orders = orders;
    },
  },
  actions: {},
  module: {},
};
