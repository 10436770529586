<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H10C10.2652 0 10.5196 0.105357 10.7071 0.292893C10.8946 0.48043 11 0.734784 11 1V10C11 10.5523 10.5523 11 10 11H1C0.447715 11 0 10.5523 0 10V1ZM2 2V9H9V2H2Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 14C0 13.4477 0.447715 13 1 13H10C10.2652 13 10.5196 13.1054 10.7071 13.2929C10.8946 13.4804 11 13.7348 11 14V23C11 23.5523 10.5523 24 10 24H1C0.447715 24 0 23.5523 0 23V14ZM2 15V22H9V15H2Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 1C13 0.447715 13.4477 0 14 0H23C23.2652 0 23.5196 0.105357 23.7071 0.292893C23.8946 0.48043 24 0.734784 24 1V10C24 10.5523 23.5523 11 23 11H14C13.4477 11 13 10.5523 13 10V1ZM15 2V9H22V2H15Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 14C13 13.4477 13.4477 13 14 13H23C23.2652 13 23.5196 13.1054 23.7071 13.2929C23.8946 13.4804 24 13.7348 24 14V23C24 23.5523 23.5523 24 23 24H14C13.4477 24 13 23.5523 13 23V14ZM15 15V22H22V15H15Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Grid",
};
</script>

<style></style>
