<template>
<div class='home flex-1 overflow-hidden pb-8' style='width:100%;'>
  <component
    :is="{
      name: 'dynamic-content',
      template: this.contentTemplate
    }"
  />
  </div>
</template>
<script>

// import { defineComponent, ref } from "vue";
// import BBShopApi from "./bb-shop-api";
import template from "./data/mainview.json";

export default {
  props: {
    pageId: String,
    query: Object,
    params: Array
  },

    data: () => ({ 
      // contentTemplate: "<div>Json data here...</div>"
      contentTemplate: template.template.content
    }),

    // mounted: () => {
    //   console.log(template.template.content)
    // }

  // setup(props) {
  //   // const contentTemplate = ref("<div>Loading...</div>");
  //   const contentTemplate = ""
  //   console.debug(props.pageId);
  //   console.debug(props.query);
  //   console.debug(props.params);

  //   BBShopApi.page(props.pageId || "").then(response => {
  //     contentTemplate.value = response.template.content;
  //   });

  //   return {
  //     contentTemplate: contentTemplate
  //   };
  // }
};
</script>
