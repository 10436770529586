<template>
  <div class="d-flex family mb-12 justify-center">
    <v-img
      v-if="data.image"
      :src="require('@/assets/images/' + data.image)"
      width="500"
      max-width="25%"
      min-width="240"
      contain
      class="flex-grow-0 mb-auto"
    >
    </v-img>
    <div class="d-flex justify-center align-center pa-8">
      <div class="d-flex flex-column" style="max-width: 580px">
        <h1 v-if="data.title" class="pb-6">
          {{ lang(data.title) }}
        </h1>
        <p v-if="data.text">
          {{ lang(data.text) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .family {
    flex-direction: column;
    align-items: center;
  }

  .family .v-image {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>