<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 2.7C11 1.98203 10.418 1.4 9.7 1.4L0.7 1.4C0.3134 1.4 -4.74968e-08 1.0866 -3.0598e-08 0.700001C-1.36992e-08 0.313402 0.3134 1.37903e-06 0.7 1.39592e-06L9.7 1.78933e-06C11.1912 1.85451e-06 12.4 1.20883 12.4 2.7L12.4 21.3C12.4 22.7912 11.1912 24 9.70001 24L0.700009 24C0.31341 24 9.45504e-06 23.6866 9.47194e-06 23.3C9.48884e-06 22.9134 0.31341 22.6 0.700009 22.6L9.70001 22.6C10.418 22.6 11 22.018 11 21.3L11 2.7Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.0118 6.95363C19.3007 6.69678 19.7432 6.72281 20 7.01176L24 11.5118C24.2358 11.777 24.2358 12.1766 24 12.4419L20 16.9419C19.7432 17.2308 19.3007 17.2568 19.0118 17C18.7228 16.7432 18.6968 16.3007 18.9536 16.0118L21.918 12.6768L14.5 12.7C14.1134 12.7 13.8 12.3866 13.8 12C13.8 11.6134 14.1134 11.3 14.5 11.3L21.918 11.2768L18.9536 7.94187C18.6968 7.65292 18.7228 7.21047 19.0118 6.95363Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Logout",
};
</script>

<style></style>
