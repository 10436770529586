import axios from "axios";

export default {
  page: async function(pageId) {
    let url = process.env.VUE_APP_CMS_ENDPOINT
    if (pageId) {
      return await axios.get(url + "/" + pageId);
    } else {
      return await axios.get(url);
    }
  },
};
