var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"item-row"},[(this.items)?_c('h3',[(_vm.link)?_c('router-link',{attrs:{"to":{
        name: 'product-list',
        params: { category: _vm.link },
      }}},[(_vm.title)?_c('h3',{staticClass:"text-center display-1 mt-8 mb-5 item-row__header"},[_vm._v(" "+_vm._s(this.title)+" ")]):(_vm.name)?_c('h3',{staticClass:"text-center display-1 mt-8 mb-5 item-row__header"},[_vm._v(" "+_vm._s(this.fixName(_vm.name))+" ")]):_vm._e()]):_c('router-link',{attrs:{"to":{
        name: 'product-list',
        params: { category: this.categoryName(this.category).seoName },
      }}},[(_vm.title)?_c('h3',{staticClass:"text-center display-1 mt-8 mb-5 item-row__header"},[_vm._v(" "+_vm._s(this.title)+" ")]):(_vm.name)?_c('h3',{staticClass:"text-center display-1 mt-8 mb-5 item-row__header"},[_vm._v(" "+_vm._s(this.fixName(_vm.name))+" ")]):_vm._e()])],1):_vm._e(),(this.text)?_c('p',{staticClass:"pt-1 pb-10 text-center pr-5 pl-5"},[_vm._v(" "+_vm._s(_vm.lang(this.text))+" ")]):_vm._e(),_c('div',{staticClass:"d-flex align-strech"},[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"next-slide__button",attrs:{"icon":"","height":"auto","tile":""},on:{"click":_vm.swipeLeft}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("$"+_vm._s("Icon24ChevronBoldLeft"))])],1),(this.items !== '')?_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOptions}},[_vm._l((this.items),function(item,i){return _c('swiper-slide',{key:i},[_c('ProductCard',{attrs:{"product":item}})],1)}),_c('swiper-slide',[_c('v-card-actions',{staticClass:"justify-center align-center",staticStyle:{"height":"100%"}},[(_vm.link)?_c('v-btn',{staticClass:"px-4 absolute product-card__add-cart-btn",attrs:{"x-large":"","rounded":"","outlined":"","to":{
              name: 'product-list',
              params: { category: _vm.link },
            }}},[_vm._v(" Katso lisää ")]):_c('v-btn',{staticClass:"px-4 absolute product-card__add-cart-btn",attrs:{"x-large":"","rounded":"","outlined":"","to":{
              name: 'product-list',
              params: { category: this.categoryName(this.category).seoName },
            }}},[_vm._v(" Katso lisää ")])],1)],1)],2):_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOptions}},_vm._l((4),function(item,i){return _c('swiper-slide',{key:i},[_c('v-skeleton-loader',{attrs:{"type":"image, list-item-two-line, actions"}})],1)}),1),_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],staticClass:"prev-slide__button",attrs:{"icon":"","height":"auto","tile":""},on:{"click":_vm.swipeRight}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("$"+_vm._s("Icon24ChevronBoldRight"))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }