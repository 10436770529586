<template>
  <div :class="data.css.class" :style="data.css.style">
    <h1 v-if="data.title" class="pb-6">{{ lang(data.title) }}</h1>
    <p v-if="data.text">
      {{ lang(data.text) }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>