<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.7 5.03678L12 0.75L0.299988 5.03678V18.9995L12 23.2518L23.7 18.9995V5.03678ZM3.04833 5.52083L12 8.75132L20.9517 5.52083L12 2.24101L3.04833 5.52083ZM11.3 9.98708L1.70001 6.52262V17.9928L11.3 21.4602V9.98708ZM12.7 9.98708L22.3 6.52262V17.9928L12.7 21.4602V9.98708Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Box",
};
</script>

<style></style>
