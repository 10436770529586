export default {
  state: {
    shoppingCart: [],
    totalPrice: 0,
    discountList: [],
  },
  getters: {
    checkDiscountList: (state) => (item) => {
      let discount = undefined;
      state.discountList.forEach((e) => {
        if (e.appliesToCategory) {
          e.appliesToCategory.every((x) => {
            if (item.includes(x)) {
              discount = e;
            }
          });
        }
      });

      if (discount) {
        return discount;
      }
    },
    cartTotal: (state) => {
      state.totalPrice = 0;
      state.shoppingCart.map((item) => {
        if (item.discount) {
          state.totalPrice +=
            item.price * (1.0 - item.discount.percent / 100) * item.count;
        } else {
          state.totalPrice += item.price * item.count;
        }
      });
      return state.totalPrice.toFixed(2);
    },
    cartTotalItems: (state) => {
      let total = 0;
      state.shoppingCart.map((item) => {
        total += item.count;
      });
      return total;
    },
    itemInCart: (state) => (item) => {
      let check = state.shoppingCart.filter((i) => i.uuid === item);
      if (check.length > 0) {
        return check[0].count;
      } else {
        return false;
      }
    },
    checkSpecialDelivery: (state) => {
      let special = true;
      state.shoppingCart.forEach((item) => {
        if (item.details.delivery_class) {
          if (item.details.delivery_class.value === "ORDER_ONLY") {
            special = false;
          }
        }
      });
      return special;
    },
  },
  mutations: {
    addToCart(state, item) {
      // console.log(item);
      let find = true;
      state.shoppingCart.map((cartItem) => {
        // console.log(state.shoppingCart);
        if (cartItem.uuid === item.uuid) {
          if (item.in_stock > cartItem.count) {
            cartItem.count += item.count;
            find = false;
            localStorage.setItem("cart", JSON.stringify(state.shoppingCart));
            this.state.snacktext = {
              text: item.name,
              message: " lisätty ostoskoriin",
              color: "success",
            };
          } else {
            find = false;
            this.state.snacktext = {
              text: item.name,
              message: " tuotetta ei riittävästi varastossa",
              color: "error",
            };
          }
        }
      });

      if (find === true) {
        state.shoppingCart.push(item);
        localStorage.setItem("cart", JSON.stringify(state.shoppingCart));
        this.state.snacktext = {
          text: item.name,
          message: " lisätty ostoskoriin",
          color: "success",
        };
      }

      if (state.shoppingCart.length <= 0) {
        state.shoppingCart.push(item);
        localStorage.setItem("cart", JSON.stringify(state.shoppingCart));
      }
    },

    removeFromCart(state, uuid) {
      let tempCart = state.shoppingCart.filter(
        (item) => item.uuid !== uuid.uuid
      );
      state.shoppingCart = tempCart;
      localStorage.setItem("cart", JSON.stringify(state.shoppingCart));
    },

    removeItem(state, item) {
      state.shoppingCart.map((cartItem) => {
        if (cartItem.uuid === item.uuid) {
          if (cartItem.count >= 2) {
            cartItem.count -= item.count;
          }
        }
      });
      localStorage.setItem("cart", JSON.stringify(state.shoppingCart));
    },

    addItem(state, item) {
      state.shoppingCart.map((cartItem) => {
        if (cartItem.uuid === item.uuid) {
          cartItem.count += item.count;
        }
      });
      localStorage.setItem("cart", JSON.stringify(state.shoppingCart));
    },
    loadCart(state) {
      if (localStorage.getItem("cart") !== null) {
        let cart = localStorage.getItem("cart");
        state.shoppingCart = JSON.parse(cart);
      } else {
        state.shoppingCart = [];
      }
    },
    emptyShoppingCart(state) {
      state.shoppingCart = [];
      localStorage.removeItem("cart");
    },
    updateDiscountList(state, list) {
      state.discountList = list;
      // console.log(state.discountList);
    },
  },
  actions: {},
  module: {},
};
