<template>
  <header :class="{ scrolled: !scrolled }">
    <div class="navbar">
      <div class="d-flex pa-2 navbar__wrapper" style="width: 100%">
        <div class="d-flex pa-2 align-center navbar__top-left">
          <v-btn
            icon
            large
            class="mobile-drawer__toggle navbar__menu"
            @click.stop="(drawer = !drawer), (showSearch = false)"
          >
            <v-icon>${{ "Icon24Menu" }}</v-icon>
          </v-btn>
          <!-- <v-btn
            v-if="scrolled"
            text
            class="ml-2 navbar__products-btn hidden-sm-and-down"
            @click="
              (expandCategoryDrawer = true),
                $root.$emit('update', expandCategoryDrawer),
                (showSearch = false)
            "
          >
            <v-icon>${{ "Icon24Category" }}</v-icon>
            <span class="ml-2">Tuotteet</span>
          </v-btn> -->
          <v-btn
            v-if="!showSearch"
            icon
            large
            class="navbar__search-btn d-sm-none"
            @click="
              toggleSearch(),
                (drawer = false),
                (expandCategoryDrawer = false),
                $root.$emit('update', expandCategoryDrawer)
            "
          >
            <v-icon>${{ "Icon24Search" }}</v-icon>
          </v-btn>
          <v-fade-transition>
            <div
              v-show="showSearch"
              class="hide-search-on-large px-4 mx-0 align-center"
              style="width: 100%; z-index: 999"
            >
              <MainSearchBar style="width: 100%; max-width: 600px" />
              <v-btn
                icon
                large
                class="navbar__search-btn"
                @click="toggleSearch()"
              >
                <v-icon>${{ "Icon16Close" }}</v-icon>
              </v-btn>
            </div>
          </v-fade-transition>
        </div>

        <div class="pa-2 navbar__top-middle hide-on-scroll justify-center">
          <router-link
            :to="{ name: 'Home' }"
            class="navbar__brand-logo hide-on-scroll hidden-sm-and-down"
          >
            <v-img
              :src="require('@/assets/images/LA_logo_vaaka.png')"
              contain
              height="50"
            ></v-img>
          </router-link>
        </div>

        <div class="d-flex pa-2 justify-end align-center navbar__top-right">
          <LoginMenu />
          <FavMenu />
          <CartMenu />
        </div>

        <div
          class="
            hidden-sm-and-down
            justify-center
            align-center
            hide-on-scroll
            navbar__links
          "
        >
          <v-sheet class="mx-auto">
            <v-slide-group class="hidden-sm-and-down">
              <template v-for="(n, i) in mainMenu">
                <v-slide-item
                  :key="i"
                  v-if="n.showInDesktop"
                  v-slot="{ active, toggle }"
                  class="mx-6"
                >
                  <!-- <v-hover v-if="i === 0" v-slot="{ hover }">
                    <router-link
                      :to="n.url"
                      exact
                      active-class="router-link-exact-path-active"
                    >
                      <v-btn
                        text
                        tile
                        :ripple="false"
                        class="nav-btn"
                        :color="active ? undefined : 'black'"
                        @click="toggle"
                        :tabindex="-1"
                      >
                        {{ lang(n.name) }}
                      </v-btn>
                   
                        <v-card
                          v-if="hover"
                          tile
                          outlined
                          max-height="calc(100vh - 140px)"
                          width="100%"
                          div
                          class="d-flex flex-column overflow-hidden"
                        >
                          <div
                            class="
                              overflow-y-auto overflow-x-hidden
                              flex-grow-1
                            "
                            style="height: 10rem"
                          >
                            aaa
                          </div>
                        </v-card>
                   
                    </router-link>
                  </v-hover> -->

                  <router-link
                    v-if="n.hoverMenu"
                    :to="n.url"
                    exact
                    active-class="router-link-exact-path-active"
                  >
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="nav-btn"
                      :color="active ? undefined : 'black'"
                      @click="toggle"
                      :tabindex="-1"
                    >
                      <!-- {{ lang(n.name) }} -->

                      <v-menu
                        transition="fade-transition"
                        offset-y
                        open-on-hover
                        tile
                        class="cart-menu"
                        style="width: 100%"
                        min-width="90%"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div>
                            <div v-bind="attrs" v-on="on">
                              {{ lang(n.name) }}
                              <!-- <v-icon>${{ "Icon16ChevronDown" }}</v-icon> -->
                            </div>
                            <!-- <v-btn class="mx-0" icon v-bind="attrs" v-on="on">
                               {{ lang(n.name) }}<v-icon> ${{ "Icon24Shopping" }} </v-icon>
                            </v-btn> -->
                          </div>
                        </template>
                        <v-card
                          tile
                          outlined
                          max-height="calc(100vh - 140px)"
                          width="100%"
                          div
                          class="d-flex flex-column overflow-y-auto mega-menu"
                        >
                          <div
                            class="d-flex flex-column pa-5 flex-shrink-0"
                            style="width: 100%"
                          >
                            <navbar-hover></navbar-hover>
                          </div>
                        </v-card>
                      </v-menu>
                    </v-btn>
                    <v-icon>${{ "Icon16ChevronDown" }}</v-icon>
                  </router-link>
                  <router-link
                    v-else
                    :to="n.url"
                    exact
                    active-class="router-link-exact-path-active"
                  >
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="nav-btn"
                      :color="active ? undefined : 'black'"
                      @click="toggle"
                      :tabindex="-1"
                    >
                      {{ lang(n.name) }}
                    </v-btn>
                  </router-link>
                </v-slide-item>
              </template>
            </v-slide-group>
          </v-sheet>
        </div>
        <div
          class="
            d-flex
            pa-2
            justify-center
            align-center
            fixed-search-on-scroll
            navbar__search-container
          "
        >
          <div class="my-1" style="width: 100% !important; max-width: 900px">
            <MainSearchBar />
          </div>
        </div>
      </div>
    </div>

    <!-- <v-fade-transition>
      <div
        v-show="showSearch"
        class="hide-search-on-large px-4 mx-0 align-center justify-center"
        style="width: 100%"
      >
        <MainSearchBar style="width: 100%; max-width: 600px" />
        <v-btn icon large class="navbar__search-btn" @click="toggleSearch()">
          <v-icon>${{ "Icon16Close" }}</v-icon>
        </v-btn>
      </div>
    </v-fade-transition> -->

    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
      width="90vw"
      class="navdrawer"
    >
      <div class="d-flex align-center pl-12 justify-center">
        <router-link :to="{ name: 'Home' }">
          <v-img
            :src="require('@/assets/images/LA_logo_vaaka.png')"
            contain
            class="mx-auto my-6 flex-shrink-1"
            max-height="40px"
            max-width="100%"
          ></v-img>
        </router-link>
        <v-btn
          @click="drawer = false"
          icon
          right
          class="ma-4"
          width="40"
          height="40"
        >
          <v-icon>${{ "Icon16Close" }}</v-icon>
        </v-btn>
      </div>
      <v-treeview :items="items2" activatable item-key="name" open-on-click>
        <template v-slot:label="{ item }">
          <v-btn
            v-if="item.url"
            text
            @click="drawer = false"
            :ripple="false"
            height="48"
            tile
            style="width: 100%"
            :to="{ path: item.url }"
          >
            {{ item.name }}
          </v-btn>

          <v-btn
            v-else-if="item.uuid"
            text
            @click="drawer = false"
            :ripple="false"
            height="48"
            tile
            style="width: 100%"
            :to="{
              name: 'product-list',
              params: { category: getSeoUrl(item), uuid: item.uuid },
            }"
          >
            {{ item.name }}
          </v-btn>
          <div v-else>
            {{ item.name }}
          </div>
        </template>
      </v-treeview>
    </v-navigation-drawer>
  </header>
</template>

<script>
import CartMenu from "@/components/cart-menu/CartMenu";
import MainSearchBar from "@/components/MainSearchBar";
import FavMenu from "@/components/fav-menu/FavMenu";
import LoginMenu from "@/components/login/LoginMenu";
import NavbarHover from "./NavbarHover.vue";

export default {
  components: {
    CartMenu,
    MainSearchBar,
    FavMenu,
    LoginMenu,
    NavbarHover,
  },
  data() {
    return {
      expandCategoryDrawer: false,
      scrolled: true,
      showSearch: false,
      mainMenu: this.$store.state.cms.mainMenuData,
      drawer: false,
      menuWidth: window.innerWidth,
      products: [],
      tuotteet: [],
      items2: [],
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
    scrolled: function () {
      if (this.scrolled) {
        // console.log("scroll true");
        this.menuWidth = window.innerWidth;
      } else {
        // console.log("scroll false");
        this.menuWidth = window.innerWidth - 500;
      }
    },
    "$store.state.cms.categoryDrawerData": function () {
      this.$store.state.cms.mainMenuData.map((e, i) => {
        // console.log(e);
        if (this.lang(e.name) === "TUOTTEET") {
          this.items2.push({
            name: this.lang(e.name),
            id: i,
            children: this.$store.state.cms.categoryDrawerData,
          });
        } else {
          this.items2.push({
            name: this.lang(e.name),
            id: i,
            url: e.url,
          });
        }
      });

      // console.log("tuotteet data");
      // this.$store.state.cms.categoryDrawerData.map((e) => {
      //   // console.log(e)
      //   this.$set(this.items2, "TUOTTEET", e);
      // });
    },
  },
  methods: {
    getSeoUrl: function (item) {
      for (var i in this.$store.state.cms.categoryData) {
        if (this.$store.state.cms.categoryData[i].name.fi == item.name) {
          return this.$store.state.cms.categoryData[i].seoName;
        }
      }
    },
    makeSubmenu(items) {
      let tempItem = [];
      items.subMenu.map((e, i) => {
        tempItem.push({
          name: this.lang(e.name),
          id: i,
          url: e.url,
        });
      });

      return tempItem;
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition > 260) {
        this.scrolled = currentScrollPosition < this.lastScrollPosition;
      } else if (currentScrollPosition < 260) {
        this.scrolled = true;
      }
      // Stop executing this function if the difference between
      // current scroll position and last scroll position is less than some offset
      if (
        Math.abs(currentScrollPosition - this.lastScrollPosition) <
        window.pageYOffset
      ) {
        return;
      }

      this.lastScrollPosition = currentScrollPosition;
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
    },
  },
  mounted() {
    this.scrolled = true;
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="css" scoped>
header {
  height: 238px;
}

.navbar__wrapper {
  flex-wrap: wrap;
}

header.scrolled .navbar__wrapper {
  justify-content: space-between;
}

.navbar__top-left,
.navbar__top-right {
  width: 20%;
}

header.scrolled .navbar__top-left header.scrolled .navbar__top-right {
  width: 80%;
}

.navbar__top-middle {
  width: 60%;
  display: flex;
}

.navbar__links,
.navbar__search-container {
  width: 100%;
  display: flex;
}

.navbar__links {
  padding: 1rem 0.5rem;
}

header.scrolled .navbar__top-left {
  order: 0;
}

header.scrolled .navbar__top-right {
  order: 3;
}

header.scrolled .navbar__links {
  order: 2;
  width: 60%;
  padding: 0 0.5rem;
}

header.scrolled .navbar__wrapper {
  flex-wrap: nowrap;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 60px;
}

.navbar__search-btn {
  display: none;
}

.v-slide-group a {
  text-decoration: none;
}

a button.nav-btn {
  padding: 0 !important;
  /* font-family: "DalaMoa", sans-serif !important; */
}

a button.nav-btn:before {
  background-color: transparent !important;
}

a button.nav-btn.router-link-exact-active {
  background-color: transparent !important;
}

a button.nav-btn.router-link-exact-active:after {
  width: 100%;
  left: 0;
  background: #000;
}

a .nav-btn:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 1.5rem;
  left: 0;
  background: #000;
  transition: width 0.2s ease;
  -webkit-transition: width 0.2s ease;
}

a .nav-btn:hover:after,
a.router-link-exact-active .nav-btn:after {
  width: 100%;
  left: 0;
  background: #000;
}

.v-list-item a.router-link-exact-active {
  font-weight: 700;
}

.w-full {
  width: 100% !important;
}

.mobile-nav {
  background-color: #000;
}

.theme--dark a {
  color: #fff !important;
  text-decoration: none;
}

header:not(.scrolled) .hide-search-on-large {
  display: none !important;
}

header.scrolled .hide-search-on-large {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: white;
  height: 60px;
  display: flex;
}

.v-navigation-drawer {
  max-width: 500px;
}

.v-navigation-drawer__content .v-list-item {
  height: 48px;
}

.v-navigation-drawer__content .v-list-item:not(.v-list-item--link),
.v-navigation-drawer__content .v-list-item:before,
.v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child) {
  border-radius: 0px !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.v-navigation-drawer__content .v-list-group__header {
  padding: 0 !important;
}

.v-navigation-drawer__content .v-list-item.v-list-item--link {
  padding: 0 15px;
}

.v-navigation-drawer__content .v-list.v-sheet.v-list--dense.v-list--nav {
  padding: 0;
}

.v-list-group__header .v-list-item.v-list-item--link:before {
  background-color: transparent !important;
}

.navdrawer .v-btn.v-btn--router {
  justify-content: start;
  padding: 0;
}

.navdrawer .v-btn.v-btn--router .v-ripple__container {
  display: none;
}

.navdrawer .v-btn--active {
  pointer-events: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 959px) {
  .mobile-drawer__toggle {
    display: block !important;
  }

  .navbar__top-left,
  .navbar__top-left {
    width: 50%;
    flex: 1;
  }

  header {
    height: auto !important;
  }
}

header.scrolled .hide-on-scroll {
  display: none !important;
}

header.scrolled .navbar__search-container {
  margin: 0 !important;
  padding: 0 !important;
}

@media only screen and (max-width: 599px) {
  header.scrolled .fixed-search-on-scroll {
    display: none !important;
  }
}

.show-on-scroll {
  display: none !important;
}

header.scrolled .show-on-scroll {
  display: block !important;
  width: 600px;
  height: 60px;
}

@media only screen and (max-width: 599px) {
  show-on-scroll {
    display: none !important;
  }
}

@media only screen and (max-width: 959px) {
  .mobile-drawer__toggle {
    display: block !important;
  }

  .navbar__top-left,
  .navbar__top-left {
    width: 50%;
    flex: 1;
  }

  header {
    height: auto !important;
  }
}

header.scrolled .hide-on-scroll {
  display: none !important;
}

header .mobile-drawer__toggle {
  display: none;
}

header.scrolled .mobile-drawer__toggle {
  display: block;
}

header.scrolled .navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background: white;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  animation: fadeIn ease 0.4s;
}

header.scrolled .navbar .row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bg-white {
  background: #fff;
}

.search-bar-toggled {
  max-width: 800px;
  margin: 0 auto;
}

header.scrolled .navbar__search-btn {
  display: inline;
}
.v-menu__content {
  left: 24px !important;
  right: 24px;
  max-width: 100%;
}
</style>
