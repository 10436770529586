<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-40"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.3 3C5.3 1.50883 6.50883 0.300003 8 0.300003H32C33.4912 0.300003 34.7 1.50883 34.7 3V19H33.3V3C33.3 2.28203 32.718 1.7 32 1.7H8C7.28203 1.7 6.7 2.28203 6.7 3V19C6.7 19.718 7.28203 20.3 8 20.3H31.7748V21.7H8C6.50883 21.7 5.3 20.4912 5.3 19V3Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34 8.5H6V5.5H34V8.5Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 17.7H10V16.3H21V17.7Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.5242 19.3938C36.7281 18.5994 35.4465 18.5759 34.6219 19.3407L25.2842 28.0003C25.0007 28.2632 24.5578 28.2465 24.2949 27.963C24.0321 27.6796 24.0487 27.2367 24.3322 26.9738L33.6699 18.3142C35.046 17.038 37.1846 17.0771 38.5131 18.4029C39.8808 19.7677 39.8808 21.9836 38.5131 23.3484L27.0727 34.7654C25.296 36.5384 22.9261 37.5911 20.4194 37.7207L11.1823 38.1982L1.06877 39.1966C0.68404 39.2346 0.341367 38.9535 0.303387 38.5688C0.265407 38.184 0.546503 37.8414 0.931233 37.8034L11.0774 36.8018L20.3472 36.3226C22.5084 36.2108 24.5519 35.3031 26.0837 33.7744L37.5242 22.3575C38.3437 21.5396 38.3437 20.2117 37.5242 19.3938Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5163 24.7C8.91008 24.7 5.38956 25.7995 2.42433 27.852L1.90425 28.2119C1.58637 28.432 1.15031 28.3526 0.930287 28.0348C0.710262 27.7169 0.789589 27.2808 1.10747 27.0608L1.62755 26.7008C4.82689 24.4864 8.62536 23.3 12.5163 23.3H22.3046C24.0719 23.3 25.5046 24.7327 25.5046 26.5C25.5046 28.2673 24.0719 29.7 22.3046 29.7H12.5842C12.1976 29.7 11.8842 29.3866 11.8842 29C11.8842 28.6134 12.1976 28.3 12.5842 28.3H22.3046C23.2987 28.3 24.1046 27.4941 24.1046 26.5C24.1046 25.5059 23.2987 24.7 22.3046 24.7H12.5163Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon40PickupPay",
};
</script>

<style></style>
