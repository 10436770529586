export default {
  state: {
    carouselData: [],
    microCardData: [],
    contentBannerData: [],
    itemRowData: [],
    notificationData: [],
    D3StoreData: [],
    mainMenuData: [],
    brandsData: [],
    categoryData: [],
    categoryDataSingle: [],
    categoryNewData: [],
    brands: [],
    inspireData: [],
    data: [],
    categoryDrawerData: [],
    variantOrder: [],
    multiCategory: {},
  },

  getters: {
    categoryUUID: (state) => (item) => {
      let error = {
        uuid: "category not found",
        name: { fi: "error" },
        seoName: "error",
      };
      let find = state.categoryData.filter((i) => i.name.fi === item);

      if (find.length >= 1) {
        return find[0];
      } else {
        for (let x in state.multiCategory) {
          // console.log(x + " vs " + item);
          if (x === item) {
            return { seoName: item, multi: true };
          }
        }
        // console.log(find2)
        // console.log("not found");
        // console.log(state.multiCategory);
      }
      return error;
    },
    categoryName: (state) => (item) => {
      // console.log(state.categoryData)
      let error = { uuid: "category not found", name: { fi: "error" } };
      let find = state.categoryData.filter((i) => i.uuid === item);
      if (find.length >= 1) {
        return find[0];
      }
      return error;
    },
  },

  mutations: {
    updateMultiCategory(state, data) {
      state.multiCategory = data;
    },
    updateVariantOrder(state, data) {
      let temp = data.filter((item) => item !== "length");
      state.variantOrder = temp;
    },
    updateCarouselData(state, data) {
      state.carouselData = data;
    },
    updateMicroCardData(state, data) {
      state.microCardData = data;
    },
    updateContentBannerData(state, data) {
      state.contentBannerData = data;
    },
    updateContentItemRowData(state, data) {
      state.itemRowData = data;
    },
    updateNotificationData(state, data) {
      state.notificationData = data;
    },
    update3DStoreData(state, data) {
      state.D3StoreData = data;
    },
    updateMainMenuData(state, data) {
      state.mainMenuData = data;
    },
    updateBrandsData(state, data) {
      state.brandsData = data;
    },
    updateBrands(state, data) {
      state.brands = data;
    },
    updateCategoryData(state, data) {
      state.categoryData = data;
    },
    updateCategoryDataSingle(state, data) {
      state.categoryDataSingle = data;
    },
    updateCategoryNewData(state, data) {
      state.categoryNewData = data;
    },
    updateBBImageNewData(state, data) {
      state.BBImage.push(data);
      // state.BBImage = data;
    },
    updateCMSData(state, data) {
      state.inspireData = data;
      let names = Object.getOwnPropertyNames(data);
      names.map((e) => {
        state.data[e] = data[e];
        // state[e + "Data"] = data[e];
      });
      // console.log(state.data);
    },
    categoryDrawerData(state, data) {
      state.categoryDrawerData = data;
    },
  },

  actions: {},

  module: {},
};
