import Vue from "vue";
import Vuetify, { VMain } from "vuetify/lib";

// import Vuetify from "vuetify";

import allIcons from "@/components/icons";
import store from "@/store";

// Vue.use(Vuetify);
Vue.use(Vuetify, {
  components: { VMain },
});
//Need to automate 'icons: {}' ...
let icons2 = [];
for (let item in allIcons) {
  icons2.push({ name: item });
}

store.commit("addIcons", icons2);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3F4045",
      },
    },
  },
  icons: {
    values: {
      Icon16ArrowDown: {
        component: allIcons.Icon16ArrowDown,
      },
      Icon16ArrowLeft: {
        component: allIcons.Icon16ArrowLeft,
      },
      Icon16ArrowRight: {
        component: allIcons.Icon16ArrowRight,
      },
      Icon16ArrowUp: {
        component: allIcons.Icon16ArrowUp,
      },
      Icon16ChevronDown: {
        component: allIcons.Icon16ChevronDown,
      },
      Icon16ChevronRight: {
        component: allIcons.Icon16ChevronRight,
      },
      Icon16Close: {
        component: allIcons.Icon16Close,
      },
      Icon16Edit: {
        component: allIcons.Icon16Edit,
      },
      Icon16Reset: {
        component: allIcons.Icon16Reset,
      },
      Icon16Shopping: {
        component: allIcons.Icon16Shopping,
      },
      Icon16Sliders: {
        component: allIcons.Icon16Sliders,
      },

      Icon24Box: {
        component: allIcons.Icon24Box,
      },
      Icon24Category: {
        component: allIcons.Icon24Category,
      },
      Icon24CheckList: {
        component: allIcons.Icon24CheckList,
      },
      Icon24ChevronBoldLeft: {
        component: allIcons.Icon24ChevronBoldLeft,
      },
      Icon24ChevronBoldRight: {
        component: allIcons.Icon24ChevronBoldRight,
      },
      Icon24Clock: {
        component: allIcons.Icon24Clock,
      },
      Icon24CreditCard: {
        component: allIcons.Icon24CreditCard,
      },
      Icon24Facebook: {
        component: allIcons.Icon24Facebook,
      },
      Icon24Grid: {
        component: allIcons.Icon24Grid,
      },
      Icon24Heart: {
        component: allIcons.Icon24Heart,
      },
      Icon24HeartSolid: {
        component: allIcons.Icon24HeartSolid,
      },
      Icon24Home: {
        component: allIcons.Icon24Home,
      },
      Icon24Instagram: {
        component: allIcons.Icon24Instagram,
      },
      Icon24Label: {
        component: allIcons.Icon24Label,
      },
      Icon24List: {
        component: allIcons.Icon24List,
      },
      Icon24Login: {
        component: allIcons.Icon24Login,
      },
      Icon24Logout: {
        component: allIcons.Icon24Logout,
      },
      Icon24Menu: {
        component: allIcons.Icon24Menu,
      },
      Icon24Search: {
        component: allIcons.Icon24Search,
      },
      Icon24Shopping: {
        component: allIcons.Icon24Shopping,
      },
      Icon24SmallGrid: {
        component: allIcons.Icon24SmallGrid,
      },
      Icon24Account: {
        component: allIcons.Icon24Account,
      },
      Icon40AittaDelivery: {
        component: allIcons.Icon40AittaDelivery,
      },
      Icon40CreditCardPay: {
        component: allIcons.Icon40CreditCardPay,
      },
      Icon40KlarnaPay: {
        component: allIcons.Icon40KlarnaPay,
      },
      Icon40Kuivuri: {
        component: allIcons.Icon40Kuivuri,
      },
      Icon40OnlineBankPay: {
        component: allIcons.Icon40OnlineBankPay,
      },
      Icon40PackageDelivery: {
        component: allIcons.Icon40PackageDelivery,
      },
      Icon40PickupPay: {
        component: allIcons.Icon40PickupPay,
      },
      Icon40TruckDelivery: {
        component: allIcons.Icon40TruckDelivery,
      },
    },
  },
});
