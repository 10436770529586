<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H5C5.26522 0 5.51957 0.105357 5.70711 0.292893C5.89464 0.48043 6 0.734784 6 1V5C6 5.55228 5.55228 6 5 6H1C0.447715 6 0 5.55228 0 5V1ZM2 2V4H4V2H2Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 1C9 0.447715 9.44772 0 10 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V1ZM11 2V4H13V2H11Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 19C0 18.4477 0.447715 18 1 18H5C5.26522 18 5.51957 18.1054 5.70711 18.2929C5.89464 18.4804 6 18.7348 6 19V23C6 23.5523 5.55228 24 5 24H1C0.447715 24 0 23.5523 0 23V19ZM2 20V22H4V20H2Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 10C0 9.44772 0.447715 9 1 9H5C5.26522 9 5.51957 9.10536 5.70711 9.29289C5.89464 9.48043 6 9.73478 6 10V14C6 14.5523 5.55228 15 5 15H1C0.447715 15 0 14.5523 0 14V10ZM2 11V13H4V11H2Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 10C9 9.44772 9.44772 9 10 9H14C14.2652 9 14.5196 9.10536 14.7071 9.29289C14.8946 9.48043 15 9.73478 15 10V14C15 14.5523 14.5523 15 14 15H10C9.44772 15 9 14.5523 9 14V10ZM11 11V13H13V11H11Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 19C9 18.4477 9.44772 18 10 18H14C14.2652 18 14.5196 18.1054 14.7071 18.2929C14.8946 18.4804 15 18.7348 15 19V23C15 23.5523 14.5523 24 14 24H10C9.44772 24 9 23.5523 9 23V19ZM11 20V22H13V20H11Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 10C18 9.44772 18.4477 9 19 9H23C23.2652 9 23.5196 9.10536 23.7071 9.29289C23.8946 9.48043 24 9.73478 24 10V14C24 14.5523 23.5523 15 23 15H19C18.4477 15 18 14.5523 18 14V10ZM20 11V13H22V11H20Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 19C18 18.4477 18.4477 18 19 18H23C23.2652 18 23.5196 18.1054 23.7071 18.2929C23.8946 18.4804 24 18.7348 24 19V23C24 23.5523 23.5523 24 23 24H19C18.4477 24 18 23.5523 18 23V19ZM20 20V22H22V20H20Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 1C18 0.447715 18.4477 0 19 0H23C23.2652 0 23.5196 0.105357 23.7071 0.292893C23.8946 0.48043 24 0.734784 24 1V5C24 5.55228 23.5523 6 23 6H19C18.4477 6 18 5.55228 18 5V1ZM20 2V4H22V2H20Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon24SmallGrid",
};
</script>

<style></style>
