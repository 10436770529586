<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.99862 12.9485C4.75095 13.1686 4.37171 13.1463 4.15155 12.8986L0.151555 8.39862C-0.0505186 8.17129 -0.0505185 7.82872 0.151555 7.60139L4.15155 3.10139C4.37171 2.85372 4.75095 2.83141 4.99862 3.05156C5.24629 3.27171 5.2686 3.65096 5.04845 3.89863L1.93611 7.40001L15.4 7.40001C15.7314 7.40001 16 7.66864 16 8.00001C16 8.33138 15.7314 8.60001 15.4 8.60001L1.93611 8.60001L5.04844 12.1014C5.2686 12.3491 5.24629 12.7283 4.99862 12.9485Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Icon16ArrowLeft",
};
</script>

<style></style>
