<template>
  <div v-if="data !== undefined">
    <select-dialog-style :data="data"></select-dialog-style>
  </div>
</template>

<script>
import SelectDialogStyle from "./bb-dialog/SelectDialogStyle.vue";
export default {
  components: { SelectDialogStyle },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: this.$store.state.cms.data.bbDialog[this.id],
    };
  },
};
</script>

<style>
</style>