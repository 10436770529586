<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 1.2C6.67452 1.2 5.6 2.27452 5.6 3.6V6.4C5.6 6.73137 5.33137 7 5 7C4.66863 7 4.4 6.73137 4.4 6.4V3.6C4.4 1.61177 6.01178 0 8 0C9.98823 0 11.6 1.61178 11.6 3.6L11.6 6.4C11.6 6.73137 11.3314 7 11 7C10.6686 7 10.4 6.73137 10.4 6.4L10.4 3.6C10.4 2.27452 9.32549 1.2 8 1.2Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5452 5.2H2.45485C2.02965 5.2 1.67843 5.53137 1.65455 5.95507L1.20367 13.9551C1.17782 14.4138 1.54359 14.8 2.00397 14.8H13.996C14.4564 14.8 14.8222 14.4138 14.7963 13.9551L14.3454 5.95507C14.3216 5.53137 13.9704 5.2 13.5452 5.2ZM2.45485 4C1.39185 4 0.513807 4.82842 0.454107 5.88767L0.00322793 13.8877C-0.0614114 15.0346 0.85301 16 2.00397 16H13.996C15.147 16 16.0614 15.0346 15.9968 13.8877L15.5459 5.88768C15.4862 4.82842 14.6082 4 13.5452 4H2.45485Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Icon16Shopping",
};
</script>

<style></style>
