<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-40"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.41932 7.4C2.33283 7.4 1.4 8.30892 1.4 9.49503V30.505C1.4 31.6911 2.33283 32.6 3.41933 32.6H36.5294C37.6159 32.6 38.5488 31.6911 38.5488 30.505V9.49504C38.5488 8.30892 37.6159 7.4 36.5294 7.4H3.41932ZM0 9.49503C0 7.56478 1.53088 6 3.41932 6H36.5294C38.4179 6 39.9488 7.56478 39.9488 9.49504V30.505C39.9488 32.4352 38.4179 34 36.5294 34H3.41933C1.53089 34 0 32.4352 0 30.505V9.49503Z"
      
    />
    <path
      d="M10.2492 16.5H8.65889C8.65889 17.8697 8.05961 19.1268 7.01478 19.9493L6.38473 20.4451L8.82594 23.9432H10.8331L8.58685 20.7244C9.6516 19.6103 10.2492 18.1113 10.2492 16.5Z"
      
    />
    <path
      d="M12.8422 23.9409H11.3071V16.5015H12.8422V23.9409Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4806 18.7948V19.124C17.0663 18.827 16.5664 18.6525 16.0271 18.6525C14.6 18.6525 13.4431 19.8683 13.4431 21.368C13.4431 22.8677 14.6 24.0834 16.0271 24.0834C16.5664 24.0834 17.0663 23.9089 17.4806 23.612V23.9409H18.9468V18.7948H17.4806ZM17.4758 21.368C17.4758 22.0992 16.881 22.692 16.1472 22.692C15.4135 22.692 14.8187 22.0992 14.8187 21.368C14.8187 20.6367 15.4135 20.044 16.1472 20.044C16.881 20.044 17.4758 20.6367 17.4758 21.368Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.949 19.124V18.7948H34.4152V23.9409H32.949V23.612C32.5348 23.9089 32.0348 24.0834 31.4955 24.0834C30.0683 24.0834 28.9114 22.8677 28.9114 21.368C28.9114 19.8683 30.0683 18.6525 31.4955 18.6525C32.0348 18.6525 32.5348 18.827 32.949 19.124ZM31.6152 22.692C32.349 22.692 32.9437 22.0992 32.9437 21.368C32.9437 20.6367 32.349 20.044 31.6152 20.044C30.8814 20.044 30.2866 20.6367 30.2866 21.368C30.2866 22.0992 30.8814 22.692 31.6152 22.692Z"
      
    />
    <path
      d="M35.0515 23.0824C35.0515 22.5484 35.4634 22.1155 35.9715 22.1155C36.4797 22.1155 36.8917 22.5484 36.8917 23.0824C36.8917 23.6163 36.4797 24.0492 35.9715 24.0492C35.4634 24.0492 35.0515 23.6163 35.0515 23.0824Z"
      
    />
    <path
      d="M26.3056 18.6562C25.7199 18.6562 25.1656 18.8472 24.795 19.3744V18.7949H23.3351V23.9407H24.813V21.2364C24.813 20.4539 25.3123 20.0706 25.9136 20.0706C26.558 20.0706 26.9285 20.4752 26.9285 21.2258V23.9407H28.3929V20.6682C28.3929 19.4706 27.4868 18.6562 26.3056 18.6562Z"
      
    />
    <path
      d="M21.2146 18.7948V19.4651C21.5086 19.063 22.0562 18.7951 22.6516 18.7951V20.2926L22.6435 20.2922L22.6385 20.292L22.6342 20.2919C22.0541 20.2919 21.218 20.7276 21.218 21.5382V23.9409H19.7134V18.7948H21.2146Z"
      
    />
    <path
      d="M6.19096 23.9431H4.56543V16.5002H6.19096V23.9431Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon40KlarnaPay",
};
</script>

<style></style>
