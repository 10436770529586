<template>
  <div class="d-flex align-center stepper-input">
    <v-btn @click="removeItem" text icon class="stepper-input__minus-btn">
      <v-icon> mdi-minus </v-icon>
    </v-btn>

    <v-text-field
      v-if="this.item"
      outlined
      hide-details
      dense
      :value="itemInCart(this.item.uuid)"
      class="flex-grow-0 flex-shrink-0 stepper-input__text-field"
    ></v-text-field>

    <v-btn @click="addItem" text icon class="stepper-input__plus-btn">
      <v-icon> mdi-plus </v-icon>
    </v-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    count: 1,
  }),
  methods: {
    addItem: function () {
      if (this.itemInCart(this.item.uuid) >= this.item.in_stock) {
        this.$store.commit("setSnack", {
          text: this.item.name,
          message: "tuotetta ei riittävästi varastossa",
          color: "error",
        });
      } else {
        this.$store.commit("addItem", {
          name: this.item.name,
          sku: this.item.sku,
          price: this.item.price,
          uuid: this.item.uuid,
          count: this.count,
          in_stock: this.in_stock,
        });
      }
    },
    removeItem: function () {
      this.$store.commit("removeItem", {
        name: this.item.name,
        sku: this.item.sku,
        price: this.item.price,
        uuid: this.item.uuid,
        count: this.count,
        in_stock: this.in_stock,
      });
    },
  },
  computed: {
    ...mapGetters(["itemInCart"]),
  },
};
</script>
<style>
.stepper-input .v-btn {
  height: 32px !important;
  width: 32px !important;
}

.stepper-input .v-text-field {
  height: 32px !important;
  width: 48px;
  font-size: 0.874rem;
}

.stepper-input .v-input__slot {
  min-height: 32px !important;
}
</style>
