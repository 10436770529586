<template>
  <div class="home flex-1 overflow-hidden" style="width: 100%">
    <component
      :is="{
        name: 'dynamic-content',
        template: this.contentTemplate,
      }"
    />
  </div>
</template>
<script>
// import BBShopApi from "./bb-shop-api";
import template from "../data/mainview.json";

export default {
  props: {
    pageId: String,
    query: Object,
    params: Array,
  },
  data: () => ({
    contentTemplate: template.template.content,
  }),
  // to axios response when proper cms
  created: function () {
    this.$store.commit("updateCMSData", template.template.data);
    // if (template.template.data.carousel) {
    //   if (this.$store.state.cms.carouselData.length < 1) {
    //     this.$store.commit(
    //       "updateCarouselData",
    //       template.template.data.carousel
    //     );
    //   }
    // }
    // if (template.template.data.microCard) {
    //   if (this.$store.state.cms.microCardData.length < 1) {
    //     this.$store.commit(
    //       "updateMicroCardData",
    //       template.template.data.microCard
    //     );
    //   }
    // }
    // if (template.template.data.contentBanner) {
    //   if (this.$store.state.cms.contentBannerData.length < 1) {
    //     this.$store.commit(
    //       "updateContentBannerData",
    //       template.template.data.contentBanner
    //     );
    //   }
    // }
    // if (template.template.data.itemRow) {
    //   if (this.$store.state.cms.itemRowData.length < 1) {
    //     this.$store.commit(
    //       "updateContentItemRowData",
    //       template.template.data.itemRow
    //     );
    //   }
    // }
  },
};
</script>

<style>
/* TODO need better solution for tihis */
.home > div > *:not(footer) {
  max-width: 1480px;
  margin: 0 auto;
}
</style>
