<template>
  <div v-if="data !== undefined">
    <select-card-style :data="data"></select-card-style>
  </div>
</template>

<script>
import SelectCardStyle from "./bb-card/SelectCardStyle.vue";
export default {
  components: { SelectCardStyle },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: this.$store.state.cms.data.bbCard[this.id],
    };
  },
};
</script>

<style>
</style>