<template>
  <div class="fav-menu-item d-flex flex-column">
    <div
      v-for="(item, i) in this.$store.state.favorite.favoriteItems"
      :key="i"
      class="d-flex"
    >
      <router-link
        :to="{
          name: 'ProductPage',
          params: {
            sku: item.details.sku.value,
            name: generateSlug(item.name),
          },
        }"
      >
        <v-img
          v-if="item.assets"
          width="120"
          height="120"
          aspect-ratio="1"
          class="card-menu-item__img flex-grow-0"
          :src="baseurl + item.assets[0] + '/small.png'"
        ></v-img>
        <v-img
          v-else
          width="120"
          height="120"
          aspect-ratio="1"
          class="card-menu-item__img flex-grow-0"
          src="@/assets/images/no-img.jpg"
        >
        </v-img>
      </router-link>
      <div class="d-fex flex-column flex-grow-0 pa-4" style="width: 100%">
        <div class="d-flex align-center" style="height: 40px">
          <router-link
            :to="{
              name: 'ProductPage',
              params: {
                sku: item.details.sku.value,
                name: generateSlug(item.name),
              },
            }"
          >
            <h4 class="card-menu-item__product-name text-truncate">
              {{ item.name }}
            </h4>
          </router-link>
        </div>
        <div
          style="height: 30px"
          class="body-2 cart-menu-item__text flex-grow-1 pr-2"
        >
          <!-- Koko lisätietojs yms. -->
        </div>
        <div class="d-flex align-center" style="height: 40px">
          <v-btn
            @click="removeFromFavorite(item.uuid)"
            outlined
            rounded
            small
            class="cart-menu-item__remove-btn mr-auto"
          >
            <v-icon left> mdi-close </v-icon>
            Poista suosikeista
          </v-btn>
          <!--<v-btn
          class="px-4 absolute product-card__add-cart-btn"
          rounded
          outlined
          small
          @click="addToCart()"
        >
          <v-icon left size="16"> ${{ "Icon16Shopping" }} </v-icon>
          <span class="d-none d-sm-inline">Lisää</span>
        </v-btn>-->
          <!-- <v-card-actions
            v-if="itemInCart(item.uuid) === false"
            class="product-card__actions pa-4"
          >
            <v-btn
              class="px-4 product-card__add-cart-btn"
              rounded
              outlined
              small
              @click="addToCart(item)"
            >
              <v-icon left size="16"> ${{ "Icon16Shopping" }} </v-icon>
              <span class="d-none d-sm-inline">Lisää</span>
            </v-btn>
          </v-card-actions>

          <v-card-actions v-else class="product-card__actions pa-4">
            <v-badge
              color="error"
              :content="itemInCart(item.uuid)"
              overlap
              offset-x="14"
              offset-y="14"
            >
              <v-btn
                class="px-4 product-card__add-cart-btn"
                rounded
                outlined
                small
                @click="addToCart(item)"
              >
                <v-icon left size="16"> ${{ "Icon16Shopping" }} </v-icon>
                <span class="d-none d-sm-inline">Lisää</span>
              </v-btn>
            </v-badge>
          </v-card-actions> -->
        </div>
        <!-- <div class="d-flex align-center" style="height:40px">
          <StepperInput :item="item"/>
          <div class="d-flex flex-wrap align-baseline ml-auto">
            <div class="cart-menu-item__currency mr-2" style="line-height:1">
              yht.
            </div>
            <div
              class="cart-menu-item__cost-value font-weight-bold"
              style="line-height:1"
            >
              {{ item.price }}€
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <v-divider></v-divider>
  </div>
</template>

<style scoped>
.card-menu-item__product-name {
  max-width: 260px;
}
.cart-menu-item__text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 20px;
  height: 40px;
  max-width: 180px;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      baseurl: process.env.VUE_APP_BBSHOP_IMAGES_ENDPOINT,
    };
  },
  methods: {
    generateSlug: function(name) {
      let lowerCase = name.toLowerCase();
      let replaceSpaces = lowerCase.replaceAll(" ", "-");
      let replaceUmlaut = replaceSpaces
        .replaceAll("ä", "a")
        .replaceAll("ö", "ö")
        .replaceAll("å", "a")
        .replaceAll("ü", "u");
      let replaceSymbols = replaceUmlaut.replaceAll(/[^a-z0-9\-]/g, "-"); //eslint-disable-line
      let replaceExtraDashes = replaceSymbols.replaceAll(/-+/g, "-");
      return replaceExtraDashes;
    },
    removeFromFavorite: function(uuid) {
      this.$store.commit("removeFavorite", { uuid: uuid });
    },
    addToCart: function(item) {
      console.log("4832948329483298")
      if (item.variants) {
        // this.$store.commit("dialogVariant", this.product)
        this.dialog = true;
        // this.$store.commit("toggleDialog")
      } else {
        this.$store.commit("addToCart", {
          name: item.name,
          sku: item.details.sku.value,
          price: item.details.price.value,
          uuid: item.uuid,
          assets: item.assets,
          count: 1,
          details: item.details,
        });
        this.$store.commit("setSnack", {
          text: item.name,
          message: "lisätty ostoskoriin",
          color: "success",
        });
      }
    },
  },
  computed: {
    ...mapGetters(["itemInCart", "itemInFav"]),
  },
};
</script>
