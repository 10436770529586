<template>
  <div class="d-flex flex-column justify-center align-center some mb-12 py-12">
    <div class="d-flex flex-column text-center" style="max-width: 410px">
      <h1 v-if="data.title" class="pb-6">
        {{ lang(data.title) }}
      </h1>
      <p v-if="data.text">
        {{ lang(data.text) }}
        <template v-for="(item, i) in data.urls">
          <a :href="item.url" :key="i"> {{ lang(item.name) }} </a>,
        </template>
      </p>
    </div>
    <v-row class="mb-4">
      <v-col>
        <template v-for="(icon, i) in data.urls">
          <v-btn class="mx-4" icon x-large :href="icon.url" :key="i">
            <v-icon x-large> {{ `$${icon.icon}`}}</v-icon>
          </v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>