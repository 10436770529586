<template>
  <v-sheet class="recent-items">
    <h4 class="mt-6 mb-2 ml-4">Viimeksi selatut tuotteet</h4>
    <v-slide-group show-arrows>
      <v-slide-item
        v-for="(item, i) in this.$store.state.recent.recentItems"
        :key="i"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"
              ><ImageCard :product="item.product"
            /></span>
          </template>
          <span>{{ item.product.name }}</span>
        </v-tooltip>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
import ImageCard from "@/components/ImageCard";

export default {
  name: "App",
  components: { ImageCard },
  data: () => ({}),
};
</script>