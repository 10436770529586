<template>
  <div class="pb-4">
    <v-row v-if="items">
      <v-col v-for="(item, i) in items" :key="i">
        <router-link
          v-if="item.name"
          class="d-block py-2 nav-btn"
          :to="{
            name: 'product-list',
            params: { category: categoryUUID(item.name).seoName },
          }"
        >
          <v-btn text tile class="nav-btn">
            {{ item.name }}
          </v-btn>
        </router-link>
        <v-row no-gutters>
          <template v-for="(child, x) in item.children">
            <v-col cols="12" :key="x">
              <router-link
                v-if="child.name"
                :key="x"
                :to="{
                  name: 'product-list',
                  params: { category: categoryUUID(child.name).seoName },
                }"
              >
                <v-btn text tile class="subMenu-text py-2">
                  {{ child.name }}
                </v-btn>
              </router-link>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      items: this.$store.state.cms.categoryDrawerData,
    };
  },
  computed: {
    ...mapGetters(["categoryUUID"]),
  },
};
</script>

<style>
.subMenu-text {
  font-weight: normal;
  font-size: 0.875rem;
  font-family: Roboto;
}
.nav-btn {
  font-family: Roboto;
  text-transform: uppercase;
}
</style>