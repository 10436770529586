<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="400"
    offset-y
    origin="top right"
    open-on-hover
    tile
    class="cart-menu"
    :disabled="this.disableMenu()"
  >
    <template
      v-if="cartValue > 0"
      v-slot:activator="{ on, attrs, items = cartValue }"
    >
      <div>
        <v-badge
          class="mx-2 bb-navbar__cart-btn"
          color="error"
          :content="items"
          bottom
          overlap
          offset-x="20px"
          offset-y="20px"
        >
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon> ${{ "Icon24Shopping" }} </v-icon>
          </v-btn>
        </v-badge>
      </div>
    </template>
    <template v-else v-slot:activator="{ on, attrs }">
      <div>
        <v-btn class="mx-2" icon v-bind="attrs" v-on="on">
          <v-icon> ${{ "Icon24Shopping" }} </v-icon>
        </v-btn>
      </div>
    </template>

    <v-card
      v-if="this.$store.state.cart.shoppingCart.length > 0"
      tile
      outlined
      max-height="calc(100vh - 140px)"
      width="100%"
      div
      class="d-flex flex-column overflow-hidden"
    >
      <div class="overflow-y-auto overflow-x-hidden flex-grow-1">
        <CartMenuItem />
      </div>
      <v-divider class="black"></v-divider>
      <div class="d-flex flex-column pa-5 flex-shrink-0">
        <div class="d-flex align-end">
          <v-btn
            @click="emptyShoppingCart()"
            text
            :ripple="false"
            small
            class="cart-menu-item__remove-btn align-end"
          >
            <v-icon left> mdi-close </v-icon>
            Poista Kaikki
          </v-btn>
          <div class="body-2 mr-2 ml-auto">yht</div>
          <h2 style="line-height: 1">{{ this.cartTotal }} €</h2>
        </div>
        <router-link
          :to="{ name: 'check-out', params: { step: 'ostoskori' } }"
          class="mt-5"
        >
          <v-btn
            rounded
            block
            x-large
            elevation="0"
            class="primary"
            @click="closeMenu()"
          >
            <v-icon left> mdi-shopping-outline </v-icon>
            Kassalle
          </v-btn>
        </router-link>
      </div>
    </v-card>
    <v-card
      v-else
      tile
      outlined
      max-height="calc(100vh - 140px)"
      width="100%"
      div
      class="d-flex flex-column overflow-hidden"
    >
      <div class="d-flex flex-column pa-5 flex-shrink-0">
        Ostoskori on tyhjä
      </div>
    </v-card>
  </v-menu>
</template>

<style>
.bb-navbar__cart-btn .v-badge__badge {
  pointer-events: none;
}

.v-btn::before {
  background-color: transparent;
}
</style>

<script>
import CartMenuItem from "@/components/cart-menu/CartMenuItem";
import { mapGetters } from "vuex";

export default {
  components: { CartMenuItem },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    cartValue: function () {
      // return this.$store.state.shoppingCart.length
      // console.log(this.$store.state.cart.shoppingCart)
      return this.cartTotalItems;
    },
    ...mapGetters(["cartTotal", "cartTotalItems"]),
  },
  methods: {
    emptyShoppingCart: function () {
      this.$store.commit("emptyShoppingCart");
    },
    disableMenu: function () {
      if (this.$route.name === "check-out") {
        return true;
      } else return false;
    },
    closeMenu() {
      this.menu = false;
    },
  },
};
</script>
