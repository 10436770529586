<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      d="M7.00005 2.70005C4.62522 2.70005 2.70005 4.62522 2.70005 7.00005V17C2.70005 19.3749 4.62522 21.3 7.00005 21.3H17C19.3749 21.3 21.3 19.3749 21.3 17V7.00005C21.3 4.62522 19.3749 2.70005 17 2.70005H7.00005ZM1.30005 7.00005C1.30005 3.85203 3.85203 1.30005 7.00005 1.30005H17C20.1481 1.30005 22.7001 3.85203 22.7001 7.00005V17C22.7001 20.1481 20.1481 22.7001 17 22.7001H7.00005C3.85203 22.7001 1.30005 20.1481 1.30005 17V7.00005Z"
    />
    <path
      d="M12.5273 8.69241C11.8407 8.5906 11.1395 8.70788 10.5234 9.02756C9.90728 9.34725 9.40766 9.85307 9.09559 10.4731C8.78353 11.0931 8.6749 11.7957 8.78518 12.481C8.89545 13.1663 9.219 13.7994 9.70981 14.2902C10.2006 14.781 10.8337 15.1045 11.519 15.2148C12.2043 15.3251 12.9069 15.2165 13.5269 14.9044C14.1469 14.5923 14.6527 14.0927 14.9724 13.4766C15.2921 12.8605 15.4094 12.1593 15.3076 11.4727C15.2037 10.7723 14.8774 10.1239 14.3767 9.62327C13.8761 9.12262 13.2277 8.79627 12.5273 8.69241ZM9.87859 7.78489C10.7561 7.32958 11.7548 7.16255 12.7327 7.30756C13.7302 7.45547 14.6536 7.92028 15.3667 8.63332C16.0797 9.34636 16.5445 10.2698 16.6924 11.2673C16.8374 12.2452 16.6704 13.2439 16.2151 14.1214C15.7598 14.9989 15.0394 15.7105 14.1563 16.1549C13.2733 16.5994 12.2726 16.7541 11.2966 16.597C10.3206 16.44 9.4189 15.9792 8.71986 15.2801C8.02083 14.5811 7.56001 13.6794 7.40296 12.7034C7.2459 11.7274 7.40061 10.7267 7.84506 9.84365C8.28952 8.96061 9.0011 8.2402 9.87859 7.78489Z"
    />
    <path
      d="M16.5 6.5C16.5 5.94772 16.9477 5.5 17.5 5.5H17.51C18.0623 5.5 18.51 5.94772 18.51 6.5C18.51 7.05228 18.0623 7.5 17.51 7.5H17.5C16.9477 7.5 16.5 7.05228 16.5 6.5Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Instagram",
};
</script>

<style></style>
