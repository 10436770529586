<template>
  <div class="d-flex flex-column pa-8">
    <h3 v-if="data.title !== ''" class="my-3">{{ lang(data.title) }}</h3>
    <div
      v-for="(item, i) in data.items"
      class="d-flex flex-column flex-sm-row align-sm-center my-5"
      :key="i"
    >
      <v-img
        v-if="item.image"
        :src="require('@/assets/images/' + item.image)"
        width="140"
        height="170"
        contain
        class="flex-grow-0 mr-4"
      ></v-img>
      <div v-if="item.texts" class="d-flex flex-column my-3">
        <h3>{{ lang(item.title) }}</h3>
        <template v-for="(item, i) in item.texts">
          <p v-if="i === 0" class="body-1 my-3" :key="item + i">
            {{ lang(item.text) }}
          </p>
          <p v-else :key="item + i" class="body-2 my-0 text-no-wrap">
            {{ lang(item.text) }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>