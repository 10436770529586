var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.showSearch)?_c('form',{staticClass:"main-search-bar",attrs:{"autocomplete":"off"}},[_c('input',{staticClass:"d-none",attrs:{"autocomplete":"false"}}),_c('v-autocomplete',{ref:"refSearch",attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"clearable":"","dialog":_vm.dialog,"clear-icon":"mdi-close-circle","rounded":"","no-filter":"","hide-details":"","hide-no-data":"","item-text":"name","item-value":"symbol","label":"Etsi tuotteita..","solo":"","flat":"","multiple":"","outlined":"","prepend-inner-icon":"mdi-magnify","append-icon":"","menu-props":{
      closeOnClick: true,
      closeOnContentClick: true,
    }},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.goToSearch($event)},"change":_vm.itemChange},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Aikasemmat haut "),_vm._l((_vm.$store.state.searchHistory),function(item,i){return _c('div',{key:i},[_c('router-link',{attrs:{"to":{
                name: 'ProductSearch',
                params: { search: item, value: item },
              }}},[_vm._v(_vm._s(item))])],1)})],2)],1)]},proxy:true},{key:"append-item",fn:function(){return [_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"d-flex justify-center show-all"},[_c('v-btn',{staticClass:"primary--text",attrs:{"text":""},on:{"click":_vm.goToSearch}},[_vm._v(" Näytä kaikki "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-arrow-right ")])],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
              var item = ref.item;
return [(item.thumbnail)?_c('v-img',{staticClass:"product-card__img flex-grow-0 mr-4",style:({ cursor: 'pointer' }),attrs:{"width":"60","aspect-ratio":"1","src":_vm.baseurl + item.thumbnail + '/small.png'},on:{"click":function($event){return _vm.addToSearchHistory(item.details.sku.value, item.name)}}}):(item.assets)?_c('v-img',{staticClass:"product-card__img flex-grow-0 mr-4",style:({ cursor: 'pointer' }),attrs:{"width":"60","aspect-ratio":"1","src":_vm.baseurl + item.assets[0] + '/small.png'},on:{"click":function($event){return _vm.addToSearchHistory(item.details.sku.value, item.name)}}}):_c('v-img',{staticClass:"product-card__img flex-grow-0 mr-4",style:({ cursor: 'pointer' }),attrs:{"contain":"","width":"60","aspect-ratio":"1","src":require("@/assets/images/no-img.jpg")},on:{"click":function($event){return _vm.addToSearchHistory(item.details.sku.value, item.name)}}}),_c('v-list-item-content',{style:({ cursor: 'pointer' }),on:{"click":function($event){return _vm.addToSearchHistory(item.details.sku.value, item.name)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.symbol)}})],1),_c('v-btn',{staticClass:"flex-shrink-0",attrs:{"outlined":"","rounded":"","small":"","disabled":_vm.checkStock(item)},on:{"click":function($event){return _vm.addToCart(item)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" $"+_vm._s("Icon16Shopping")+" ")]),_vm._v(" Lisää ")],1),_c('br')]}}],null,false,1980276821),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }