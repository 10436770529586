<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.39229 0L8.78236 2.13037L6.71402 4.65124L5.84643 3.9249L6.67407 2.91618C4.07454 3.53472 2.12683 5.93248 2.12683 8.81145C2.12683 12.1626 4.76582 14.8618 8 14.8618C11.2342 14.8618 13.8732 12.1626 13.8732 8.81145C13.8732 7.25848 13.306 5.84459 12.3745 4.77427L13.2208 4.02274C14.3277 5.29461 15 6.97371 15 8.81145C15 12.7719 11.8755 16 8 16C4.12453 16 1 12.7719 1 8.81145C1 5.3203 3.42789 2.39824 6.65786 1.75474L5.64673 0.853479L6.39229 0Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon16Reset",
};
</script>

<style></style>
