const requireModule = require.context(".", false, /\.vue$/);

const icons = {};

requireModule.keys().forEach(fileName => {

  const moduleName = fileName.replace(/(\.\/|\.vue)/g, ""); //

  icons[moduleName] = requireModule(fileName).default;
});

export default icons;