<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7 11.7101V4H11.3V12.2899L15.505 16.495L16.495 15.505L12.7 11.7101Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 21.6C17.3019 21.6 21.6 17.3019 21.6 12C21.6 6.69807 17.3019 2.4 12 2.4C6.69807 2.4 2.4 6.69807 2.4 12C2.4 17.3019 6.69807 21.6 12 21.6ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Clock",
};
</script>

<style></style>
