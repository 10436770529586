export default {
  state: {
    favoriteItems: [],
  },
  getters: {
    itemInFav: (state) => (item) => {
      let check = state.favoriteItems.filter((i) => i.uuid === item);
      if (check.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  mutations: {
    addFavorite(state, product) {
      if (this.state.login.isLoggedIn) {
        state.favoriteItems.push(product);
        this.state.snacktext = {
          text: product.name,
          message: " lisätty suosikkeihin?",
          color: "success",
        };
      }
    },

    removeFavorite(state, product) {
      let tempList = state.favoriteItems.filter(
        (item) => item.uuid !== product.uuid
      );
      state.favoriteItems = tempList;
      // console.log(state.favoriteItems);
      this.state.snacktext = {
        text: product.name,
        message: " poistettu suosikeista",
        color: "warning",
      };
    },

    noLoginFavorite() {
      // console.log("login first");
      this.state.snacktext = {
        text: "Kirjaudu sisään lisätäksesi suosikkeja",
        message: "",
        color: "warning",
      };
    },
  },
  actions: {},
  module: {},
};
