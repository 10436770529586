<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-40"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7 24V24.5H13C13.7 24.5 13.7 24.4998 13.7 24.5L13.7 24.5024L13.7 24.5059L13.6999 24.5161L13.6994 24.5493C13.6987 24.577 13.6976 24.6157 13.6954 24.6645C13.691 24.7622 13.6823 24.9006 13.6654 25.0731C13.6316 25.4176 13.5646 25.9 13.4313 26.4651C13.1656 27.5914 12.6307 29.0704 11.5485 30.435C10.0494 32.3251 8.16282 33.2645 6.66744 33.7308C5.91906 33.9641 5.26249 34.0811 4.78986 34.14C4.5532 34.1694 4.36162 34.1844 4.22644 34.1921C4.15882 34.1959 4.10522 34.1979 4.06704 34.1989C4.04794 34.1994 4.03269 34.1997 4.02146 34.1998L4.00766 34.2L4.00306 34.2L4.00134 34.2C4.00102 34.2 4.00001 34.2 4.00001 33.5C4.00001 32.8 3.99974 32.8 3.99949 32.8L4.00295 32.8L4.02921 32.7994C4.05426 32.7987 4.09412 32.7973 4.14743 32.7943C4.2541 32.7883 4.41422 32.7759 4.61694 32.7507C5.02308 32.7001 5.59604 32.5984 6.25068 32.3942C7.56133 31.9855 9.17475 31.1749 10.4516 29.565C11.3693 28.4079 11.8344 27.1368 12.0687 26.1436C12.1854 25.6489 12.2434 25.2292 12.2721 24.9364C12.2864 24.7903 12.2934 24.6763 12.2968 24.6013C12.2985 24.5638 12.2993 24.536 12.2997 24.5188L12.3 24.5011L12.3 24.5V24H13.7Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.3 24V24.5H27C26.3 24.5 26.3 24.4998 26.3 24.5L26.3 24.5024L26.3 24.5059L26.3001 24.5161L26.3007 24.5493C26.3013 24.577 26.3024 24.6157 26.3046 24.6645C26.309 24.7622 26.3177 24.9006 26.3346 25.0731C26.3684 25.4176 26.4354 25.9 26.5687 26.4651C26.8344 27.5914 27.3693 29.0704 28.4516 30.435C29.9506 32.3251 31.8372 33.2645 33.3326 33.7308C34.081 33.9641 34.7375 34.0811 35.2102 34.14C35.4468 34.1694 35.6384 34.1844 35.7736 34.1921C35.8412 34.1959 35.8948 34.1979 35.933 34.1989C35.9521 34.1994 35.9673 34.1997 35.9785 34.1998L35.9924 34.2L35.997 34.2L35.9987 34.2C35.999 34.2 36 34.2 36 33.5C36 32.8 36.0003 32.8 36.0005 32.8L35.9971 32.8L35.9708 32.7994C35.9458 32.7987 35.9059 32.7973 35.8526 32.7943C35.7459 32.7883 35.5858 32.7759 35.3831 32.7507C34.9769 32.7001 34.404 32.5984 33.7493 32.3942C32.4387 31.9855 30.8253 31.1749 29.5485 29.565C28.6307 28.4079 28.1656 27.1368 27.9313 26.1436C27.8146 25.6489 27.7566 25.2292 27.7279 24.9364C27.7136 24.7903 27.7066 24.6763 27.7032 24.6013C27.7015 24.5638 27.7007 24.536 27.7003 24.5188L27.7 24.5011L27.7 24.5V24H26.3Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4931 30.3399C16.2265 30.06 16.2373 29.6169 16.5172 29.3502L19.5172 26.4931C19.7876 26.2356 20.2124 26.2356 20.4828 26.4931L23.4828 29.3502C23.7627 29.6169 23.7735 30.06 23.5069 30.3399C23.2403 30.6199 22.7972 30.6307 22.5173 30.364L20.7 28.6333V37C20.7 37.3866 20.3866 37.7 20 37.7C19.6134 37.7 19.3 37.3866 19.3 37V28.6333L17.4828 30.364C17.2028 30.6307 16.7597 30.6199 16.4931 30.3399Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.99548 5.8H36.0045L39.9712 17.7H27V16.3H38.0288L34.9955 7.2H5.00454L1.9712 16.3H13V17.7H0.0288086L3.99548 5.8Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 9.19923L29.34 14.3881L28.6601 15.6119L20 10.8008L11.34 15.6119L10.6601 14.3881L20 9.19923Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.7 14V24H26.3V14H27.7Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7 14V24H12.3V14H13.7Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27 23.7H13V22.3H27V23.7Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3 15.3H23.7V23H22.3V16.7H17.7V23H16.3V15.3Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.3 25.8V16.5H37.7V27.2H30V25.8H36.3ZM1.80001 17H3.20001V25.8H10V27.2H1.80001V17Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon40AittaDelivery",
};
</script>

<style></style>
