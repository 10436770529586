<template>
  <v-row no-gutters class="mx-auto my-0 content-banner">
    <v-col cols="12" md="6" lg="6" xl="6" v-if="this.data.image">
      <v-img
        :src="require('@/assets/images/' + this.data.image)"
        max-height="440"
      >
      </v-img>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="6"
      xl="6"
      class="green accent-1 d-flex align-center justify-center pa-8"
    >
      <div
        class="icon d-flex flex-column align-center justify-center text-center"
      >
        <h3 class="display-1 mb-3">{{ lang(this.data.title) }}</h3>
        <p class="mb-6">
          {{ lang(this.data.text) }}
        </p>
        <v-btn rounded outlined @click="link">
          Lue lisää
          <v-icon right> ${{ "Icon16ArrowRight" }} </v-icon>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      data: this.$store.state.cms.data.contentBanner[this.id],
    };
  },
  methods: {
    link: function() {
      this.$router.push(this.data.url);
    },
  },
};
</script>
