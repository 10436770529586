import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./assets/styles/components.css";
import VueGtag from "vue-gtag";
import store from "./store";

import Navbar from "@/components/Navbar";
import Carousel from "@/components/Carousel";
import MicroCard from "@/components/MicroCard";
import ContentBanner from "@/components/ContentBanner";
import ItemRow from "@/components/ItemRow";
import RecentItems from "@/components/RecentItems";
import CategoryDrawer from "@/components/CategoryDrawer";
import NotificationBar from "@/components/NotificationBar";
import Footer from "@/components/Footer";
import SnackBar from "@/components/SnackBar";
import ThreeD from "@/components/three-d/ThreeD";
import BBImage from "@/components/BBImage";
import BBText from "@/components/BBText";
import BBCard from "@/components/BBCard";
import BBDialog from "./components/BBDialog";

import axios from "axios";
import VueAxios from "vue-axios";

import VueLodash from "vue-lodash";
import lodash from "lodash";
import VuetifySnackbarQueue from "vuetify-snackbar-queue";
import VueMeta from "vue-meta";
import BBApi from "@/bb-shop-api.js";
import VueCookies from "vue-cookies";
import "./registerServiceWorker";

axios.defaults.withCredentials = true;

Vue.use(VueCookies);
Vue.use(VueMeta);
Vue.use(VuetifySnackbarQueue);
Vue.use(VueAxios, axios);

Vue.use(VueLodash, { lodash: lodash });

Vue.config.productionTip = false;

Vue.component("nav-bar", Navbar);
Vue.component("category-drawer", CategoryDrawer);
Vue.component("carousel", Carousel);
Vue.component("micro-card", MicroCard);
Vue.component("content-banner", ContentBanner);
Vue.component("item-row", ItemRow);
Vue.component("recent-items", RecentItems);
Vue.component("notification-bar", NotificationBar);
//cannot use "footer" name
Vue.component("foo-ter", Footer);
Vue.component("snack-bar", SnackBar);
Vue.component("three-d-store", ThreeD);
Vue.component("bb-image", BBImage);
Vue.component("bb-text", BBText);
Vue.component("bb-card", BBCard);
Vue.component("bb-dialog", BBDialog);

Vue.mixin({
  methods: {
    lang(text) {
      let a = store.getters.lang(text);
      return a;
      // return this.store.getters("lang", text);
    },
    BBShopApi(page) {
      return BBApi.page(page);
    },
    checkDiscountCategory(category) {
      let check = store.getters.checkDiscountList(category);
      return check;
    },
  },
});

Vue.use(
  VueGtag,
  {
    config: { id: "UA-59841028-1" },
    params: {
      send_page_view: true,
    },
    pageTrackerTemplate(to) {
      return {
        page_path: to.path + location.hash,
      };
    },
    pageTrackerSkipSamePath: false,
  },
  router
);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
