<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9485 11.0014C13.1686 11.249 13.1463 11.6283 12.8986 11.8484L8.39863 15.8484C8.17129 16.0505 7.82872 16.0505 7.60139 15.8484L3.10139 11.8484C2.85372 11.6283 2.83141 11.249 3.05156 11.0014C3.27171 10.7537 3.65096 10.7314 3.89863 10.9516L7.40001 14.0639L7.40001 0.599998C7.40001 0.268627 7.66864 -1.92183e-06 8.00001 -1.90735e-06C8.33138 -1.89286e-06 8.60001 0.268627 8.60001 0.599998L8.60001 14.0639L12.1014 10.9516C12.3491 10.7314 12.7283 10.7537 12.9485 11.0014Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Icon16ArrowDown",
};
</script>

<style></style>
