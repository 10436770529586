<template>
  <div class="d-flex justify-center tab-item">
    <iframe
      :id="'showcaseIframe' + (this.id + 1)"
      class="showcaseIframe"
      width="853"
      height="480"
      :src="'https://my.matterport.com/show/?m=' + this.item"
      frameborder="0"
      allowfullscreen
      allow="xr-spatial-tracking"
      sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
    ></iframe>
  </div>
</template>


  <script src='https://static.matterport.com/showcase-sdk/latest.js'></script>
<script>
import axios from "axios";

export default {
  props: {
    item: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: Number,
    },
  },
  created() {
    let matterportScript = document.createElement("script");
    // matterportScript.setAttribute(
    //   "src",
    //   "https://static.matterport.com/showcase-sdk/2.0.1-0-g64e7e88/sdk.js"
    // );

    matterportScript.setAttribute(
      "src",
      "https://static.matterport.com/showcase-sdk/latest.js"
    );
    document.head.appendChild(matterportScript);
    // console.log(matterportScript);
  },
  data() {
    return {
      items: this.$store.state.cms.D3StoreData["matterport"],
      activeTab: 0,
      timeout: null,
      selected: [],
    };
  },
  async mounted() {
    let vm = this;
    // APPLY MATTERPORT SPACE SETTINGS
    // let settings2 = {
    //   model1: "VYC55HfeVEK",
    //   model2: "uf8fvdRBE9P",
    // };
    // const matterportBaseUrl = "https://my.matterport.com/show/?m=";

    // var frame1 = document.getElementById("showcaseIframe1");
    // frame1.setAttribute(
    //   "data-src",
    //   matterportBaseUrl + settings2.model1
    // );

    // this.$set(frame1, "data-src", matterportBaseUrl + settings2.model1);

    // var frame2 = document.getElementById("showcaseIframe2");
    // frame2.setAttribute(
    //   "data-src",
    //   matterportBaseUrl + settings2.model2
    // );
    // this.$set(frame2, "data-src", matterportBaseUrl + settings2.model2);

    let showcaseIframes = document.getElementsByClassName("showcaseIframe");
    var iframeConfig = {
      pointerOverIframe: "",
      connectedIframe: "",
    };
    Array.from(showcaseIframes).forEach((iframe) => {
      iframe.addEventListener("pointerover", function () {
        iframeConfig.pointerOverIframe = this.id;
        if (iframeConfig.connectedIframe != iframeConfig.pointerOverIframe) {
          mpSdkConnector(this);
          iframeConfig.connectedIframe = iframeConfig.pointerOverIframe;
        }
      });
      iframe.addEventListener("pointerout", function () {
        iframeConfig.pointerOverIframe = "";
      });
    });

    async function mpSdkConnector(iframe) {
      const sdkKey = "0kr0f4rfhq76dm980d47ihq5b"; // TODO: replace with your sdk key
      // const iframe = document.getElementById('showcase-iframe');

      try {
        const mpSdk = await window.MP_SDK.connect(
          iframe, // Obtained earlier
          sdkKey, // Your SDK key
          "" // Unused but needs to be a valid string
        );
        // onShowcaseConnect(mpSdk);
        await showcaseHandler(mpSdk);
        // console.log("Connected?");
      } catch (e) {
        console.error(e);
        // console.log("many errors?");
      }

      // try {
      //   window.MP_SDK.connect(
      //     iframe, // Showcase iframe holding the space you want to connect to
      //     "0kr0f4rfhq76dm980d47ihq5b", // Your API key
      //     "3.5" // SDK version you are using. Use the latest version you can for your app
      //   )
      //     .then(showcaseHandler)
      //     .catch(handleError);
      // } catch (e) {
      //   console.error(e);
      // }
    }

    async function showcaseHandler(mpSdk) {
      await mpSdk.Mattertag.getData().then(function (mattertags) {
        mattertags.forEach((product) => {
          // addCartBtn(product);
          addCartBtn2(product);
        });

        function addCartBtn2(product) {
          var htmlToInject = ` \
        <style> \
          button { \
        } \
        .injectionContainer {\
          height: 100%;\
          width: 100%;\
        }\
        .cartBtn {\
          display: block;\
          background-color: #9cd36e;\
          border-radius: 10px;\
          text-align: center;\
          line-height: 30px;\
          border-width: 1px;\
          margin: 0 auto;\
        }\
          .btnText {\
            display: inline-block;\
            vertical-align: middle;\
            color: #ffffff;\
            font-size: small;\
            font-weight: bold;\
        }\
        </style> \
        <div class="injectionContainer"> \
        <button class="cartBtn" id="btn1"> \
        <span class="btnText"> \
        LISÄÄ OSTOSKORIIN</button> \
        </span> \
        </div> \
        <script> \
          var btn1 = document.getElementById("btn1"); \
          btn1.addEventListener("click", function () { \
            window.send("buttonClick", 12345); \
          }); \
        <\/script>`;

          mpSdk.Mattertag.injectHTML(product["sid"], htmlToInject, {
            size: {
              w: 250,
              h: 100,
            },
          }).then(function (messenger) {
            messenger.on("buttonClick", function (buttonId) {
              if (product !== null) {
                addToCart(skuFromProduct(product));
                // console.log("clicked button with id:", skuFromProduct(product));
              }
              product = null;
            });
          });
        }
      });
    }

    function handleError(error) {
      console.log(error);
    }

    function skuFromProduct(product) {
      var descriptionArray = product.parsedDescription[0]["text"].split("│");
      descriptionArray =
        descriptionArray[descriptionArray.length - 1].split("|");
      var sku = descriptionArray[descriptionArray.length - 1].trim();
      return sku;
    }

    function addToCart(product) {
      if (!product) {
        // console.log("product missing");
        return;
      }
      vm.addProduct(product);
    }
  },
  methods: {
    addProduct: function (product) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        let url =
          process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/product";
        let data = {
          from: 0,
          size: 10,
          query: { match: { "details.sku.value": product } },
        };
        axios.post(url, data).then((response) => {
          this.selected = response.data;
        });
      }, 500);
    },
  },
  watch: {
    selected: function () {
      console.log("selected changed");
      if (this.selected.size !== 0) {
        // console.log(this.selected.items[0]);
        this.$store.commit("addToCart", {
          name: this.selected.items[0].name,
          sku: this.selected.items[0].details.sku.value,
          price: this.selected.items[0].details.price.value,
          count: 1,
          uuid: this.selected.items[0].uuid,
          assets: this.selected.items[0].assets,
          details: this.selected.items[0].details,
        });
        this.$store.commit("setSnack", {
          text: this.selected.items[0].name,
          message: " lisätty ostoskoriin",
          color: "success",
        });
        console.log("item found");
      } else {
        console.log("not found");
      }
    },
  },
};
</script>

<style>
.tab-item {
  padding-top: 20px;
}
</style>
