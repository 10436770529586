<template>
  <div class="life-style d-flex flex-column mb-12">
    <v-img
      v-if="data.image"
      :src="require('@/assets/images/' + data.image)"
      width="100%"
      contain
    >
    </v-img>
    <div
      class="life-style-content py-16 px-8 d-flex justify-center"
    >
      <v-img
        v-if="data.logo"
        :src="require('@/assets/images/' + data.logo)"
        width="20%"
        max-width="170"
        max-height="170"
        contain
        class="mr-8 flex-grow-0"
      >
      </v-img>
      <div class="d-flex flex-column white--text" style="max-width: 580px">
        <h1 class="pb-6" v-if="data.title">
          {{ lang(data.title) }}
        </h1>
        <p v-if="data.text">
          {{ lang(data.text) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .life-style-content {
    flex-direction: column;
    align-items: center;
  }

  .life-style-content .v-image {
    margin: 0 auto 32px 0 !important;
  }

  .life-style .v-image {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>