import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Page from "../Page.vue";
import store from "../store/modules/login.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/page",
    name: "Page",
    component: Page,
  },
  {
    path: "/tuote/:sku/:name",
    name: "ProductPage",
    component: () => import("../views/ProductPage.vue"),
  },
  {
    path: "/product/search/:value",
    name: "ProductSearch",
    component: () => import("../views/ProductSearch.vue"),
  },
  {
    path: "/kategoria/:category",
    name: "product-list",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductList.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProductPage.vue"),
  },
  {
    path: "/kassa/:step",
    name: "check-out",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CheckOutStepper.vue"),
  },
  {
    path: "/kassa/:step/:order",
    name: "check-out",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CheckOutStepper.vue"),
  },
  {
    path: "/asiakastili/:tab",
    name: "user-page",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UserPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/asiakastili/:tab/:uuid",
    name: "user-order",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/user-page/OrderSummary.vue"
      ),
  },
  {
    path: "/suosikit",
    name: "favorites",
    component: () => import("../views/Favorites.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../components/login/RegisterUser.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../components/login/ResetPassword.vue"),
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../components/login/UserPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/icons",
    name: "icons",
    component: () => import("../components/test/Icons.vue"),
  },
  {
    path: "/3d-myymala",
    name: "3dstore",
    component: () => import("../views/ThreeDStore.vue"),
  },
  {
    path: "/brandit",
    name: "brands",
    component: () => import("../views/Brands.vue"),
  },
  {
    path: "/inspiroidu",
    name: "inspire",
    component: () => import("../views/Inspire.vue"),
  },
  {
    path: "/meista",
    name: "aboutus",
    component: () => import("../views/AboutUs.vue"),
  },
  {
    path: "/sisustuspalvelut",
    name: "services",
    component: () => import("../views/Services.vue"),
  },
  {
    path: "/tietosuojakaytanto",
    name: "privacy policy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/tilaus-ja-toimitusehdot",
    name: "terms of delivery",
    component: () => import("../views/TermsOfDelivery.vue"),
  },
  {
    path: "/yhteystiedot",
    name: "contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("../components/404.vue"),
  },
  {
    path: "/tuote-osasto/:name",
    redirect: { path: "/kategoria/:name" },
  },
  {
    path: "/category/arkisto/",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else if (savedPosition) {
      return {
        savedPosition,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.isLoggedIn) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
  // to and from are both route objects. must call `next`.
});
export default router;
