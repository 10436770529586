<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.6 2.7C22.6 1.98203 22.018 1.4 21.3 1.4L12.3 1.4C11.9134 1.4 11.6 1.0866 11.6 0.700001C11.6 0.313402 11.9134 1.37903e-06 12.3 1.39592e-06L21.3 1.78933e-06C22.7912 1.85451e-06 24 1.20883 24 2.7L24 21.3C24 22.7912 22.7912 24 21.3 24L12.3 24C11.9134 24 11.6 23.6866 11.6 23.3C11.6 22.9134 11.9134 22.6 12.3 22.6L21.3 22.6C22.018 22.6 22.6 22.018 22.6 21.3L22.6 2.7Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5349 6.97681C10.8239 6.71997 11.2663 6.746 11.5232 7.03495L15.5232 11.5349C15.7589 11.8002 15.7589 12.1998 15.5232 12.4651L11.5232 16.9651C11.2663 17.254 10.8239 17.28 10.5349 17.0232C10.246 16.7663 10.22 16.3239 10.4768 16.0349L13.4412 12.7H0.999999C0.6134 12.7 0.299999 12.3866 0.299999 12C0.299999 11.6134 0.6134 11.3 0.999999 11.3H13.4412L10.4768 7.96506C10.22 7.67611 10.246 7.23366 10.5349 6.97681Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Login",
};
</script>

<style></style>
