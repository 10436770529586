<template>
  <!-- <v-hover v-slot="{ hover }"> -->

  <v-card
    :width="width"
    :max-width="maxWidth"
    :min-width="minWidth"
    class="product-card ma-3"
    outlined
    :splitDecimal="splitDecimal"
    :realPrice="realPrice"
    :itemInCart="itemInCart"
    :itemInFav="itemInFav"
    :routeName="routeName"
    :baseurl="baseurl"
  >
    <router-link
      :to="{
        name: 'ProductPage',
        params: {
          sku: product.details.sku.value,
          name: generateSlug(product.name),
        },
      }"
      class="card-link"
      style="color: inherit"
    >
      <!-- removed prop from v-card :elevation="hover ? 4 : 0" -->
      <div
        class="product-card__badges d-flex flex-column px-2 py-3"
        v-if="this.newProduct || this.discount"
      >
        <v-chip
          v-if="this.newProduct"
          class="label-new font-weight-bold ma-1 rounded-0 justify-center"
          color="primary"
          tile
          label
          small
        >
          UUTUUS
        </v-chip>

        <v-chip
          v-if="this.discount"
          class="label-discount font-weight-bold ma-1 rounded-0 justify-center"
          color="error"
          label
          small
        >
          -{{ discountPercent }} %
          <!-- -10% -->
        </v-chip>
      </div>
      <v-img
        v-if="'thumbnail' in product"
        aspect-ratio="1"
        class="product-card__img"
        :src="baseurl + product.thumbnail + '/medium.png'"
      >
      </v-img>
      <v-img
        v-else-if="product.assets"
        aspect-ratio="1"
        class="product-card__img"
        :src="baseurl + product.assets[0] + '/medium.png'"
      >
      </v-img>
      <v-img
        v-else
        aspect-ratio="1"
        class="product-card__img"
        src="@/assets/images/no-img.jpg"
      >
      </v-img>
      <div class="card-content flex-1" style="width: 100%">
        <v-card-title class="product-card__title pb-0 pt-3">{{
          product.name
        }}</v-card-title>

        <v-card-text class="product-card__text py-0">
          {{ product.description }}
        </v-card-text>

        <!-- <div class="d-flex product-card__bottom"> -->
        <div class="d-flex pt-4 pr-0 pb-4 pl-4 flex-wrap product-card__cost">
          <!-- <div class="d-flex"> -->
          <h2 class="product-card__cost-value" style="line-height: 1">
            <!-- {{ splitDecimal(product.details.price.value, 0) }} -->
            {{ this.price }}
          </h2>
          <div
            class="product-card__cost-decimal font-weight-bold"
            style="line-height: 1"
          >
            <!-- .{{ splitDecimal(product.details.price.value, 1) }} -->
            .{{ this.decimal }}€
          </div>
          <!-- <div class="product-card__currency mr-3" style="line-height: 1">
                € <br />
              </div> -->
          <!-- </div> -->
          <div
            v-if="this.discount"
            class="product-card__original-price text-decoration-line-through pl-2"
            style="line-height: 1"
          >
            {{ realPrice(product.details.price.value) }}
          </div>
        </div>

        <!-- <v-spacer></v-spacer> -->
        <!-- </div> -->
      </div>
    </router-link>

    <v-card-actions
      v-if="itemInCart(product.uuid) === false"
      class="product-card__actions pa-4"
      style="position: absolute; bottom: 0; right: 0"
    >
      <v-btn
        :disabled="checkStock(product)"
        class="px-4 absolute product-card__add-cart-btn"
        rounded
        outlined
        :icon="icon"
        @click="addToCart()"
      >
        <v-icon :left="iconLeft" size="16"> ${{ "Icon16Shopping" }} </v-icon>
        <span class="d-none d-sm-inline">Lisää</span>
        <!-- <v-icon v-if="product.variants" right size="16"> mdi-arrow-up-bold-box-outline </v-icon> -->
      </v-btn>
    </v-card-actions>

    <v-card-actions
      v-else
      class="product-card__actions pa-4"
      style="position: absolute; bottom: 0; right: 0"
    >
      <v-badge
        color="error"
        :content="itemInCart(product.uuid)"
        overlap
        offset-x="14"
        offset-y="14"
      >
        <v-btn
          class="px-4 absolute product-card__add-cart-btn"
          rounded
          outlined
          :icon="icon"
          @click="addToCart()"
        >
          <v-icon :left="iconLeft" size="16"> ${{ "Icon16Shopping" }} </v-icon>
          <span class="d-none d-sm-inline">Lisää</span>
        </v-btn>
      </v-badge>
    </v-card-actions>

    <v-icon
      v-if="!this.$store.state.login.isLoggedIn"
      class="product-card__fav-btn ma-2"
      @click="noLoginFavorite"
    >
      ${{ "Icon24Heart" }}
    </v-icon>
    <v-btn
      class="product-card__fav-btn ma-2"
      text
      icon
      large
      v-else-if="itemInFav(this.product.uuid)"
      @click="removeFavorite"
    >
      <v-icon color="red">${{ "Icon24HeartSolid" }}</v-icon>
    </v-btn>
    <v-btn
      text
      icon
      large
      v-else
      @click="addFavorite"
      class="product-card__fav-btn ma-2"
    >
      <v-icon>${{ "Icon24Heart" }}</v-icon>
    </v-btn>

    <!-- <v-btn
      v-if="itemInFav(product.uuid) === true"
      class="product-card__fav-btn ma-2"
      icon
      large
      @click="removeFavorite()"
    >
      <v-icon color="red"> mdi-heart </v-icon>
    </v-btn>
    <v-btn
      v-else
      class="product-card__fav-btn ma-2"
      icon
      large
      @click="addFavorite()"
    >
      <v-icon> ${{ "Icon24Heart" }} </v-icon>
    </v-btn> -->

    <dialog-variant
      v-if="product.variants"
      :product="product"
      :dialog.sync="dialog"
      @open-dialog="dialog = true"
      @close-dialog="dialog = false"
    />
  </v-card>
  <!-- </v-hover> -->
</template>

<script>
import { mapGetters } from "vuex";
import DialogVariant from "@/components/DialogVariant";
export default {
  components: { DialogVariant },
  props: {
    product: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      fav_Icon_off: "mdi-heart-outline",
      fav_icon_on: "mdi-heart",
      active: false,
      snackbar: false,
      routeName: this.$route.name,
      baseurl: process.env.VUE_APP_BBSHOP_IMAGES_ENDPOINT,
      baseurl2: process.env.VUE_APP_BBSHOP_ENDPOINT,
      price: "0",
      decimal: "0",
      dialog: false,
      stock: 0,
      newProduct: false,
      discount: false,
      discountPercent: 0,
    };
  },
  mounted: function() {
    let check = this.checkDiscountCategory(this.product.categories);

    if (check) {
      this.discount = true;
      this.discountPercent = check.percentage * 100;
    }

    if (this.product.details.price) {
      let value = 0;
      if (this.discount) {
        value =
          this.product.details.price.value *
          (1.0 - (this.discountPercent / 100).toFixed(2));
        this.$set(this.product, "discount", {
          active: true,
          percent: this.discountPercent,
        });
      } else {
        value = this.product.details.price.value;
      }

      // let temp = this.product.details.price.value.toString();
      let temp = value.toString();
      let priceArray = temp.split(".");
      let price = priceArray[0];
      let decimal = priceArray[1];
      this.price = price;

      if (isNaN(decimal)) {
        this.decimal = "00";
      } else {
        if (decimal.length < 2) {
          this.decimal = decimal + "0";
        } else {
          this.decimal = decimal.substring(0, 2);
        }
      }

      this.axios
        .get(this.baseurl2 + "/stock/" + this.product.uuid)
        .then((res) => {
          this.stock = res.data;
        });
      let today = new Date();
      if (this.product.created) {
        let test = this.product.created.replace(/\s/g, "");
        let difference = today - new Date(test);
        if (difference / (1000 * 3600 * 24) < 14) {
          this.newProduct = true;
        } else {
          this.newProduct = false;
        }
      }

      // if (this.discount) {
      //   console.log("discount true");
      // } else {
      //   console.log("discount false");
      // }
    }
  },
  methods: {
    noLoginFavorite() {
      this.$store.commit("noLoginFavorite");
    },
    checkStock(item) {
      if (item.variants) {
        return false;
      }
      if (this.stock) {
        if (this.stock > 0) {
          return false;
        } else {
          return true;
        }
      } else return true;
      // if (item.details.in_stock) {
      //   if (item.details.in_stock.value > 0) {
      //     return false;
      //   } else {
      //     return true;
      //   }
      // } else return true;
    },
    addFavorite: function() {
      this.$store.commit("addFavorite", this.product);
    },
    removeFavorite: function() {
      this.$store.commit("removeFavorite", this.product);
      // console.log("removed");
    },
    addToCart: function() {
      if (this.product.variants) {
        this.dialog = true;
      } else {
        if (this.discount) {
          this.$store.commit("addToCart", {
            name: this.product.name,
            sku: this.product.details.sku.value,
            price: this.product.details.price.value,
            uuid: this.product.uuid,
            assets: this.product.assets,
            in_stock: this.stock,
            details: this.product.details,
            count: 1,
            thumbnail: this.product.thumbnail,
            discount: {
              active: this.discount,
              percent: this.discountPercent,
            },
          });
        } else {
          this.$store.commit("addToCart", {
            name: this.product.name,
            sku: this.product.details.sku.value,
            price: this.product.details.price.value,
            uuid: this.product.uuid,
            assets: this.product.assets,
            in_stock: this.stock,
            details: this.product.details,
            count: 1,
            thumbnail: this.product.thumbnail,
          });
        }
      }
    },
    generateSlug: function(name) {
      let lowerCase = name.toLowerCase();
      let replaceSpaces = lowerCase.replaceAll(" ", "-");
      let replaceUmlaut = replaceSpaces
        .replaceAll("ä", "a")
        .replaceAll("ö", "ö")
        .replaceAll("å", "a")
        .replaceAll("ü", "u");
      let replaceSymbols = replaceUmlaut.replaceAll(/[^a-z0-9\-]/g, "-"); //eslint-disable-line
      let replaceExtraDashes = replaceSymbols.replaceAll(/-+/g, "-");
      return replaceExtraDashes;
    },
    splitDecimal: function(number, val) {
      let tempPrice = number.toString();
      let numbArray = tempPrice.split(".");
      // console.log(numbArray)
      let price = parseInt(numbArray[0]);
      let decimal = parseInt(numbArray[1]);
      if (val === 0) {
        return price.toFixed(2);
      } else {
        if (!isNaN(decimal)) {
          // console.log(decimal);
          return decimal;
        }
      }
    },
    realPrice: function(price) {
      let realValue = price;
      return realValue.toFixed(2);
    },
  },
  computed: {
    ...mapGetters(["itemInCart", "itemInFav"]),
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "calc(100% - 24px)";
        case "sm":
          return "calc(50% - 24px)";
        case "md":
          return "calc(33.33% - 24px)";
        case "lg":
          return "calc(25% - 24px)";
        case "xl":
          return "calc(20% - 24px)";
        default:
          return "calc(33.33% - 24px)";
      }
    },
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 600;
        case "sm":
          return 440;
        case "md":
          return 400;
        case "lg":
          return 440;
        case "xl":
          return 440;
        default:
          return 0;
      }
    },
    minWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 240;
        case "sm":
          return 240;
        case "md":
          return 260;
        case "lg":
          return 260;
        case "xl":
          return 320;
        default:
          return 0;
      }
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "80px";
        case "sm":
          return "80px";
        case "md":
          return "150px";
        case "lg":
          return "150px";
        case "xl":
          return "150px";
        default:
          return 0;
      }
    },
    icon() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "icon";
        default:
          return false;
      }
    },
    iconLeft() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        default:
          return true;
      }
    },
  },
};

// Breakpoints
// xs	< 600px
// sm	600px > < 960px
// md	960px > < 1264px*
// lg	1264px > < 1904px*
// xl	4k and ultra-wide	> 1904px*
// * -16px on desktop for browser scrollbar
</script>

<style scoped>
.v-card__title product-card__title.pb-0 {
  padding-bottom: 0.5rem !important;
}

.product-card__text {
  display: none !important;
}

.product-card__bottom {
  contain: layout;
}

.product-card__text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 48px;
}

.card-list .product-card .card-link {
  display: flex;
}

.card-list .product-card__text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 24px;
}

.card-list .product-card {
  width: 100% !important;
  max-width: 100% !important;
  height: 100%;
  position: relative;
}

.card-list .product-card:hover {
  z-index: 1;
}

.card-list .product-card {
  margin: 0 !important;
  border: none;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

.card-list .product-card:first-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-list .product-card:last-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.card-list .product-card:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.card-list .product-card .product-card__img {
  width: 120px;
  flex-shrink: 0;
}

.product-card .product-card__title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "DalaMoa", sans-serif !important;
}

.product-card__badges {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.product-card__fav-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.v-application--is-ltr .v-card__actions > .v-badge .v-btn.v-btn .v-icon--left {
  margin-left: 4px;
}

.v-card__title.product-card__title {
  padding-bottom: 0.5rem !important;
  font-family: Lora, serif;
}

@media only screen and (max-width: 740px) {
  .v-card__title.product-card__title {
    font-size: 1rem;
    padding-bottom: 0.5rem !important;
    font-family: Lora, serif;
  }

  .card-list .product-card__cost {
    flex-direction: column-reverse;
    padding-bottom: 12px;
  }

  .card-list .product-card__fav-btn {
    position: absolute;
    top: auto;
    bottom: 3px;
    right: 120px;
    z-index: 1;
  }
}

@media only screen and (max-width: 599px) {
  .card-list .product-card__cost {
    font-size: 0.8rem;
  }

  .card-list .product-card .product-card__img {
    width: 80px;
  }

  .card-list .product-card__fav-btn {
    right: 60px;
    z-index: 1;
  }

  .card-list .product-card .product-card__title {
    display: block;
    word-break: keep-all;
    white-space: pre-wrap;
    line-height: 1rem;
  }
}
</style>
