<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5765 22.6465L12 23L12.4235 22.6465C21.5294 15.2222 24 12.6061 24 8.36364C24 4.82828 21.1765 2 17.6471 2C15.4917 2 14.0411 2.90194 12.9745 3.88811C12.6088 4.22615 12.2883 4.57409 12 4.89899C11.7117 4.57409 11.3912 4.22615 11.0255 3.88811C9.95889 2.90194 8.50826 2 6.35294 2C2.82353 2 0 4.82828 0 8.36364C0 12.6061 2.47059 15.2222 11.5765 22.6465Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24HeartSolid",
};
</script>

<style></style>
