<template>
  <v-alert
    :color="data.color"
    :text="data.textColor"
    dense
    tile
    dismissible
    icon="mdi-information-outline"
    :type="type"
    class="ma-0 notification-bar"
  >
    {{ lang(data.text) }}
  </v-alert>
</template>

<style scoped>
.notification-bar {
  width: 100%;
}
</style>
<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    type: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      data: this.$store.state.cms.data.notification[this.id],
    };
  },
};
</script>