<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.299988 6C0.299988 4.50883 1.50882 3.3 2.99999 3.3H21C22.4912 3.3 23.7 4.50883 23.7 6V18C23.7 19.4912 22.4912 20.7 21 20.7H2.99999C1.50882 20.7 0.299988 19.4912 0.299988 18V6ZM2.99999 4.7C2.28202 4.7 1.69999 5.28203 1.69999 6V18C1.69999 18.718 2.28202 19.3 2.99999 19.3H21C21.718 19.3 22.3 18.718 22.3 18V6C22.3 5.28203 21.718 4.7 21 4.7H2.99999Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23 10.5H0.999988V7.5H23V10.5Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 16.7H3.99999V15.3H12V16.7Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24CreditCard",
};
</script>

<style></style>
