<template>
  <v-row
    v-if="data !== undefined"
    :class="data.css.class"
    :style="data.css.style"
  >
    <v-col :cols="data.css.cols">
      <v-img
        v-if="firstItem !== ''"
        :src="require('@/assets/images/' + firstItem.image)"
        height="100%"
      ></v-img>
    </v-col>
    <v-col :cols="data.css.cols">
      <v-row class="custom-margin" v-for="(item, i) in remaining" :key="i">
        <v-img :src="require('@/assets/images/' + item.image)"> </v-img>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      firstItem: "",
      remaining: {},
    };
  },
  mounted() {
    if (this.data) {
      this.data.items.map((e, i) => {
        if (i === 0) {
          this.firstItem = e;
        } else this.remaining[i] = e;
      });
    }
  },
};
</script>
<style scoped>
.custom-margin {
  margin: 0;
}

.custom-margin + .custom-margin {
  margin-top: 24px;
}

</style>