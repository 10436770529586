<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H4C4.55228 0 5 0.447715 5 1C5 1.55228 4.55228 2 4 2H1C0.447715 2 0 1.55228 0 1Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 8C0 7.44772 0.447715 7 1 7H4C4.55228 7 5 7.44772 5 8C5 8.55228 4.55228 9 4 9H1C0.447715 9 0 8.55228 0 8Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 15C0 14.4477 0.447715 14 1 14H4C4.55228 14 5 14.4477 5 15C5 15.5523 4.55228 16 4 16H1C0.447715 16 0 15.5523 0 15Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 22C0 21.4477 0.447715 21 1 21H4C4.55228 21 5 21.4477 5 22C5 22.5523 4.55228 23 4 23H1C0.447715 23 0 22.5523 0 22Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 1C7 0.447715 7.44772 0 8 0H23C23.5523 0 24 0.447715 24 1C24 1.55228 23.5523 2 23 2H8C7.44772 2 7 1.55228 7 1Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 8C7 7.44772 7.44772 7 8 7H23C23.5523 7 24 7.44772 24 8C24 8.55228 23.5523 9 23 9H8C7.44772 9 7 8.55228 7 8Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 15C7 14.4477 7.44772 14 8 14H23C23.5523 14 24 14.4477 24 15C24 15.5523 23.5523 16 23 16H8C7.44772 16 7 15.5523 7 15Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 22C7 21.4477 7.44772 21 8 21H23C23.5523 21 24 21.4477 24 22C24 22.5523 23.5523 23 23 23H8C7.44772 23 7 22.5523 7 22Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon24List",
};
</script>

<style></style>
