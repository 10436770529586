export default {
  state: {
    recentItems: [],
  },
  getters: {},
  mutations: {
    recentItems(state, product) {
      let find = state.recentItems.find(
        (obj) => obj.product.uuid === product.product.uuid
      );

      if (find === undefined) {
        if (state.recentItems.length < 10) {
          state.recentItems.unshift(product);
          localStorage.setItem(
            "recentItems",
            JSON.stringify(state.recentItems)
          );
        } else {
          state.recentItems.pop(product);
          state.recentItems.unshift(product);
          localStorage.setItem(
            "recentItems",
            JSON.stringify(state.recentItems)
          );
        }
      }
    },
    loadRecent(state) {
      if (localStorage.getItem("recentItems") !== null) {
        let recent = localStorage.getItem("recentItems");
        state.recentItems = JSON.parse(recent);
      } else {
        state.recentItems = [];
      }
    },
  },
  actions: {},
  module: {},
};
