import Vue from "vue";
import Vuex from "vuex";
import login from "./modules/login";
import recent from "./modules/recent";
import cart from "./modules/cart";
import favorite from "./modules/favorite";
import cms from "./modules/cms";
import orders from "./modules/orders";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchHistory: [],
    snacktext: {},
    icons: [],
    sperateDelivery: false,
    lang: "fi",
    dialog: false,
    dialogVariant: {},
    deliveryMethod: "Valitse toimitustapa",
    deliveryAddress: {
      mhName: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      id: "",
    },
    deliveryCost: 0,
    paymentMethod: "",
    contactInfo: {
      emailAddress: "-",
      phoneNumber: "-",
      name: "-",
    },
  },
  getters: {
    lang: (state) => (text) => {
      if (!text) return "no text";
      if (state.lang === "fi") return text.fi;
      else if (state.lang === "en") return text.en;
      else return "no translation found for: " + state.lang;
      // or default to fi if no other found?
      // else return text.fi
    },
  },
  mutations: {
    resetOrderInfo(state) {
      // console.log("reset all info");
      state.deliveryMethod = "Valitse toimitustapa";
      state.contactInfo = {
        emailAddress: "-",
        phoneNumber: "-",
        name: "-",
      };
      state.deliveryCost = 0;
      state.paymentMethod = "";
      state.contactInfo = {
        emailAddress: "-",
        phoneNumber: "-",
        name: "-",
      };
    },
    setContactInfo(state, method) {
      state.contactInfo.name = method.name;
      state.contactInfo.emailAddress = method.email;
      state.contactInfo.phoneNumber = method.phone;
    },
    setPaymentMethod(state, method) {
      state.paymentMethod = method;
    },
    setDeliveryMethod(state, deliveryMethod) {
      if (deliveryMethod.name.includes("Valitse")) {
        if (state.deliveryMethod.includes("noutopisteeseen")) {
          return false;
        } else {
          state.deliveryMethod = deliveryMethod.name;
          state.deliveryCost = deliveryMethod.cost;
        }
      } else {
        state.deliveryMethod = deliveryMethod.name;
        state.deliveryCost = deliveryMethod.cost;
      }
      if (deliveryMethod.name == "Nouto myymälästä 7vrk kuluessa") {
        state.deliveryAddress.mhName = "";
        state.deliveryAddress.streetAddress = "Vahdontie 600";
        state.deliveryAddress.postalCode = "21290";
        state.deliveryAddress.city = "Rusko";
        state.deliveryAddress.id = "";
      } else if (
        deliveryMethod.name == "Matkahuolto kotijakelu" ||
        deliveryMethod.name == "Valitse noutopiste"
      ) {
        state.deliveryAddress.mhName = "";
        state.deliveryAddress.streetAddress = "";
        state.deliveryAddress.postalCode = "";
        state.deliveryAddress.city = "";
        state.deliveryAddress.id = "";
      } else if (deliveryMethod.name === "Rahtitoimitus") {
        state.deliveryAddress.mhName = "";
        state.deliveryAddress.streetAddress = "Vahdontie 600";
        state.deliveryAddress.postalCode = "21290";
        state.deliveryAddress.city = "Rusko";
        state.deliveryAddress.id = "";
      }
    },
    setDeliveryAddress(state, deliveryAddress) {
      if (deliveryAddress.mhName) {
        state.deliveryAddress.mhName = deliveryAddress.mhName;
      }
      deliveryAddress.id
        ? (state.deliveryAddress.id = deliveryAddress.id)
        : (state.deliveryAddress.id = "");
      state.deliveryAddress.streetAddress = deliveryAddress.streetAddress;
      state.deliveryAddress.postalCode = deliveryAddress.postalCode;
      state.deliveryAddress.city = deliveryAddress.city;
    },
    dialogVariant(state, item) {
      state.dialogVariant = item;
    },
    toggleDialog(state) {
      state.dialog = !state.dialog;
    },
    updateCustomerName(state, name) {
      state.customername = name;
    },
    updateCustomerCompany(state, name) {
      state.customercompany = name;
    },
    updateCustomerAddr(state, name) {
      if (state.sperateDelivery === false) {
        state.customerdeliveryaddr = name;
      }
      state.customeraddr = name;
    },
    updateCustomerAddr2(state, name) {
      if (state.sperateDelivery === false) {
        state.customerdeliveryaddr2 = name;
      }
      state.customeraddr2 = name;
    },
    updateCustomerPostalCode(state, name) {
      if (state.sperateDelivery === false) {
        state.customerdeliverypostalpode = name;
      }
      state.customerpostalcode = name;
    },
    updateCustomerPostalOffice(state, name) {
      if (state.sperateDelivery === false) {
        state.customerdeliverypostaloffice = name;
      }
      state.customerpostaloffice = name;
    },
    updateCustomerPhoneNumber(state, name) {
      state.customerphonenumber = name;
    },
    updateCustomerEmail(state, name) {
      state.customeremail = name;
    },
    updateSperateDelivery(state, name) {
      state.sperateDelivery = name;
    },
    updateCustomerDeliveryAddr(state, name) {
      state.customerdeliveryaddr = name;
    },
    updateCustomerDeliveryAddr2(state, name) {
      state.customerdeliveryaddr2 = name;
    },
    updateCustomerDeliveryPostalCode(state, name) {
      state.customerdeliverypostalcode = name;
    },
    updateCustomerDeliveryPostalOffice(state, name) {
      state.customerdeliverypostaloffice = name;
    },

    addToSearchHistory(state, value) {
      if (state.searchHistory.length < 10) {
        state.searchHistory.unshift(value);
      } else {
        state.searchHistory.pop();
        state.searchHistory.unshift(value);
      }

      // console.log(state.searchHistory);
    },

    setSnack(state, text) {
      state.snacktext = text;
    },

    addIcons(state, icons) {
      state.icons = icons;
    },
  },
  actions: {},
  modules: {
    login,
    recent,
    cart,
    favorite,
    cms,
    orders,
  },
});
