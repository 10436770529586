<template>
  <div class="d-flex flex-column justify-center align-center gia mb-12">
    <v-img v-if="data.image" :src="require('@/assets/images/' + data.image)">
    </v-img>
    <div class="d-flex flex-column" style="max-width: 900px">
      <h1 v-if="data.title" class="pb-6">
        {{ lang(data.title) }}
      </h1>
      <template v-for="(text, i) in data.texts">
        <p :key="i">
          {{ lang(text) }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>