<template>
  <v-menu
    v-model="menu"
    max-width="450"
    offset-y
    origin="top right"
    :close-on-content-click="false"
    tile
    class="login-menu"
    style="width: 400px !important"
  >
    <template v-if="loggedIn" v-slot:activator="{ on, attrs }">
      <div>
        <v-btn text v-bind="attrs" v-on="on" class="user-account-btn">
          <v-icon> ${{ "Icon24Account" }} </v-icon>
          <span class="hide-on-scroll ml-2"
            >Hei, {{ $store.state.login.user.username }}!</span
          >
        </v-btn>
      </div>
    </template>
    <template v-else v-slot:activator="{ on, attrs }">
      <div>
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
         
          class="bb-navbar__login-btn"
          rounded
          block
          x-large
          color="primary"
        >
          <v-icon> ${{ "Icon24Login" }} </v-icon>
          <span class="ml-2 hide-on-scroll">Kirjaudu</span>
        </v-btn>
      </div>
    </template>
    <v-card
      v-if="$store.state.login.isLoggedIn"
      tile
      outlined
      max-height="calc(100vh - 140px)"
      width="100%"
      div
      class="d-flex flex-column overflow-hidden"
    >
      <div class="overflow-y-auto overflow-x-hidden flex-grow-1"></div>
      <div class="d-flex flex-column pa-5 flex-shrink-0">
        <div class="d-flex justify-center mb-6">
          Kirjautunut sisään: {{ $store.state.login.user.username }}
        </div>
        <div class="d-flex justify-center mb-6">
          <v-btn @click="account" rounded block large color="primary">
            Tilin tiedot
          </v-btn>
        </div>
        <div class="d-flex justify-center mb-6">
          <v-row>
            <v-col cols="12" sm="12">
              <v-btn
                color="primary"
                outlined
                block
                large
                rounded
                @click="logout"
              >
                Kirjaudu ulos
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>
    <v-card
      v-else
      tile
      max-height="calc(100vh - 140px)"
      width="100%"
      class="d-flex flex-column overflow-hidden"
    >
      <LoginUser />
    </v-card>
  </v-menu>
</template>

<style>
.bb-navbar__cart-btn .v-badge__badge {
  pointer-events: none;
}

header.scrolled .hide-on-scroll {
  display: none !important;
}

.user-account-btn {
  max-width: 220px;
  justify-content: start;
}

.user-account-btn .v-btn__content {
  display: inline;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  letter-spacing: initial;
  font-weight: 600;
}

.user-account-btn .v-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}

header.scrolled .user-account-btn {
  height: 36px;
  width: 36px;
  min-width: 36px !important;
  padding: 0 !important;
  margin: 0 8px;
  border-radius: 36px;
}
</style>

<script>
import LoginUser from "./LoginUser";

export default {
  data() {
    return {
      menu: false,
      show: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 3 || "vähintään 3 merkkiä",
        minPass: (v) => v.length >= 8 || "vähintään 8 merkkiä",
      },
      errorMessages: "",
      username: "",
      password: "",
    };
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.login.isLoggedIn;
    },
  },
  methods: {
    loginPage() {
      this.menu = false;
      this.$router.push({ name: "login" }).catch(() => {});
    },
    logout() {
      this.menu = false;
      this.$store.commit("emptyOrders");
      this.$store.commit("logoutUser");
      // console.log(this.$route.name);
      if (this.$route.name === "user-page") {
        this.$router.push("/");
      }
    },
    account() {
      this.menu = false;
      this.$router.push({ name: "user-page", params: { tab: "yhteystiedot" } });
    },
  },
  components: {
    LoginUser,
    // UserPage,
  },
};
</script>
