<template>
  <select-image-style v-if="data!== undefined" :data="data"></select-image-style>
</template>

<script>

import SelectImageStyle from './bb-images/SelectImageStyle.vue';

export default {
  components: { SelectImageStyle },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: this.$store.state.cms.data.bbImage[this.id],
    };
  },
};
</script>

<style>
</style>