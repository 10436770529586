<template>
  <div>
    <v-tabs centered height="120" show-arrows hide-slider v-model="activeTab">
      <v-tab v-for="(item, i) in items" :key="i" class="tab-button">
        <div class="d-flex align-center">
        <v-icon> ${{ item.icon }} </v-icon>
        <div class="ml-4">
          <div class="font-weight-bold">{{ item.name }}</div>
        <div class="mt-1 font-weight-regular">{{ item.text }}</div>
        </div>
        </div>
      </v-tab>
      <v-tab-item v-for="(item, i) in items" :key="i">
        <Matterport :item="item.id" :id="i"/>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Matterport from '@/components/three-d/Matterport'
export default {
  components: { Matterport },
  data() {
    return {
      items: this.$store.state.cms.D3StoreData["matterport"],
      activeTab: 0,
    };
  },

};
</script>

<style>
.tab-item {
  padding-top: 20px;
}

.tab-button {
   width: 300px;
}

.v-slide-group__content.v-tabs-bar__content {
  border-bottom: 1px solid black;
}

.v-tab {
  text-align: left !important;
  border-top: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;
}

.v-tab.v-tab--active {
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid white;
  margin-bottom: -1px;
}

@media only screen and (max-width: 650px) {
  .tab-button {
   width: 200px;
}
}

@media only screen and (max-width: 420px) {
.v-tab, .v-tab.v-tab.v-tab--active {
  border: none;
}
}

</style>
