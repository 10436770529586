<template>
  <div v-if="data.style === '1'">
    <dialog-style-1 :data="data"></dialog-style-1>
  </div>
</template>

<script>
import DialogStyle1 from "./styles/DialogStyle1.vue";
export default {
  components: { DialogStyle1 },
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style>
</style>