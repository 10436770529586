<template>
  <div v-if="data.style === '1'">
    <text-style-1 :data="data"> </text-style-1>
  </div>
  <div v-else-if="data.style === '2'">
    <text-style-2 :data="data"> </text-style-2>
  </div>
</template>

<script>
import TextStyle1 from "./styles/TextStyle1.vue";
import TextStyle2 from "./styles/TextStyle2.vue";

export default {
  components: {
    TextStyle1,
    TextStyle2,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>