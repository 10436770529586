<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-40"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 33.1C11.9882 33.1 13.6 31.4882 13.6 29.5C13.6 27.5118 11.9882 25.9 10 25.9C8.01178 25.9 6.4 27.5118 6.4 29.5C6.4 31.4882 8.01178 33.1 10 33.1ZM10 34.5C12.7614 34.5 15 32.2614 15 29.5C15 26.7386 12.7614 24.5 10 24.5C7.23858 24.5 5 26.7386 5 29.5C5 32.2614 7.23858 34.5 10 34.5Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 33.1C31.9882 33.1 33.6 31.4882 33.6 29.5C33.6 27.5118 31.9882 25.9 30 25.9C28.0118 25.9 26.4 27.5118 26.4 29.5C26.4 31.4882 28.0118 33.1 30 33.1ZM30 34.5C32.7614 34.5 35 32.2614 35 29.5C35 26.7386 32.7614 24.5 30 24.5C27.2386 24.5 25 26.7386 25 29.5C25 32.2614 27.2386 34.5 30 34.5Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 5.7C2.28203 5.7 1.7 6.28203 1.7 7V27C1.7 27.718 2.28203 28.3 3 28.3H6V29.7H3C1.50883 29.7 0.300003 28.4912 0.300003 27V7C0.300003 5.50883 1.50883 4.3 3 4.3H22.5C23.9912 4.3 25.2 5.50883 25.2 7V29.7H14.5V28.3H23.8V7C23.8 6.28203 23.218 5.7 22.5 5.7H3Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.8 11C23.8 10.6134 24.1134 10.3 24.5 10.3H29.4637C30.341 10.3 31.1636 10.7262 31.6695 11.443L37.0719 19.0963C37.1552 19.2144 37.2 19.3554 37.2 19.5V28.3H38.5C38.8866 28.3 39.2 28.6134 39.2 29C39.2 29.3866 38.8866 29.7 38.5 29.7H34.5C34.1134 29.7 33.8 29.3866 33.8 29C33.8 28.6134 34.1134 28.3 34.5 28.3H35.8V20.2H24.5C24.1134 20.2 23.8 19.8866 23.8 19.5C23.8 19.1134 24.1134 18.8 24.5 18.8H35.1491L30.5257 12.2503C30.2822 11.9052 29.8861 11.7 29.4637 11.7H24.5C24.1134 11.7 23.8 11.3866 23.8 11Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon40TruckDelivery",
};
</script>

<style></style>
