<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00001 11.6C7.84088 11.6 7.68826 11.5368 7.57574 11.4243L1.57574 5.42426C1.34143 5.18995 1.34143 4.81005 1.57574 4.57573C1.81006 4.34142 2.18996 4.34142 2.42427 4.57573L8.00001 10.1515L13.5757 4.57573C13.8101 4.34142 14.19 4.34142 14.4243 4.57573C14.6586 4.81005 14.6586 5.18995 14.4243 5.42426L8.42427 11.4243C8.31175 11.5368 8.15914 11.6 8.00001 11.6Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon16ChevronDown",
};
</script>

<style></style>
