<template>
  <div
    v-if="data !== undefined"
    :class="data.css.class"
    :style="data.css.style"
  >
    <v-row>
      <v-col :cols="data.css.cols">
        <v-img
          v-if="firstItem !== ''"
          :src="require('@/assets/images/' + firstItem.image)"
          height="100%"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-for="(item, i) in remaining" :key="i">
        <v-img :src="require('@/assets/images/' + item.image)"> </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      firstItem: "",
      remaining: {},
    };
  },
  mounted() {
    if (this.data) {
      this.data.items.map((e, i) => {
        if (i === 0) {
          this.firstItem = e;
        } else this.remaining[i] = e;
      });
    }
  },
};
</script>

<style>
</style>