<template>
  <div class="carousel">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(image, i) in images" :key="i">
        <router-link
          v-if="image.link && image.link !== ''"
          :to="{ name: 'product-list', params: { category: image.link } }"
        >
          <v-img
            :src="require('@/assets/images/carousel-imgs/' + image.image)"
            aspect-ratio="2.05"
          ></v-img>
        </router-link>
        <v-img
          v-else
          :src="require('@/assets/images/carousel-imgs/' + image.image)"
          aspect-ratio="2.05"
        ></v-img>
      </swiper-slide>
      <div
        class="swiper-pagination swiper-pagination-white"
        slot="pagination"
      ></div>
      <div
        class="swiper-button-prev swiper-button-white"
        slot="button-prev"
      ></div>
      <div
        class="swiper-button-next swiper-button-white"
        slot="button-next"
      ></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  name: "swiper-example-fade-effect",
  title: "Fade effect",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      images: this.$store.state.cms.data.carousel[this.id],
      url: "@/assets/images/carousel-imgs/",
      swiperOption: {
        loop: false,
        autoplay: {
          delay: 6000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  // watch: {
  //   "$store.state.cms.carouselData": function() {
  //     this.images = this.$store.state.cms.carouselData[this.id];
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.swiper {
  max-height: 800px;
  // max-height: 50rem;

  .swiper-slide {
    background-position: center;
    background-size: cover;
  }
}
.swiper-pagination {
  position: relative;
  bottom: 0px;
}

@media only screen and (max-width: 500px) {
  .swiper {
    // height: 240px;
    height: 15rem;
  }
}
</style>
