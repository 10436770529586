<template>
  <div>
    <h2 v-if="data.title" class="mb-3">{{ lang(data.title) }}</h2>
    <v-row class="mb-4">
      <template v-for="(item, i) in data.items">
        <v-col v-if="i === 0" :key="i" cols="4">
          <router-link
            v-if="item.link && item.link !== ''"
            :to="{ name: 'product-list', params: { category: item.link } }"
          >
            <v-img
              :src="require('@/assets/images/' + item.image)"
              height="100%"
            ></v-img>
          </router-link>
          <v-img
            v-else
            :src="require('@/assets/images/' + item.image)"
            height="100%"
          ></v-img>
        </v-col>
        <v-col v-if="i === 1" :key="item.image" cols="8">
          <router-link
            v-if="item.link && item.link !== ''"
            :to="{ name: 'product-list', params: { category: item.link } }"
          >
            <v-img
              :src="require('@/assets/images/' + item.image)"
              height="100%"
            ></v-img>
          </router-link>
          <v-img
            v-else
            :src="require('@/assets/images/' + item.image)"
            height="100%"
          ></v-img>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>