var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-card"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"my-4 ml-4",class:{ 'on-hover': hover },attrs:{"width":"100","height":"100","outlined":"","elevation":hover ? 4 : 0}},[_c('router-link',{attrs:{"to":{
          name: 'ProductPage',
          params: {
            sku: _vm.product.details.sku.value,
            name: _vm.generateSlug(_vm.product.name),
          },
        }}},[(_vm.product.thumbnail)?_c('v-img',{staticClass:"product-card__img",attrs:{"aspect-ratio":"1","src":_vm.baseurl + _vm.product.thumbnail + '/small.png'}}):(_vm.product.assets)?_c('v-img',{staticClass:"product-card__img",attrs:{"aspect-ratio":"1","src":_vm.baseurl + _vm.product.assets[0] + '/small.png'}}):_c('v-img',{staticClass:"product-card__img",attrs:{"aspect-ratio":"1","src":require("@/assets/images/no-img.jpg")}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }