<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6 8C11.6 8.15913 11.5368 8.31174 11.4243 8.42426L5.42426 14.4243C5.18995 14.6586 4.81005 14.6586 4.57573 14.4243C4.34142 14.1899 4.34142 13.81 4.57573 13.5757L10.1515 8L4.57573 2.42426C4.34142 2.18995 4.34142 1.81005 4.57573 1.57574C4.81005 1.34142 5.18995 1.34142 5.42426 1.57574L11.4243 7.57574C11.5368 7.68826 11.6 7.84087 11.6 8Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon16ChevronDown",
};
</script>

<style></style>
