<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="400"
    offset-y
    origin="top right"
    open-on-hover
    tile
    class="fav-menu"
    :disabled="this.disableMenu()"
  >
    <template
      v-if="favNumber > 0 && $store.state.login.isLoggedIn"
      v-slot:activator="{ on, attrs, items = favNumber }"
    >
      <div>
        <v-badge
          class="bb-navbar__cart-btn mx-2"
          color="error"
          :content="items"
          bottom
          overlap
          offset-x="20px"
          offset-y="20px"
        >
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon> ${{ "Icon24Heart" }} </v-icon>
          </v-btn>
        </v-badge>
      </div>
    </template>
    <template v-else v-slot:activator="{ on, attrs }">
      <div>
        <v-btn class="mx-2" icon v-bind="attrs" v-on="on">
          <v-icon> ${{ "Icon24Heart" }} </v-icon>
        </v-btn>
      </div>
    </template>
    <div>
      <v-card
        v-if="
          this.$store.state.favorite.favoriteItems.length > 0 &&
          $store.state.login.isLoggedIn
        "
        tile
        outlined
        max-height="calc(100vh - 140px)"
        width="100%"
        div
        class="d-flex flex-column overflow-hidden"
      >
        <div class="overflow-y-auto overflow-x-hidden flex-grow-1">
          <FavMenuItem />
        </div>
        <v-divider class="black"></v-divider>
        <div class="d-flex flex-column pa-5 flex-shrink-0">
          <router-link :to="{ name: 'favorites' }">
            <v-btn rounded block elevation="0" class="primary mt-5">
              Katso kaikki
              <v-icon small class="ml-2"> ${{ "Icon16ArrowRight" }} </v-icon>
            </v-btn>
          </router-link>
        </div>
      </v-card>
      <v-card
        v-else-if="!$store.state.login.isLoggedIn"
        tile
        outlined
        max-height="calc(100vh - 140px)"
        width="100%"
        div
        class="d-flex flex-column overflow-hidden"
      >
        <div class="d-flex flex-column pa-5 flex-shrink-0">
          Kirjaudu sisään lisätäksesi suosikkeja
        </div>
      </v-card>
      <v-card
        v-else
        tile
        outlined
        max-height="calc(100vh - 140px)"
        width="100%"
        div
        class="d-flex flex-column overflow-hidden"
      >
        <div class="d-flex flex-column pa-5 flex-shrink-0">Ei suosikkeja</div>
      </v-card>
    </div>
  </v-menu>
</template>

<style>
.bb-navbar__cart-btn .v-badge__badge {
  pointer-events: none;
}
</style>

<script>
import FavMenuItem from "@/components/fav-menu/FavMenuItem";
// import { mapGetters } from 'vuex'

export default {
  components: { FavMenuItem },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    favNumber: function () {
      return this.$store.state.favorite.favoriteItems.length;
    },
  },
  methods: {
    disableMenu: function () {
      if (this.$route.name === "check-out") {
        return true;
      } else return false;
    },
  },
};
</script>
