<template>
  <div v-if="data !== undefined">
    <select-text-style :data="data"></select-text-style>
  </div>
</template>

<script>
// import SelectTextStyle from "@/components/bb-text/SelectTextStyle";
import SelectTextStyle from './bb-text/SelectTextStyle.vue';
export default {
  components: { SelectTextStyle },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: this.$store.state.cms.data.bbText[this.id],
    };
  },
};
</script>

<style>
</style>