<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      d="M10.9695 2.96954C12.0385 1.90058 13.4883 1.30005 15 1.30005H18C18.3866 1.30005 18.7 1.61345 18.7 2.00005V6.00005C18.7 6.38665 18.3866 6.70005 18 6.70005H15C14.9205 6.70005 14.8442 6.73166 14.7879 6.78792C14.7317 6.84418 14.7 6.92048 14.7 7.00005V9.30005H18C18.2156 9.30005 18.4191 9.39936 18.5518 9.56926C18.6844 9.73916 18.7314 9.9607 18.6791 10.1698L17.6791 14.1698C17.6012 14.4814 17.3213 14.7 17 14.7H14.7V22C14.7 22.3866 14.3866 22.7001 14 22.7001H10C9.61345 22.7001 9.30005 22.3866 9.30005 22V14.7H7.00005C6.61345 14.7 6.30005 14.3866 6.30005 14V10C6.30005 9.61345 6.61345 9.30005 7.00005 9.30005H9.30005V7.00005C9.30005 5.48831 9.90058 4.0385 10.9695 2.96954ZM15 2.70005C13.8596 2.70005 12.7659 3.15308 11.9595 3.95949C11.1531 4.7659 10.7 5.85962 10.7 7.00005V10C10.7 10.3866 10.3866 10.7 10 10.7H7.70005V13.3H10C10.3866 13.3 10.7 13.6134 10.7 14V21.3H13.3V14C13.3 13.6134 13.6134 13.3 14 13.3H16.4535L17.1035 10.7H14C13.6134 10.7 13.3 10.3866 13.3 10V7.00005C13.3 6.54918 13.4792 6.11678 13.798 5.79797C14.1168 5.47916 14.5492 5.30005 15 5.30005H17.3V2.70005H15Z"
    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Facebook",
};
</script>

<style></style>
