<template>
  <div class="text-center">
    <v-dialog :value="dialog" @input="toggleDialog" width="600">
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          {{ lang(data.title) }}
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="isValid">
              <div class="row py-6 px-1">
                <template v-for="(item, i) in data.form">
                  <div
                    v-if="item.type === 'email'"
                    :key="i"
                    class="py-1 col-12"
                  >
                    <v-text-field
                      v-model="formData[item.name]"
                      outlined
                      dense
                      :label="lang(item.text)"
                      :rules="[rules.required, rules.email]"
                    ></v-text-field>
                  </div>
                  <div
                    v-if="item.type === 'text'"
                    class="py-1 col col-12"
                    :key="i"
                  >
                    <v-text-field
                      v-model="formData[item.name]"
                      outlined
                      dense
                      :label="lang(item.text)"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </div>
                  <div
                    v-if="item.type === 'textarea'"
                    class="py-1 col col-12"
                    :key="i"
                  >
                    <v-textarea
                      outlined
                      dense
                      v-model="formData[item.name]"
                      :label="lang(item.text)"
                      :rules="[rules.required]"
                    ></v-textarea>
                  </div>
                </template>
              </div>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="$store.commit('toggleDialog')">
            Sulje
          </v-btn>
          <v-btn color="error" text outlined @click="submit">
            Lähetä
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      isValid: false,
      rules: {
        required: (value) => !!value || "Pakollinen kenttä.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Virheellinen e-mail";
        },
      },
    };
  },
  computed: {
    dialog: function () {
      return this.$store.state.dialog;
    },
  },
  methods: {
    toggleDialog: function (state) {
      if (!state) {
        this.$store.commit("toggleDialog");
      }
    },
    submit: function () {
      if (this.isValid) {
        let message = {
          contact: {
            email: this.formData.email,
          },
          message: this.formData.topic + ": " + this.formData.message,
        };
            this.$gtag.event("ContactForm", "submit", "form");
        // let url = "http://localhost/";
        let url = process.env.VUE_APP_BBSHOP_ENDPOINT + "/contact/message"
        this.axios
          .post(url, message)
          .then((res) => {
            console.log(res);
            this.$store.commit("toggleDialog");
            this.formData = {};
            this.$store.commit("setSnack", {
              text: "Viesti lähetetty",
              message: "onnistuneesti!",
              color: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("setSnack", {
              text: "Viestin lähetys epäonnistui",
              message: "Yritä myöhemin uudelleen",
              color: "error",
            });
            this.formData = {};
            this.$store.commit("toggleDialog");
          });
      }
    },
  },
};
</script>

<style>
</style>