<template>
  <div v-if="data.style === '1'">
    <image-style-1 :data="data"> </image-style-1>
  </div>
  <div v-else-if="data.style === '2'">
    <image-style-2 :data="data"> </image-style-2>
  </div>
  <div v-else-if="data.style === '3'">
    <image-style-3 :data="data"> </image-style-3>
  </div>
  <div v-else-if="data.style === '4'">
    <image-style-4 :data="data"> </image-style-4>
  </div>
  <div v-else-if="data.style === '6'">
    <image-style-6 :data="data"> </image-style-6>
  </div>
  <div v-else-if="data.style === '7'">
    <image-style-7 :data="data"> </image-style-7>
  </div>
</template>

<script>
import ImageStyle1 from "./styles/ImageStyle1.vue";
import ImageStyle2 from "./styles/ImageStyle2.vue";
import ImageStyle3 from "./styles/ImageStyle3.vue";
import ImageStyle4 from "./styles/ImageStyle4.vue";
import ImageStyle6 from "./styles/ImageStyle6.vue";
import ImageStyle7 from "./styles/ImageStyle7.vue";

export default {
  components: {
    ImageStyle1,
    ImageStyle2,
    ImageStyle3,
    ImageStyle4,
    ImageStyle6,
    ImageStyle7,
  },
  props: {
    data: {
      required: true,
      type: Object
    },
  },
};
</script>

<style>
</style>