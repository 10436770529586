<template>
  <div>
    <template v-for="(item, i) in data.items">
      <div :key="i">
        <h2 v-if="data.title" class="mb-3">{{ lang(data.title) }}</h2>
        <v-row class="mb-8">
          <v-col>
            <router-link
              v-if="item.link && item.link !== ''"
              :to="{ name: 'product-list', params: { category: item.link } }"
            >
              <v-img :src="require('@/assets/images/' + item.image)"></v-img>
            </router-link>
            <v-img
              v-else
              :src="require('@/assets/images/' + item.image)"
            ></v-img>
          </v-col>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>