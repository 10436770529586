<template>
  <div class="login-user">
    <div class="d-flex justify-center">
      <v-card outlined tile>
        <v-card-title> Kirjaudu sisään </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Käyttäjätunnus"
                v-model="username"
                :rules="[rules.required, rules.min]"
                :error-messages="errorMessages"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                label="Salasana"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.minPass]"
                @click:append="show = !show"
              >
              </v-text-field>
              <router-link to="/reset-password"
                >Unohtuiko salasana?</router-link
              >
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                primary
                depressed
                x-large
                rounded
                color="primary"
                @click="login(username, password)"
              >
                Kirjaudu
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn block primary depressed outlined rounded @click="register">
                Rekisteröidy käyttäjäksi
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <br />
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.login-user {
  width: 100%;
}
</style>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      show: false,
      url: process.env.VUE_APP_BBSHOP_ENDPOINT + "/authentication/login/",
      orderUrl: process.env.VUE_APP_BBSHOP_ENDPOINT + "/order/orders/",
      rules: {
        required: (value) => !!value || "Kenttä ei voi olla tyhjä.",
        min: (v) => v.length >= 3 || "vähintään 3 merkkiä",
        minPass: (v) => v.length >= 8 || "vähintään 8 merkkiä",
      },
      errorMessages: "",
    };
  },
  methods: {
    login: function (user, pass) {
      // console.log(this.url);
      let data = {
        username: user,
        password: pass,
      };
      this.axios
        .post(this.url, data)
        .then((res) => {
          // console.log(res);
          this.$store.commit("loginUser", {
            username: user,
            token: res.headers,
            uuid: res.data,
          });
          this.axios.get(this.orderUrl+`${this.$store.state.login.uuid}`).then(response => this.$store.commit("addOrders", response.data))
          this.$router.push({ name: "user-page", params: { tab: "yhteystiedot" } });
        })
        .catch((err) => {
          this.$store.commit("setSnack", {
            text: "Väärä käyttäjätunnus tai ",
            message: "salasana",
            color: "error",
          });
          console.log(err);
        });
      // this.$store.commit("loginUser", { username: user, token: res.headers.authorization });
      // back to previous page or to account page?
      // this.$router.push({ name: "user-page", params: { tab: "yhteystiedot" } });
      // this.$router.go(-1);
    },
    register: function () {
      this.$router.push({ name: "register" });
    },
  },
};
</script>

<style></style>
