<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.48278 1.4931C6.76273 1.75972 6.77354 2.20281 6.50692 2.48276L3.64977 5.48276C3.40181 5.74312 2.99693 5.77329 2.71312 5.55255L0.570262 3.88588C0.265099 3.64853 0.210124 3.20874 0.447473 2.90358C0.684822 2.59841 1.12462 2.54344 1.42978 2.78079L3.07274 4.05865L5.49312 1.51724C5.75974 1.23729 6.20283 1.22648 6.48278 1.4931Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.48278 10.4931C6.76273 10.7597 6.77354 11.2028 6.50692 11.4828L3.64977 14.4828C3.40181 14.7431 2.99693 14.7733 2.71312 14.5525L0.570262 12.8859C0.265099 12.6485 0.210124 12.2087 0.447473 11.9036C0.684822 11.5984 1.12462 11.5434 1.42978 11.7808L3.07274 13.0586L5.49312 10.5172C5.75974 10.2373 6.20283 10.2265 6.48278 10.4931Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.48278 18.4931C6.76273 18.7597 6.77354 19.2028 6.50692 19.4828L3.64977 22.4828C3.40181 22.7431 2.99693 22.7733 2.71312 22.5525L0.570262 20.8859C0.265099 20.6485 0.210124 20.2087 0.447473 19.9036C0.684822 19.5984 1.12462 19.5434 1.42978 19.7808L3.07274 21.0586L5.49312 18.5172C5.75974 18.2373 6.20283 18.2265 6.48278 18.4931Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00002 4C8.00002 3.44772 8.44774 3 9.00002 3H23C23.5523 3 24 3.44772 24 4C24 4.55229 23.5523 5 23 5H9.00002C8.44774 5 8.00002 4.55229 8.00002 4ZM8.00002 13C8.00002 12.4477 8.44774 12 9.00002 12H23C23.5523 12 24 12.4477 24 13C24 13.5523 23.5523 14 23 14H9.00002C8.44774 14 8.00002 13.5523 8.00002 13ZM8.00002 21C8.00002 20.4477 8.44774 20 9.00002 20H23C23.5523 20 24 20.4477 24 21C24 21.5523 23.5523 22 23 22H9.00002C8.44774 22 8.00002 21.5523 8.00002 21Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon24CheckList",
};
</script>

<style></style>
