<template>
  <v-dialog
    :value="dialog"
    @input="$emit('input', $event)"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 lighten-2">
        Valitse tuotevaihtoehdot
      </v-card-title>
      <v-card-text>
        <v-container>
          <div
            class="d-flex flex-column"
            v-for="(items, i) in variantList"
            :key="i + Date.now()"
          >
            <v-select
              v-model="variantSelect[i]"
              :label="variantSelectName(i)"
              :items="variantItems(i)"
              @change="fixSelection(i)"
            >
            </v-select>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              rounded
              large
              outlined
              block
              :to="{
                name: 'ProductPage',
                params: { sku: product.details.sku.value, name: product.name },
                query: this.makeQuery(),
              }"
            >
              <!-- <v-icon> ${{ "Icon16Shopping" }} </v-icon> -->
              <span class="ml-2">Siirry tuotesivulle</span>
            </v-btn>
          </v-col>

          <v-col>
            <v-btn
              color="primary"
              rounded
              large
              :disabled="variantDisable"
              block
              @click="addToCart()"
            >
              <v-icon left>fas fa-shopping-bag</v-icon>
              <span class="ml-2">Lisää ostoskoriin</span>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-spacer></v-spacer>
            <v-btn color="secondary" text outlined @click="close">
              Sulje
            </v-btn>
          </v-col>
        </v-row>
        <!-- <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="close"> Sulje </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    editedId: Number,
    dialog: Boolean,
    product: Object,
  },
  data() {
    return {
      //   product: {},
      variants: {},
      variantOrder: this.$store.state.cms.variantOrder,
      // variantOrder: [
      //   "colour",
      //   "size_letter_code",
      //   "width",
      //   "height",
      //   "depth",
      //   "weight",
      //   "diameter",
      // ],
      variantNames: {
        colour: {
          fi: "Väri",
          en: "Colour",
        },
        size_letter_code: {
          fi: "Koko",
          en: "Size",
        },
        length: {
          fi: "Pituus",
          en: "Length",
        },
        width: {
          fi: "Leveys",
          en: "Width",
        },
        height: {
          fi: "Korkeus",
          en: "Height",
        },
        depth: {
          fi: "Syvyys",
          en: "Depth",
        },
        weight: {
          fi: "Paino",
          en: "Weight",
        },
        diameter: {
          fi: "Halkaisija",
          en: "Diameter",
        },
        sku: {
          fi: "Sku",
          en: "Sku",
        },
        numeric_size: {
          fi: "Koko",
          en: "Size",
        },
        in_pack: {
          fi: "",
          en: "",
        },
        tekso_size: {
          fi: "Koko",
          en: "Size",
        },
        measured_size: {
          fi: "Koko",
          en: "Size",
        },
        shoe_size: {
          fi: "Koko",
          en: "Size",
        },
      },
      variantOrder2: [],
      variantList: {},
      variantSelectedList: {},
      selectedProduct: {},
      variantSelect: {},
      count: 1,
      variantDisable: true,
      url: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/product",
      stock: 0,
      variantStock: 0,
      stockUrl: process.env.VUE_APP_BBSHOP_ENDPOINT + "/stock/",
    };
  },
  methods: {
    makeQuery: function() {
      let queries = {};

      for (let item in this.variantSelect) {
        queries[this.variantOrder2[item].name] = this.variantSelect[item];
      }

      return queries;
    },
    close() {
      this.$emit("close-dialog");

      if (this.editedId === null) {
        this.$emit("open-dialog");
      }
    },
    toggleDialog: function(state) {
      if (!state) {
        this.$store.commit("toggleDialog");
      }
    },
    fixSelection: function(i) {
      for (let item in this.variantSelect) {
        this.variantDisable = true;
        if (item > parseInt(i)) {
          delete this.variantSelect[item];
        }
      }
      if (
        Object.keys(this.variantSelect).length >=
        Object.keys(this.variantList).length
      ) {
        if (Object.keys(this.variants).length > 0) {
          this.findSelectedProduct();
        }
      }
    },
    variantItems: function(item) {
      let array = [];
      let find = this.variantOrder2.find(
        (element) => element.id === parseInt(item)
      );

      if (find !== undefined) {
        if (this.variantList[find.id]) {
          if (parseInt(item) === 0) {
            this.variantList[find.id].map((e) => {
              if (e[find.name].value) {
                array.push(e[find.name].value);
              }
            });
          } else {
            let test3 = this.findTest(
              this.variantList[find.id],
              this.variantOrder2[find.id - 1].name,
              this.variantSelect[item - 1]
            );

            test3.map((e) => {
              array.push(e[find.name].value);
            });

            return array;
          }
        }
        this.variantSelectedList[find.id] = array;
        return array;
      }
    },
    variantSelectName: function(item) {
      if (this.variantOrder2[item].name) {
        return this.lang(this.variantNames[this.variantOrder2[item].name]);
      } else {
        return "name not found";
      }
    },
    findTest: function(variant, check, key) {
      let result = [];
      variant.map((e) => {
        if (e[check].value === key) {
          result.push(e);
        }
      });
      return result;
    },

    findVariant: function(variantsToFind, keyToMatch) {
      return this.helper(variantsToFind, keyToMatch, []);
    },

    helper: function(object, key, result) {
      if (!object) return result;
      if (object instanceof Array) {
        for (var i in object) {
          result = result.concat(this.helper(object[i], key, []));
        }
        return result;
      }
      if (object[key]) result.push(object);

      if (typeof object == "object" && object !== null) {
        let children = Object.keys(object);
        if (children.length > 0) {
          for (i = 0; i < children.length; i++) {
            result = result.concat(this.helper(object[children[i]], key, []));
          }
        }
      }
      return result;
    },
    findSelectedProduct: function() {
      let totalSelected = 0;
      for (let x in this.variantSelect) {
        if (x) {
          totalSelected += 1;
        }
      }
      if (totalSelected >= Object.keys(this.variantList).length) {
        this.variants.find((e) => {
          let total = 0;
          this.variantOrder2.map((x) => {
            if (e[x.name]) {
              if (e[x.name].value === this.variantSelect[x.id]) {
                total += 1;
              }
            }
          });
          if (total >= Object.keys(this.variantList).length) {
            this.selectedProduct = Object.assign({}, this.selectedProduct, e);
            // console.log(this.selectedProduct);
            this.axios
              // .get(this.stockUrl + this.product.uuid + ":" + e.identifier.value)
              .get(this.stockUrl + e.identifier.value)
              .then((res) => {
                // console.log("variant stock value: " + res.data);
                this.variantStock = res.data;
              })
              .finally(() => {
                if (this.variantStock) {
                  if (this.variantStock > 0) {
                    this.variantDisable = false;
                  } else {
                    this.variantDisable = true;
                  }
                } else {
                  this.variantDisable = true;
                }
              });

            //   this.selectedProduct = e;
            //   if (e.in_stock) {
            //     if (e.in_stock.value > 0) {
            //       this.variantDisable = false;
            //     } else {
            //       this.variantDisable = true;
            //     }
            //   } else {
            //     this.variantDisable = true;
            //   }
            // }
          }
        });
      }
    },
    addToCart: function() {
      let totalSelected = 0;
      for (let x in this.variantSelect) {
        if (x) {
          totalSelected += 1;
        }
      }

      if (totalSelected >= Object.keys(this.variantList).length) {
        this.variants.find((e) => {
          let total = 0;
          this.variantOrder2.map((x) => {
            if (e[x.name].value === this.variantSelect[x.id]) {
              total += 1;
            }
          });
          if (total >= Object.keys(this.variantList).length) {
            this.selectedProduct = e;
          }
        });

        // console.log(this.selectedProduct);
        if (this.selectedProduct) {
          if (this.selectedProduct.in_stock) {
            // console.log("in stock found");
            let variantProduct = [];
            this.variantOrder2.map((item, i) => {
              item = {
                name: this.lang(this.variantNames[item.name]),
                value: this.variantSelect[i],
              };
              variantProduct.push(item);
            });
            let data = {};
            if (this.product.discount) {
              data = {
                name: this.product.name,
                sku: this.selectedProduct.sku.value,
                price: this.selectedProduct.price.value,
                in_stock: this.selectedProduct.in_stock.value,
                count: this.count,
                uuid: this.selectedProduct.identifier.value,
                assets: this.product.assets,
                baseProduct: {
                  uuid: this.product.uuid,
                  sku: this.product.details.sku.value,
                },
                variantProduct: variantProduct,
                details: this.product.details,
                thumbnail: this.product.thumbnail,
                discount: {
                  active: this.product.discount.active,
                  percent: this.product.discount.percent,
                },
              };
            } else {
              data = {
                name: this.product.name,
                sku: this.selectedProduct.sku.value,
                price: this.selectedProduct.price.value,
                in_stock: this.selectedProduct.in_stock.value,
                count: this.count,
                uuid: this.selectedProduct.identifier.value,
                assets: this.product.assets,
                baseProduct: {
                  uuid: this.product.uuid,
                  sku: this.product.details.sku.value,
                },
                variantProduct: variantProduct,
                details: this.product.details,
                thumbnail: this.product.thumbnail,
              };
            }
            // console.log(data);

            if (data.count > 0 && data.count <= data.in_stock) {
              this.selectedProduct.in_stock.value -= data.count;
              this.$store.commit("addToCart", data);
              this.$store.commit("setSnack", {
                text: this.product.name,
                message: "lisätty ostoskoriin",
                color: "success",
              });
            } else {
              this.$store.commit("setSnack", {
                text: this.product.name,
                message: "tuotetta ei riittävästi varastossa",
                color: "error",
              });
            }
            this.close();
          }

          // let data = {
          //   from: 0,
          //   size: 10,
          //   query: {
          //     match: { "details.sku.value": this.selectedProduct.sku.value },
          //   },
          // };
          // axios
          //   .post(this.url, data)
          //   .then((res) => {
          //     console.log(res.data.size);
          //     if (res.data.size > 0) {
          //       let variant = res.data.items[0];
          //       let data = {
          //         name: variant.name,
          //         sku: variant.details.sku.value,
          //         price: variant.details.price.value,
          //         count: 1,
          //         uuid: variant.uuid,
          //         assets: variant.assets,
          //       };
          //       this.$store.commit("addToCart", data);
          //       this.$store.commit("setSnack", {
          //         text: variant.name,
          //         message: "lisätty ostoskoriin",
          //         color: "success",
          //       });
          //       this.close();
          //     }
          //   })
          //   .catch();
        }
      }
    },
  },
  mounted: function() {
    // console.log(this.product);
    if (this.product.variants) {
      this.variants = this.product.variants;
      let tempList = [];
      this.variantOrder.map((e) => {
        let temp = this.findVariant(this.variants, e);
        if (temp.length) {
          tempList.push(e);
        }
      });
      if (tempList.length > 0) {
        tempList.map((e, i) => {
          let data = {
            id: i,
            name: e,
          };
          this.variantOrder2.push(data);
        });
      }

      this.variantOrder2.map((e) => {
        let temp = this.findVariant(this.variants, e.name);

        this.variantOrder.forEach((i) => {
          // console.log(i)
          if (e.name === i) {
            this.variantList[e.id] = temp;
          }
        });

        // if (e.name === "colour") {
        //   this.variantList[e.id] = temp;
        // } else if (e.name === "size_letter_code") {
        //   this.variantList[e.id] = temp;
        // } else if (e.name === "width") {
        //   this.variantList[e.id] = temp;
        // } else if (e.name === "height") {
        //   this.variantList[e.id] = temp;
        // } else if (e.name === "depth") {
        //   this.variantList[e.id] = temp;
        // } else if (e.name === "weight") {
        //   this.variantList[e.id] = temp;
        // } else if (e.name === "diameter") {
        //   this.variantList[e.id] = temp;
        // }
      });
      this.findSelectedProduct();
    }
  },
  watch: {
    "$store.state.cms.variantOrder": function() {
      this.variantOrder = this.$store.state.cms.variantOrder;
      // console.log("variantOrder updated");
    },
  },
};
</script>
