export default {
  state: {
    user: {},
    userStatus: "",
    isLoggedIn: false,
    token: "",
    uuid: "",
    loginLoading: true,
  },

  getters: {},

  mutations: {
    loginUser(state, user) {
      state.user = user;
      state.isLoggedIn = true;
      state.userStatus = "loggedIn";
      state.token = user.token.authorization;
      state.uuid = user.uuid.uuid;
      localStorage.setItem("jwt", JSON.stringify(user.token.authorization));
    },
    logoutUser(state) {
      state.user = {};
      state.isLoggedIn = false;
      state.userStatus = "LoggedOut";
      state.uuid = "";
      state.token = "";
      localStorage.removeItem("jwt");
    },
    loginWithToken(state, { user, token }) {
      let userInfo = { username: user.email, token: token, uuid: user.uuid };
      state.user = userInfo;
      state.isLoggedIn = true;
      state.userStatus = "loggedIn";
      state.token = token;
      state.uuid = user.uuid;
    },
    loginLoading(state) {
      state.loginLoading = !state.loginLoading;
    },
  },

  actions: {},
  module: {},
};
