<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.587 1.2471L1.61465 11.2194L1.21892 14.7811L4.78055 14.3853L14.7529 4.413L11.587 1.2471ZM10.8056 0.323657C11.2372 -0.107885 11.9368 -0.107886 12.3684 0.323657L15.6763 3.63162C16.1079 4.06316 16.1079 4.76283 15.6763 5.19438L5.60804 15.2627C5.43062 15.4401 5.19808 15.5519 4.9487 15.5796L1.22724 15.9931C0.523426 16.0713 -0.0712702 15.4766 0.00693103 14.7728L0.420427 11.0513C0.448136 10.8019 0.559896 10.5694 0.737325 10.392L10.8056 0.323657Z"

    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1654 7.85293L8.14706 3.83463L8.99948 2.98221L13.0178 7.00052L12.1654 7.85293Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon16Edit",
};
</script>

<style></style>
