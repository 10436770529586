<template>
  <v-app>
    <component
      v-if="this.contentTemplate !== ''"
      class="main-component"
      :is="{
        name: 'dynamic-content',
        template: this.contentTemplate,
      }"
    />
  </v-app>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@font-face {
  font-family: "DalaMoa";
  src: local("DalaMoa"),
    url(./assets/fonts/DalaMoa-RegularNo2.otf) format("opentype");
}
h1,
h2:not(.product-card__cost-value) {
  /* font-family: "Lora", serif; */
  font-family: "DalaMoa", sans-serif !important;
  font-weight: 400;
}

.v-main__wrap {
  display: flex;
}

</style>

<script>
import template from "./data/template.json";

export default {
  name: "App",
  metaInfo() {
    return {
      title: "Sisustus ja lahjatavaraliike Loviisan Aitta",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Sisustus ja lahjatavaraliike Loviisan Aitta",
        },
        {
          vmid: "ogdescription",
          property: "og:description",
          content: "Sisustus ja lahjatavaraliike Loviisan Aitta",
        },
        {
          vmid: "ogtitle",
          property: "og:title",
          content: "Sisustus ja lahjatavaraliike Loviisan Aitta",
        },
        {
          vmid: "ogsize_name",
          property: "og:site_name",
          content: "Loviisan aitta",
        },
        {
          vmid: "ogimage",
          property: "og:image",
          content: "http://localhost:8080/Loviisan-Aitta-logo.jpg",
        },
        { property: "og:type", content: "website" },
        // { property: "robots", content: "index,follow" },
        { name: "robots", content: this.robots() },
      ],
    };
  },
  data: () => ({
    contentTemplate: template.template.content,
    // contentTemplate: "",
    // urls: template.template.urls,
    productUrl: "https://api.loviisanaitta.fi/client/search/product",
  }),

  async beforeCreate() {
    // await console.log("beforeCreated");
    this.axios
      .get(process.env.VUE_APP_BBSHOP_ENDPOINT + "/discount/list")
      .then((res) => {
        // console.log(res.data);
        this.$store.commit("updateDiscountList", res.data.items);
      });

    if (localStorage.getItem("jwt") !== null) {
      let token = JSON.parse(localStorage.getItem("jwt"));
      let config = {
        headers: { Authorization: "Bearer " + token },
      };
      await this.axios(
        process.env.VUE_APP_BBSHOP_ENDPOINT + "/user/self",
        config
      )
        .then((res) => {
          if (res.status === 200) {
            let user = res.data.item;
            this.$store.commit("loginWithToken", { user, token });
            this.$store.commit("loginLoading");
          }
        })
        // .catch((err) => {
        .catch(() => {
          // console.log(err);
          this.$store.commit("loginLoading");
          this.$store.commit("logoutUser");
          this.$store.commit("emptyOrders");
        });
    }
  },
  created() {
    let url = "https://api.loviisanaitta.fi/properties/variant";
    this.axios.get(url).then((res) => {
      this.$store.commit("updateVariantOrder", res.data.items);
      // console.log(res.data.items);
    });

    // this.BBShopApi("template").then((res) => {
    //   this.contentTemplate = res.data.template.content;
    //   this.$store.commit("updateCMSData", res.data.template.data);
    //   this.$store.commit("updateMainMenuData", res.data.template.data.mainMenu);
    //   this.$store.commit(
    //     "updateCategoryDataSingle",
    //     res.data.template.data.categoryDrawer
    //   );
    // });

    // if (localStorage.getItem("jwt") !== null) {
    //   let token = JSON.parse(localStorage.getItem("jwt"));
    //   let config = {
    //     headers: { Authorization: "Bearer " + token },
    //   };
    //   this.axios(process.env.VUE_APP_BBSHOP_ENDPOINT + "/user/self", config)
    //     .then((res) => {
    //       if (res.status === 200) {
    //         let user = res.data.item;
    //         this.$store.commit("loginWithToken", { user, token });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.$store.commit("logoutUser");
    //     });
    // }

    this.$store.commit("loadCart");

    if (this.$store.state.cart.shoppingCart.length > 0) {
      this.$store.state.cart.shoppingCart.forEach((element) => {
        let data = {
          query: {
            match_phrase: {
              uuid: element.uuid,
            },
          },
        };
        this.axios
          .post(this.productUrl, data)
          .then((res) => {
            if (res.data.size === 0) {
              this.$store.commit("removeFromCart", { uuid: element.uuid });
            }
          })
          .catch(function() {
            this.$store.commit("emptyShoppingCart");
          });

        if (element.discount) {
          this.$store.commit("removeFromCart", { uuid: element.uuid });
        }
      });
    }

    this.$store.commit("loadRecent");

    this.$store.commit("updateCMSData", template.template.data);

    if (template.template.data.mainMenu) {
      if (this.$store.state.cms.mainMenuData.length < 1) {
        this.$store.commit(
          "updateMainMenuData",
          template.template.data.mainMenu
        );
      }
    }

    if (template.template.data.categoryDrawer) {
      if (this.$store.state.cms.categoryDataSingle.length < 1) {
        this.$store.commit(
          "updateCategoryDataSingle",
          template.template.data.categoryDrawer
        );
      }
    }
  },
  methods: {
    robots() {
      if (this.$route.name === "404") {
        return "noindex";
      } else {
        return "index, follow";
      }
    },
  },
};
</script>
