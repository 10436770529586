var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"cart-menu",attrs:{"close-on-content-click":false,"nudge-width":400,"offset-y":"","origin":"top right","open-on-hover":"","tile":"","disabled":this.disableMenu()},scopedSlots:_vm._u([(_vm.cartValue > 0)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var items = ref.items; if ( items === void 0 ) items = _vm.cartValue;
return [_c('div',[_c('v-badge',{staticClass:"mx-2 bb-navbar__cart-btn",attrs:{"color":"error","content":items,"bottom":"","overlap":"","offset-x":"20px","offset-y":"20px"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" $"+_vm._s("Icon24Shopping")+" ")])],1)],1)],1)]}}:{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" $"+_vm._s("Icon24Shopping")+" ")])],1)],1)]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[(this.$store.state.cart.shoppingCart.length > 0)?_c('v-card',{staticClass:"d-flex flex-column overflow-hidden",attrs:{"tile":"","outlined":"","max-height":"calc(100vh - 140px)","width":"100%","div":""}},[_c('div',{staticClass:"overflow-y-auto overflow-x-hidden flex-grow-1"},[_c('CartMenuItem')],1),_c('v-divider',{staticClass:"black"}),_c('div',{staticClass:"d-flex flex-column pa-5 flex-shrink-0"},[_c('div',{staticClass:"d-flex align-end"},[_c('v-btn',{staticClass:"cart-menu-item__remove-btn align-end",attrs:{"text":"","ripple":false,"small":""},on:{"click":function($event){return _vm.emptyShoppingCart()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-close ")]),_vm._v(" Poista Kaikki ")],1),_c('div',{staticClass:"body-2 mr-2 ml-auto"},[_vm._v("yht")]),_c('h2',{staticStyle:{"line-height":"1"}},[_vm._v(_vm._s(this.cartTotal)+" €")])],1),_c('router-link',{staticClass:"mt-5",attrs:{"to":{ name: 'check-out', params: { step: 'ostoskori' } }}},[_c('v-btn',{staticClass:"primary",attrs:{"rounded":"","block":"","x-large":"","elevation":"0"},on:{"click":function($event){return _vm.closeMenu()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-shopping-outline ")]),_vm._v(" Kassalle ")],1)],1)],1)],1):_c('v-card',{staticClass:"d-flex flex-column overflow-hidden",attrs:{"tile":"","outlined":"","max-height":"calc(100vh - 140px)","width":"100%","div":""}},[_c('div',{staticClass:"d-flex flex-column pa-5 flex-shrink-0"},[_vm._v(" Ostoskori on tyhjä ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }