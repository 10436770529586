<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.05155 4.99862C2.8314 4.75095 2.85371 4.3717 3.10138 4.15155L7.60138 0.151553C7.82871 -0.0505203 8.17128 -0.0505202 8.39861 0.151553L12.8986 4.15155C13.1463 4.3717 13.1686 4.75095 12.9484 4.99862C12.7283 5.24629 12.349 5.2686 12.1014 5.04844L8.59999 1.9361L8.59999 15.4C8.59999 15.7314 8.33136 16 7.99999 16C7.66862 16 7.39999 15.7314 7.39999 15.4L7.39999 1.9361L3.89861 5.04844C3.65094 5.26859 3.2717 5.24629 3.05155 4.99862Z"

    />
  </svg>
</template>

<script>
export default {
  name: "Icon16ArrowUp",
};
</script>

<style></style>
