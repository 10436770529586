var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fav-menu-item d-flex flex-column"},[_vm._l((this.$store.state.favorite.favoriteItems),function(item,i){return _c('div',{key:i,staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{
        name: 'ProductPage',
        params: {
          sku: item.details.sku.value,
          name: _vm.generateSlug(item.name),
        },
      }}},[(item.assets)?_c('v-img',{staticClass:"card-menu-item__img flex-grow-0",attrs:{"width":"120","height":"120","aspect-ratio":"1","src":_vm.baseurl + item.assets[0] + '/small.png'}}):_c('v-img',{staticClass:"card-menu-item__img flex-grow-0",attrs:{"width":"120","height":"120","aspect-ratio":"1","src":require("@/assets/images/no-img.jpg")}})],1),_c('div',{staticClass:"d-fex flex-column flex-grow-0 pa-4",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"40px"}},[_c('router-link',{attrs:{"to":{
            name: 'ProductPage',
            params: {
              sku: item.details.sku.value,
              name: _vm.generateSlug(item.name),
            },
          }}},[_c('h4',{staticClass:"card-menu-item__product-name text-truncate"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1),_c('div',{staticClass:"body-2 cart-menu-item__text flex-grow-1 pr-2",staticStyle:{"height":"30px"}}),_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"40px"}},[_c('v-btn',{staticClass:"cart-menu-item__remove-btn mr-auto",attrs:{"outlined":"","rounded":"","small":""},on:{"click":function($event){return _vm.removeFromFavorite(item.uuid)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-close ")]),_vm._v(" Poista suosikeista ")],1)],1)])],1)}),_c('v-divider')],2)}
var staticRenderFns = []

export { render, staticRenderFns }