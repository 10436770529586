<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-40"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38 7.4H2C1.66863 7.4 1.4 7.66863 1.4 8V32C1.4 32.3314 1.66863 32.6 2 32.6H38C38.3314 32.6 38.6 32.3314 38.6 32V8C38.6 7.66863 38.3314 7.4 38 7.4ZM2 6C0.895431 6 0 6.89543 0 8V32C0 33.1046 0.895431 34 2 34H38C39.1046 34 40 33.1046 40 32V8C40 6.89543 39.1046 6 38 6H2Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 13.2H3.5V11.8H10V13.2ZM18.5 13.2H12V11.8H18.5V13.2ZM10 16.2H3.5V14.8H10V16.2ZM18.5 16.2H12V14.8H18.5V16.2ZM10 19.2H3.5V17.8H10V19.2ZM18.5 19.2H12V17.8H18.5V19.2ZM10 22.2H3.5V20.8H10V22.2ZM18.5 22.2H12V20.8H18.5V22.2ZM10 25.2H3.5V23.8H10V25.2ZM18.5 25.2H12V23.8H18.5V25.2ZM10 28.2H3.5V26.8H10V28.2ZM18.5 28.2H12V26.8H18.5V28.2Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.8 20.5C21.8 19.0088 23.0088 17.8 24.5 17.8H32.5C33.9912 17.8 35.2 19.0088 35.2 20.5V25.5C35.2 26.9912 33.9912 28.2 32.5 28.2H24.5C23.0088 28.2 21.8 26.9912 21.8 25.5V20.5ZM24.5 19.2C23.782 19.2 23.2 19.782 23.2 20.5V25.5C23.2 26.218 23.782 26.8 24.5 26.8H32.5C33.218 26.8 33.8 26.218 33.8 25.5V20.5C33.8 19.782 33.218 19.2 32.5 19.2H24.5Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.5 12.7C26.6775 12.7 25.2 14.1775 25.2 16V19H23.8V16C23.8 13.4043 25.9043 11.3 28.5 11.3C31.0957 11.3 33.2 13.4043 33.2 16V16.5H31.8V16C31.8 14.1775 30.3225 12.7 28.5 12.7Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.2 23.5V25.5H27.8V23.5H29.2Z"
      
    />
    <path
      d="M30 22.5C30 23.3284 29.3284 24 28.5 24C27.6716 24 27 23.3284 27 22.5C27 21.6716 27.6716 21 28.5 21C29.3284 21 30 21.6716 30 22.5Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon40OnlineBankPay",
};
</script>

<style></style>
