var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"fav-menu",attrs:{"close-on-content-click":false,"nudge-width":400,"offset-y":"","origin":"top right","open-on-hover":"","tile":"","disabled":this.disableMenu()},scopedSlots:_vm._u([(_vm.favNumber > 0 && _vm.$store.state.login.isLoggedIn)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var items = ref.items; if ( items === void 0 ) items = _vm.favNumber;
return [_c('div',[_c('v-badge',{staticClass:"bb-navbar__cart-btn mx-2",attrs:{"color":"error","content":items,"bottom":"","overlap":"","offset-x":"20px","offset-y":"20px"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" $"+_vm._s("Icon24Heart")+" ")])],1)],1)],1)]}}:{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" $"+_vm._s("Icon24Heart")+" ")])],1)],1)]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('div',[(
        this.$store.state.favorite.favoriteItems.length > 0 &&
        _vm.$store.state.login.isLoggedIn
      )?_c('v-card',{staticClass:"d-flex flex-column overflow-hidden",attrs:{"tile":"","outlined":"","max-height":"calc(100vh - 140px)","width":"100%","div":""}},[_c('div',{staticClass:"overflow-y-auto overflow-x-hidden flex-grow-1"},[_c('FavMenuItem')],1),_c('v-divider',{staticClass:"black"}),_c('div',{staticClass:"d-flex flex-column pa-5 flex-shrink-0"},[_c('router-link',{attrs:{"to":{ name: 'favorites' }}},[_c('v-btn',{staticClass:"primary mt-5",attrs:{"rounded":"","block":"","elevation":"0"}},[_vm._v(" Katso kaikki "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" $"+_vm._s("Icon16ArrowRight")+" ")])],1)],1)],1)],1):(!_vm.$store.state.login.isLoggedIn)?_c('v-card',{staticClass:"d-flex flex-column overflow-hidden",attrs:{"tile":"","outlined":"","max-height":"calc(100vh - 140px)","width":"100%","div":""}},[_c('div',{staticClass:"d-flex flex-column pa-5 flex-shrink-0"},[_vm._v(" Kirjaudu sisään lisätäksesi suosikkeja ")])]):_c('v-card',{staticClass:"d-flex flex-column overflow-hidden",attrs:{"tile":"","outlined":"","max-height":"calc(100vh - 140px)","width":"100%","div":""}},[_c('div',{staticClass:"d-flex flex-column pa-5 flex-shrink-0"},[_vm._v("Ei suosikkeja")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }