<template>
  <v-sheet class="item-row">
    <h3 v-if="this.items">
      <router-link
        v-if="link"
        :to="{
          name: 'product-list',
          params: { category: link },
        }"
      >
        <h3
          v-if="title"
          class="text-center display-1 mt-8 mb-5 item-row__header"
        >
          {{ this.title }}
        </h3>
        <h3
          v-else-if="name"
          class="text-center display-1 mt-8 mb-5 item-row__header"
        >
          {{ this.fixName(name) }}
        </h3>
      </router-link>
      <router-link
        v-else
        :to="{
          name: 'product-list',
          params: { category: this.categoryName(this.category).seoName },
        }"
      >
        <h3
          v-if="title"
          class="text-center display-1 mt-8 mb-5 item-row__header"
        >
          {{ this.title }}
        </h3>
        <h3
          v-else-if="name"
          class="text-center display-1 mt-8 mb-5 item-row__header"
        >
          {{ this.fixName(name) }}
        </h3>
      </router-link>
    </h3>
    <p class="pt-1 pb-10 text-center pr-5 pl-5" v-if="this.text">
      {{ lang(this.text) }}
    </p>
    <div class="d-flex align-strech">
      <v-btn
        icon
        height="auto"
        v-ripple="false"
        tile
        class="next-slide__button"
        @click="swipeLeft"
        ><v-icon large>${{ "Icon24ChevronBoldLeft" }}</v-icon></v-btn
      >
      <swiper ref="mySwiper" :options="swiperOptions" v-if="this.items !== ''">
        <swiper-slide v-for="(item, i) in this.items" :key="i">
          <ProductCard :product="item" />
        </swiper-slide>
        <swiper-slide>
          <v-card-actions
            class="justify-center align-center"
            style="height: 100%"
          >
            <v-btn
              v-if="link"
              x-large
              rounded
              outlined
              class="px-4 absolute product-card__add-cart-btn"
              :to="{
                name: 'product-list',
                params: { category: link },
              }"
            >
              Katso lisää
            </v-btn>
            <v-btn
              v-else
              x-large
              rounded
              outlined
              class="px-4 absolute product-card__add-cart-btn"
              :to="{
                name: 'product-list',
                params: { category: this.categoryName(this.category).seoName },
              }"
            >
              Katso lisää
            </v-btn>
          </v-card-actions>
        </swiper-slide>
      </swiper>
      <swiper ref="mySwiper" :options="swiperOptions" v-else>
        <swiper-slide v-for="(item, i) in 4" :key="i">
          <v-skeleton-loader
            type="image, list-item-two-line, actions"
          ></v-skeleton-loader>
        </swiper-slide>
      </swiper>
      <v-btn
        icon
        height="auto"
        v-ripple="false"
        tile
        class="prev-slide__button"
        @click="swipeRight"
        ><v-icon large>${{ "Icon24ChevronBoldRight" }}</v-icon></v-btn
      >
    </div>
  </v-sheet>
</template>

<script>
import ProductCard from "@/components/ProductCard";
import axios from "axios";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapGetters } from "vuex";
export default {
  name: "App",
  props: ["id", "perRow", "uuid"],
  components: { ProductCard, Swiper, SwiperSlide },
  directives: {
    swiper: directive,
  },
  methods: {
    swipeRight: function() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
    swipeLeft: function() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    fixName: function(name) {
      let parts = this.lang(name).split(" ");
      if (parts[parts.length - 1] === "tuotteita") {
        parts.pop();
      }

      let remake = parts.join(" ");
      return remake;
    },
  },
  data: function() {
    return {
      model: null,
      items: "",
      category: "",
      title: "",
      name: "",
      link: "",
      text: "",
      swiperOptions: {
        threshold: 20,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
        breakpoints: {
          2000: {
            slidesPerView: this.perRow,
            slidesPerGroup: this.perRow,
            spaceBetween: 40,
          },
          1600: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 40,
          },
          1300: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 25,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 15,
          },
          400: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 0,
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    ...mapGetters(["categoryName"]),
  },
  mounted() {
    if (this.uuid) {
      this.category = this.uuid;
    } else {
      this.category = this.$store.state.cms.data.itemRow[this.id].category;
      if (this.$store.state.cms.data.itemRow[this.id].link) {
        this.link = this.$store.state.cms.data.itemRow[this.id].link;
      }
      if (this.$store.state.cms.data.itemRow[this.id].title) {
        this.title = this.$store.state.cms.data.itemRow[this.id].title;
      }
      if (this.$store.state.cms.data.itemRow[this.id].text) {
        this.text = this.$store.state.cms.data.itemRow[this.id].text;
      }
    }
    // console.log(this.category);

    this.swiper.slideTo(0, 1000, false);
    let url = process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/product";
    let data = {
      from: 0,
      size: 10,
      sort: [
        {
          "created.keyword": {
            order: "desc",
          },
        },
      ],
      query: { match_phrase: { categories: this.category } },
    };
    let url2 = process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/category";
    let data2 = {
      from: 0,
      size: 10,
      query: { match_phrase: { uuid: this.category } },
    };

    axios
      .post(url, data)
      .then((response) => {
        this.items = response.data.items;
        // let sortedList = response.data.items;
        // sortedList.sort((a, b) => {
        //   let c = a.created.split(" ");
        //   let d = b.created.split(" ");
        //   return new Date(d[0]) - new Date(c[0]);
        // });
        // this.items = sortedList;
      })
      .catch(function(error) {
        console.log(error);
      });

    axios
      .post(url2, data2)
      .then((res) => {
        this.name = res.data.items[0].name;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped>
/* 
.swiper-button-prev, .swiper-button-next {
  padding: 80px 40px 80px 40px;
  -webkit-transform: translate(0,-50%);
  transform: translate(0,-50%);
  position: absolute;
  top: 50%;
  color: #f4f4f4;
}

.swiper-button-disabled {
     opacity: 1 !important;
} 

.swiper-button-prev {
  left: -5px;
}
*/
.display-1 {
  font-family: "DalaMoa", sans-serif !important;
}
.swiper-button-next {
  right: -5px;
}

.item-row .product-card {
  margin: 0 !important;
  width: 100% !important;
  box-sizing: border-box;
}

.item-row .swiper-slide {
  min-width: 285px;
}

@media only screen and (max-width: 1024px) {
  .item-row .swiper-slide {
    min-width: 240px;
  }
}
</style>
