<template>
  <div class="image-card">
    <v-hover v-slot="{ hover }">
      <v-card
        width="100"
        height="100"
        outlined
        class="my-4 ml-4"
        :elevation="hover ? 4 : 0"
        :class="{ 'on-hover': hover }"
      >
        <router-link
          :to="{
            name: 'ProductPage',
            params: {
              sku: product.details.sku.value,
              name: generateSlug(product.name),
            },
          }"
        >
          <v-img
            v-if="product.thumbnail"
            aspect-ratio="1"
            class="product-card__img"
            :src="baseurl + product.thumbnail + '/small.png'"
          >
          </v-img>
          <v-img
            v-else-if="product.assets"
            aspect-ratio="1"
            class="product-card__img"
            :src="baseurl + product.assets[0] + '/small.png'"
          >
          </v-img>
          <v-img
            v-else
            aspect-ratio="1"
            class="product-card__img"
            src="@/assets/images/no-img.jpg"
          >
          </v-img>
        </router-link>
      </v-card>
    </v-hover>
    <!-- {{ this.product.name }} -->
  </div>
</template>
<script>
export default {
  props: {
    product: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_BBSHOP_IMAGES_ENDPOINT,
    };
  },
  methods: {
    generateSlug: function(name) {
      let lowerCase = name.toLowerCase();
      let replaceSpaces = lowerCase.replaceAll(" ", "-");
      let replaceUmlaut = replaceSpaces
        .replaceAll("ä", "a")
        .replaceAll("ö", "ö")
        .replaceAll("å", "a")
        .replaceAll("ü", "u");
      let replaceSymbols = replaceUmlaut.replaceAll(/[^a-z0-9\-]/g, "-"); //eslint-disable-line
      let replaceExtraDashes = replaceSymbols.replaceAll(/-+/g, "-");
      return replaceExtraDashes;
    },
  },
};
</script>
<style scoped>
.v-card {
  transition: opacity 0.2s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}
</style>
