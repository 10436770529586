<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5 1.4C9.22973 1.4 8.19999 2.42975 8.19999 3.7V9C8.19999 9.3866 7.8866 9.7 7.5 9.7C7.1134 9.7 6.8 9.3866 6.8 9V3.7C6.8 1.65655 8.45654 0 10.5 0H13.5C15.5434 0 17.2 1.65655 17.2 3.7V9C17.2 9.3866 16.8866 9.7 16.5 9.7C16.1134 9.7 15.8 9.3866 15.8 9V3.7C15.8 2.42975 14.7702 1.4 13.5 1.4H10.5Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.2222 7.4H2.77778C2.45934 7.4 2.19637 7.64877 2.1787 7.96672L1.40092 21.9667C1.38182 22.3106 1.65555 22.6 2 22.6H22C22.3445 22.6 22.6182 22.3106 22.5991 21.9667L21.8213 7.96672C21.8036 7.64877 21.5407 7.4 21.2222 7.4ZM2.77778 6C1.71631 6 0.839738 6.82923 0.780858 7.88906L0.00308011 21.8891C-0.0606089 23.0355 0.85183 24 2 24H22C23.1482 24 24.0606 23.0355 23.9969 21.8891L23.2191 7.88906C23.1603 6.82923 22.2837 6 21.2222 6H2.77778Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon24Shopping",
};
</script>

<style></style>
