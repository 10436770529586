<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="icon-40"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 3.25021L33.7 9.66031V30.4012L33.2315 30.5654L33.1214 30.6041C33.0488 30.6296 32.9414 30.6675 32.8023 30.7167C32.5242 30.8152 32.1197 30.9588 31.6146 31.1396C30.6045 31.5012 29.1925 32.0113 27.5857 32.6048C24.3691 33.793 20.3828 35.3118 17.2757 36.6434L17 36.7616L16.7243 36.6434C13.6172 35.3118 9.6309 33.793 6.4143 32.6048C4.8075 32.0113 3.39555 31.5012 2.3854 31.1396C1.88036 30.9588 1.47583 30.8152 1.19771 30.7167C1.05865 30.6675 0.951194 30.6296 0.878579 30.6041L0.768887 30.5655L0.300003 30.4012V9.66031L17 3.25021ZM1.7 29.4094C1.97801 29.5078 2.37137 29.6476 2.85725 29.8215C3.87078 30.1843 5.28726 30.696 6.89942 31.2915C9.79571 32.3614 13.3326 33.7053 16.3 34.9441V16.6742L1.7 11.1544V29.4094ZM2.96633 10.1365L17 15.4421L31.0337 10.1365L17 4.7498L2.96633 10.1365ZM32.3 11.1544L17.7 16.6742V34.9441C20.6674 33.7053 24.2043 32.3614 27.1006 31.2915C28.7127 30.696 30.1292 30.1843 31.1428 29.8215C31.6286 29.6476 32.022 29.5078 32.3 29.4094V11.1544Z"
      
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.6601 18.4931C35.9401 18.2265 36.3831 18.2373 36.6498 18.5172L39.5069 21.5172C39.7644 21.7876 39.7644 22.2124 39.5069 22.4828L36.6498 25.4828C36.3831 25.7627 35.9401 25.7735 35.6601 25.5069C35.3802 25.2403 35.3693 24.7972 35.636 24.5172L37.3667 22.7H29C28.6134 22.7 28.3 22.3866 28.3 22C28.3 21.6134 28.6134 21.3 29 21.3H37.3667L35.636 19.4828C35.3693 19.2028 35.3802 18.7597 35.6601 18.4931Z"
      
    />
  </svg>
</template>

<script>
export default {
  name: "Icon40PackageDelivery",
};
</script>

<style></style>
