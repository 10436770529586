<template>
  <v-navigation-drawer
    v-model="expandCategoryDrawer"
    fixed
    temporary
    width="300px"
    class="category-drawer"
  >
    <div class="d-flex flex-column" style="height: 100%">
      <div class="d-flex flex-column flex-shrink-0">
        <div class="d-flex align-center pl-6 pr-2" style="height: 60px">
          <h4>
            {{ this.$store.state.cms.categoryDataSingle.product.name.fi }}
          </h4>
          <v-spacer></v-spacer>
          <v-btn
            text
            icon
            class="close-products-btn"
            @click="expandCategoryDrawer = false"
          >
            <v-icon>${{ "Icon16Close" }}</v-icon>
          </v-btn>
        </div>
        <v-divider></v-divider>
      </div>
      <v-treeview
        :items="categories"
        class="category-tree py-6 overflow-y-auto"
        activatable
        open-on-click
      >
        <!-- <template v-slot:prepend="{ item }"> -->
        <!-- <template slot="label" slot-scope="{ item }"> -->
        <template v-slot:label="{ item }">
          <v-btn
            v-if="item.children.length === 0"
            text
            @click="drawer = false"
            :ripple="false"
            height="48"
            tile
            style="width: 100%"
            :to="{
              name: 'product-list',
              params: { category: getSeoUrl(item), uuid: item.uuid },
            }"
          >
            {{ item.name }}
          </v-btn>
          <v-btn
            text
            @click="drawer = false"
            :ripple="false"
            height="48"
            tile
            style="width: 100%"
            v-else
          >
            {{ item.name }}
          </v-btn>
        </template>
      </v-treeview>
    </div>
  </v-navigation-drawer>
</template>

<style scoped>
a {
  white-space: nowrap;
}
</style>

<script>
import axios from "axios";
export default {
  /*
  Tässä taas on ns. mounted lohko, joka suoritetaan kun komponentti on 'mountattu', eli siitä tulee osa DOM:ia.
  Mounted lohkossa kiinitetään listener $on, joka kuuntelee tapahtumaa update, joka määriteltiin aiemmin Navbarissa.
  Kun 'update' niminen tapahtuma propagoituu mistään päin sovellusta, tämä listener kaappaa sen mikäli komponentti
  on olemassa ja muuttaa expandCategoryDrawerin arvoa tapahtumasta saadun arvon mukaiseksi.
   */
  data: () => ({
    categories: [],
    items2: [],
    items: [],
    url3: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/get/category/",
    url2: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/tree",
    url: process.env.VUE_APP_BBSHOP_ENDPOINT + "/client/search/category",
    expandCategoryDrawer: false,
    tree: "",
  }),
  mounted() {
    this.$root.$on("update", (data) => (this.expandCategoryDrawer = data));
    let data = {
      from: 0,
      size: 1000,
      query: {
        match_all: {},
      },
      sort: [
        {
          "name.fi.keyword": {
            order: "asc",
          },
        },
      ],
    };
    axios.post(this.url, data).then((response) => {
      if (this.$store.state.cms.categoryData.length < 1) {
        this.$store.commit("updateCategoryData", response.data.items);
      }
    });

    // console.log(this.$store.state.cms.categoryDataSingle.product.uuid);
    // let data2 = {
    //   from: 0,
    //   size: 1000,
    //   query: {
    //     query_string: {
    //       query: this.$store.state.cms.categoryDataSingle.product.uuid,
    //       fields: ["parent"],
    //     },
    //   },
    // };

    // axios.post(url, data2).then((res) => {
    //   this.items = res.data;
    //   if (this.$store.state.cms.categoryData.length < 1) {
    //     this.$store.commit("updateCategoryNewData", res.data.items);
    //   }
    // });

    //-----------------------------------------------------------------//

    // let data2 = {
    //   from: 0,
    //   size: 100,
    //   query: {
    //     match_phrase: {
    //       name: this.$store.state.cms.categoryDataSingle.product.uuid,
    //     },
    //   },
    // };

    //-----------------------------------------------------------------//
    let data2 = {
      from: 0,
      size: 100,
      query: {
        bool: {
          must: {
            match_phrase: {
              "tree.children.name":
                this.$store.state.cms.categoryDataSingle.product.uuid,
            },
          },
        },
      },
      _source: ["tree.children"],
    };

    this.axios.post(this.url2, data2).then((res) => {
      this.tree = res.data.items[0];
      res.data.items[0].tree.children.map((element) => {
        if (
          element.name === this.$store.state.cms.categoryDataSingle.product.uuid
        ) {
          element.children.map((x, i) => {
            this.categories.push(x);
            this.$set(this.categories[i], "id", i);
          });

          // console.log(element);
        }
      });

      this.$store.commit("categoryDrawerData", this.categories);

      let test = {};
      let temp = [];
      this.categories.forEach((e) => {
        e.children.forEach((x) => {
          temp.push(x.data);
          test[e.name] = x.data;
        });
        if (e.name !== undefined) {
          test[e.name] = temp;
        }
        temp = [];
      });

      this.$store.commit("updateMultiCategory", test);
    });
  },

  methods: {
    test: function (name, uuid) {
      // console.log("name: " + name + ", uuid: " + uuid);
      this.$router.push({
        name: "product-list",
        params: { category: name, uuid: uuid },
      });
    },
    getSeoUrl: function (item) {
      for (var i in this.$store.state.cms.categoryData) {
        if (this.$store.state.cms.categoryData[i].name.fi == item.name) {
          return this.$store.state.cms.categoryData[i].seoName;
        }
      }
    },
  },
};
</script>

<style scoped>
.v-btn {
  justify-content: start;
  padding: 0;
}
</style>